import { View  } from 'react-native';
import React, { useState, useEffect } from 'react';
import { Formik } from 'formik';
import { useIsFocused } from '@react-navigation/native'
import DropdownField from '../../components/DropdownFieldFormik';
import axiosInstance from '../../axiosInstance';
import ContentContainer from '../../components/ContentContainer';
import ContentHeader from '../../components/ContentHeader';
import {globalStyle} from '../../style';
import {residencyStates} from '../../assets/constants';
import { replaceEmptyWithNull, replaceNullWithEmpty } from '../../utils/index'; 
import { handleFilingIdParam, handleFormIdParam, navigateFiling, editFormValues } from '../../utils';

export default function ResidencyScreen({navigation, route}) {
  // declare field state variables
  const initialValues = {residencyState2: ''}
  const [ formValues, setFormValues ] = useState(initialValues);
  const [ filingId, setFilingId ] = useState('');
  const [ formId, setFormId ] = useState('');
  const isFocused = useIsFocused();

  useEffect(() => {
      const newFilingId = handleFilingIdParam(navigation, route, setFilingId);
      const cleanFormId = handleFormIdParam(route, setFormValues, setFormId, initialValues, newFilingId, isFocused, [], 'Personal Info'); 
  }, [isFocused]);

  const continuePressed = (formValues, setSubmitting, setValues) => {
    editFormValues(setSubmitting, setValues, filingId, formId, 'Personal Info', formValues, initialValues, navigation, 'dependents', []);
  };

  const backPressed = () => {
    navigateFiling(navigation, 'contactInfo', { filingId: filingId });
  };

  return (
    <Formik 
      initialValues={formValues} 
      enableReinitialize={true}
      onSubmit={(values, { setSubmitting, setValues }) => {continuePressed(values, setSubmitting, setValues)}}>
     {({ handleChange, handleBlur, handleSubmit, values, isSubmitting }) => (
    <ContentContainer nextPress={handleSubmit} nextDisabled={isSubmitting} backPress={backPressed}>
      <ContentHeader icon={"home"} headerText="Let's establish your residency status" subHeaderText="This will help us figure out which state filings you will need to make"/>
      
      <View style={globalStyle.fieldRow}>
        <DropdownField name="residencyState2" fieldName="What other states did you live in during 2021?" placeholder="Choose" items={residencyStates} width={100} />
      </View>
    </ContentContainer>
    )}
    </Formik>
  );
}