import { StyleSheet, Button, TouchableOpacity, View, ActivityIndicator  } from 'react-native';
import React, { useState, useEffect } from 'react';
import { useFocusEffect, useIsFocused } from '@react-navigation/native'
import { Formik } from 'formik';
import ContentContainer from '../../../components/ContentContainer';
import ContentHeader from '../../../components/ContentHeader';
import StyledText from '../../../components/StyledText';
import TextFieldFormik from '../../../components/TextFieldFormik';
import DollarFieldFormik from '../../../components/DollarFieldFormik';
import BooleanField from '../../../components/BooleanFieldFormik';
import DropdownField from '../../../components/DropdownFieldFormik';
import axiosInstance from '../../../axiosInstance';
import {globalStyle} from '../../../style';
import { replaceNullWithEmpty, replaceEmptyWithNull } from '../../../utils/index'; 
import { handleFilingIdParam, prettifyNumericFields, editFormValues, navigateFiling } from '../../../utils';
import { countries } from '../../../assets/constants';

export default function AddForeignAccount({route, navigation}) {
  const initialValues = {
    'payerName': '',
    'accountType': '', 
    'accountNumber': '',
    'giin': '', 
    'maxValue': '', 
    'fxUsed': '',
    'fxRate': '',
    'fxCurrency': '',
    'fxSource': '',
    'foreignAddress': '',
    'foreignCity': '',
    'foreignPostCode': '',
    'foreignCountry': '',
    'accountOpened': '', 
    'accountClosed': '',
    'jointAccount': '',
    'incomeType': '',
    'accountIncome': '',
  }
  const [f8939Required, setF8939Required] = useState(false);
  const [formValues, setFormValues] = useState(initialValues);
  const [filingId, setFilingId] = useState('');
  const [formId, setFormId] = useState('');
  const isFocused = useIsFocused();
  const numericFields = ['maxValue', 'accountIncome']

  // if we are editing an existing dependent then we would have received the dependent if as a parameter 
  // we use this to load the existing dependent details upon render and focus
  useEffect(() => {
    const newFilingId = handleFilingIdParam(navigation, route, setFilingId);
    const newFormId = route.params?.formId;
    if (isFocused) {
      setFormId(newFormId);
      pullForm(newFilingId, newFormId);
    }

  }, [isFocused]);

  const pullForm = (newFilingId, formId) => {
    var jsonData = JSON.stringify({'userFormId': formId, 'filingId': newFilingId});
    axiosInstance
      .post('loadForeignAccountEditor', jsonData, { headers: {'Content-Type': 'application/json' }, withCredentials: true})
      .then(function (response) {
        setF8939Required(response['data']['f8939Required']);
        let r = response['data']['account'];
        if (Object.keys(r).length > 0) {
          setFormValues(prevValues => ({
            ...prevValues,
            ...Object.fromEntries(Object.entries(prettifyNumericFields(replaceNullWithEmpty(r), numericFields)).filter(([key]) => key in prevValues))
          }));
        }
      })
      .catch(function (error) {console.log(error);});
  };

  const continuePressed = (formValues, setSubmitting, setValues) => {
    editFormValues(setSubmitting, setValues, filingId, formId, 'Foreign Account', formValues, initialValues, navigation, 'foreignAccountSummary', numericFields)
  };

  const backPressed = (setValues) => {
    navigateFiling(navigation, 'foreignAccountSummary', { filingId: filingId });
  };

  const foreignAccountTypes = [
    {label: 'Choose', value: ''},
    {label: 'Deposit', value: 'deposit'},
    {label: 'Custodial', value: 'custodial'}
  ];
  const foreignIncomeTypes = [
    {label: 'Choose', value: ''},
    {label: 'Interest', value: 'interest_income'},
    {label: 'Dividend', value: 'ordinary_dividends'},
    {label: 'Royalty', value: 'royalty'},
    {label: 'Capital Gain', value: 'capital_gain'},
    {label: 'Deduction', value: 'deduction'},
    {label: 'Credit', value: 'credit'},
    {label: 'Other', value: 'other'},
  ];

  return (
    <Formik 
    initialValues={formValues} 
    enableReinitialize={true}
    onSubmit={(values, { setSubmitting, setValues }) => {continuePressed(values, setSubmitting, setValues)}}>
    {({ handleChange, handleBlur, handleSubmit, values, setValues, isSubmitting }) => (
      <ContentContainer nextPress={handleSubmit} nextDisabled={isSubmitting} backPress={backPressed} backDisabled={false}>
         <ContentHeader icon={"airplanemode-active"} headerText={"Enter details about your foreign account"} />

        {f8939Required === true ?
          <View>
            <View style={globalStyle.sectionHeaderContainer}>
              <StyledText style={globalStyle.sectionHeaderText}>Account Details</StyledText>
            </View>
            <View style={globalStyle.fieldRow}>
              <TextFieldFormik name="payerName" fieldName="Name of financial institution" placeholder="" fieldStyle={{'flex': 2, minWidth: 250}} />
              <View style={{flex: 3}}></View>
            </View>
            <View style={globalStyle.fieldRow}>
              <DropdownField name="accountType" fieldName="Account Type" placeholder="Choose" items={foreignAccountTypes} fieldStyle={{'flex': 2, minWidth: 250}}/>
              <View style={{flex: 3}}></View>
            </View>

            <View style={globalStyle.fieldRow}>
              <TextFieldFormik name="accountNumber" fieldName="Account Number" placeholder="" fieldStyle={{'flex': 1, minWidth: 250}} />
              <TextFieldFormik name="giin" fieldName="GIIN (optional)" placeholder="" fieldStyle={{'flex': 1, minWidth: 250}} />
            </View>

            <View style={globalStyle.fieldRow}>
              <DollarFieldFormik name="maxValue" fieldName="What was the maximum value in the account over the year, in USD?" placeholder="" fieldStyle={{'flex': 2, minWidth: 250}}/>
              <View style={{flex: 3}}></View>
            </View>

            <View style={globalStyle.fieldRow}>
              <BooleanField name='fxUsed' fieldName='Did you convert the value of your account into USD using an FX rate?' trueLabel={'Yes'} falseLabel={'No'} />
            </View>

            {values['fxUsed'] ? 
              <View style={globalStyle.fieldRow}>
                <TextFieldFormik name="fxRate" fieldName="FX rate used" placeholder="" fieldStyle={{'flex': 1}} />
                <TextFieldFormik name="fxCurrency" fieldName="FX Currency" placeholder="" fieldStyle={{'flex': 1}}/>
                <TextFieldFormik name="fxSource" fieldName="FX Source" placeholder="" fieldStyle={{'flex': 1}} />
              </View>:
              <View></View>
            }

            <View style={globalStyle.fieldRow}>
              <TextFieldFormik name="foreignAddress" fieldName="Address" placeholder="" fieldStyle={{'flex': 1}}/>
              <TextFieldFormik name="foreignCity" fieldName="City" placeholder="" fieldStyle={{'flex': 1}}/>
            </View>

            <View style={globalStyle.fieldRow}>
              <TextFieldFormik name="foreignPostCode" fieldName="Post Code" placeholder="" fieldStyle={{'flex': 1}}/>
              <DropdownField name="foreignCountry" fieldName="Country" placeholder="Choose" items={countries} fieldStyle={{'flex': 1}}/>
            </View> 

            <View style={globalStyle.fieldRow}>
              <BooleanField name="accountOpened" fieldName="Did you open this account this year?" trueLabel={'Yes'} falseLabel={'No'}/>
            </View>
            <View style={globalStyle.fieldRow}>
              <BooleanField name="accountClosed" fieldName="Did you close this account this year?" trueLabel={'Yes'} falseLabel={'No'}/>
            </View>
            <View style={globalStyle.fieldRow}>
              <BooleanField name="jointAccount" fieldName="Is this account jointly owned with your spouse?" trueLabel={'Yes'} falseLabel={'No'}/>
            </View>

            <View style={globalStyle.sectionHeaderContainer}>
              <StyledText style={globalStyle.sectionHeaderText}>Account Income</StyledText>
            </View>
            <View style={globalStyle.fieldRow}>
              <DropdownField name="incomeType" fieldName="Income Type" placeholder="Choose" items={foreignIncomeTypes} fieldStyle={{'flex': 1}}/>
              <DollarFieldFormik name="accountIncome" fieldName="Income Amount (USD)" placeholder="" fieldStyle={{'flex': 1}} />
            </View>
          </View>
          :
          <View>
            <View style={globalStyle.sectionHeaderContainer}>
              <StyledText style={globalStyle.sectionHeaderText}>Account Income</StyledText>
            </View>
            <View style={globalStyle.fieldRow}>
              <TextFieldFormik name="payerName" fieldName="Name of financial institution" placeholder="" fieldStyle={{'flex': 2, minWidth: 250}} />
              <View style={{flex: 3}}></View>
            </View>
            <View style={globalStyle.fieldRow}>
              <DropdownField name="incomeType" fieldName="Income Type" placeholder="Choose" items={foreignIncomeTypes} fieldStyle={{'flex': 2}}/>
              <DollarFieldFormik name="accountIncome" fieldName="Income Amount (USD)" placeholder="" fieldStyle={{'flex': 1}} />
            </View>
          </View>
        }
      </ContentContainer>
    )}
    </Formik>
  );
}