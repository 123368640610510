import React, { useState, useEffect } from 'react';
import { TouchableOpacity, View, StyleSheet, useWindowDimensions } from 'react-native';
import { Formik } from 'formik';
import BooleanBoxField from '../../components/BooleanBoxFormik';
import {globalColors, globalStyle} from '../../style';
import StyledText from '../../components/StyledText';
import StyledButton from '../../components/StyledButton';
import { MaterialIcons } from '@expo/vector-icons';
import { largeScreen } from '../../assets/constants';
import { logOut, navigateFiling } from '../../utils';

 export default function GetStartedScreen({navigation}) {
  const initialValues = {nextScreen: ''}
  const [ formValues, setFormValues ] = useState(initialValues);
  const { width } = useWindowDimensions();

  let contentWidth;
  if (width > 1300) {
    contentWidth = 900;
  } else if (width > largeScreen) {
    contentWidth = width * 0.7;
  } else {
    contentWidth = width;
  }

  const continuePressed = (values) => {
    if (values.nextScreen === 'personalInfo') {
      navigateFiling(navigation, 'personalInfo', { filingId: null });
    } 
    else if (values.nextScreen === 'uploadFiling') {
      navigation.navigate('landing', {'screen': 'uploadFiling'})
    }
  }

  const uploadCardText = <View>
    <StyledText style={{fontWeight: 'bold'}}>Upload previous filing</StyledText>
    <StyledText style={{paddingTop: 4}}>Recommended</StyledText>
    <StyledText style={{paddingTop: 2}}>2x faster</StyledText>
  </View>

  const enterManuallyText = <View>
    <StyledText style={{fontWeight: 'bold'}}>Enter manually</StyledText>
    <StyledText style={{paddingTop: 4}}>If you don't have a previous filing</StyledText>
    <StyledText style={{paddingTop: 2}}>Slower</StyledText>
  </View>

  return (
    <Formik initialValues={formValues} enableReinitialize={true} onSubmit={(values) => {continuePressed(values)}}>
    {({ handleChange, handleBlur, handleSubmit, values, setValues, isSubmitting, setFieldValue }) => (

      <View style={{...globalStyle.landingContainer, backgroundColor: 'white'}}>
        <View style={globalStyle.landingContainerCentralWide}>
          <StyledText style={{paddingTop: 16, paddingBottom: 16, fontSize: 28, fontWeight: 'bold', marginBottom: 28,}}>How do you want to get started?</StyledText>

          <BooleanBoxField 
            name='nextScreen' 
            fieldName='nextScreen' 
            trueValue='uploadFiling' 
            trueText={uploadCardText} 
            trueImage='upload' 
            falseValue='personalInfo' 
            falseText={enterManuallyText} 
            falseImage='form'
            />
        </View>

        <View style={{ ...styles.buttonContainer, width: contentWidth }}>
          <StyledButton 
            title={'Back'} 
            onPress={() => navigation.navigate('landing', {'screen': 'filings'})} 
            disabled={false} 
            style={{ backgroundColor: 'transparent' }} 
            textStyle={{ color: globalColors.text }}
          />
          <StyledButton 
            title={'Next'} 
            onPress={() => continuePressed(values)} 
            disabled={false} 
          />
        </View>

        <View style={styles.signOutContainer}>
          <TouchableOpacity onPress={() => logOut(navigation)} style={{flexDirection: 'row'}}>
            <MaterialIcons name={"logout"} size={20} color={ globalColors.text } />
            <StyledText style={{color: globalColors.text, paddingLeft: 10, fontWeight: 'bold'}}>Sign Out</StyledText>
          </TouchableOpacity>
        </View>        
      </View>

    )}
    </Formik>

  )
 };

 const styles = StyleSheet.create({
  signOutContainer: {
    position: 'absolute',
    bottom: 50,
  },
  buttonContainer: {
    alignSelf: 'center',
    marginTop: 32,
    paddingVertical: 30,
    flexDirection: 'row',
    justifyContent:'space-between',
    borderTopWidth: 1,
    borderColor: globalColors.grey,
  }
});