export const counties = [
    {label:'Choose', value:''},
    {label:'Albany', value:'Albany'},
    {label:'Allegany', value:'Allegany'},
    {label:'Bronx', value:'Bronx'},
    {label:'Brooklyn', value:'Kings'},
    {label:'Broome', value:'Broome'},
    {label:'Cattaraugus', value:'Cattaraugus'},
    {label:'Cayuga', value:'Cayuga'},
    {label:'Chautauqua', value:'Chautauqua'},
    {label:'Chemung', value:'Chemung'},
    {label:'Chenango', value:'Chenango'},
    {label:'Clinton', value:'Clinton'},
    {label:'Columbia', value:'Columbia'},
    {label:'Cortland', value:'Cortland'},
    {label:'Delaware', value:'Delaware'},
    {label:'Dutchess', value:'Dutchess'},
    {label:'Erie', value:'Erie'},
    {label:'Essex', value:'Essex'},
    {label:'Franklin', value:'Franklin'},
    {label:'Fulton', value:'Fulton'},
    {label:'Genesee', value:'Genesee'},
    {label:'Greene', value:'Greene'},
    {label:'Hamilton', value:'Hamilton'},
    {label:'Herkimer', value:'Herkimer'},
    {label:'Jefferson', value:'Jefferson'},
    {label:'Lewis', value:'Lewis'},
    {label:'Livingston', value:'Livingston'},
    {label:'Madison', value:'Madison'},
    {label:'Monroe', value:'Monroe'},
    {label:'Montgomery', value:'Montgomery'},
    {label:'Nassau', value:'Nassau'},
    {label:'Manhattan', value:'New York'},
    {label:'Niagara', value:'Niagara'},
    {label:'Oneida', value:'Oneida'},
    {label:'Onondaga', value:'Onondaga'},
    {label:'Ontario', value:'Ontario'},
    {label:'Orange', value:'Orange'},
    {label:'Orleans', value:'Orleans'},
    {label:'Oswego', value:'Oswego'},
    {label:'Otsego', value:'Otsego'},
    {label:'Putnam', value:'Putnam'},
    {label:'Queens', value:'Queens'},
    {label:'Rensselaer', value:'Rensselaer'},
    {label:'Rockland', value:'Rockland'},
    {label:'Saratoga', value:'Saratoga'},
    {label:'Schenectady', value:'Schenectady'},
    {label:'Schoharie', value:'Schoharie'},
    {label:'Schuyler', value:'Schuyler'},
    {label:'Seneca', value:'Seneca'},
    {label:'St. Lawrence', value:'St. Lawrence'},
    {label:'Staten Island', value:'Richmond'},
    {label:'Steuben', value:'Steuben'},
    {label:'Suffolk', value:'Suffolk'},
    {label:'Sullivan', value:'Sullivan'},
    {label:'Tioga', value:'Tioga'},
    {label:'Tompkins', value:'Tompkins'},
    {label:'Ulster', value:'Ulster'},
    {label:'Warren', value:'Warren'},
    {label:'Washington', value:'Washington'},
    {label:'Wayne', value:'Wayne'},
    {label:'Westchester', value:'Westchester'},
    {label:'Wyoming', value:'Wyoming'},
    {label:'Yates', value:'Yates'}
]

export const schoolDistricts = 
{
  "Albany": [
    {
      "label": "Choose",
      "value": ""
    },
    {
      "label": "Albany (005)",
      "value": "Albany (005)"
    },
    {
      "label": "Berne-Knox-Westerlo (050)",
      "value": "Berne-Knox-Westerlo (050)"
    },
    {
      "label": "Bethlehem (051)",
      "value": "Bethlehem (051)"
    },
    {
      "label": "Cairo-Durham (076)",
      "value": "Cairo-Durham (076)"
    },
    {
      "label": "Cohoes (122)",
      "value": "Cohoes (122)"
    },
    {
      "label": "Duanesburg (153)",
      "value": "Duanesburg (153)"
    },
    {
      "label": "Green Island (236)",
      "value": "Green Island (236)"
    },
    {
      "label": "Greenville (240)",
      "value": "Greenville (240)"
    },
    {
      "label": "Guilderland (246)",
      "value": "Guilderland (246)"
    },
    {
      "label": "Menands (388)",
      "value": "Menands (388)"
    },
    {
      "label": "Middleburgh (393)",
      "value": "Middleburgh (393)"
    },
    {
      "label": "Mohonasen (402)",
      "value": "Mohonasen (402)"
    },
    {
      "label": "Niskayuna (439)",
      "value": "Niskayuna (439)"
    },
    {
      "label": "North Colonie (including Maplewood) (443)",
      "value": "North Colonie (including Maplewood) (443)"
    },
    {
      "label": "Ravena-Coeymans-Selkirk (524)",
      "value": "Ravena-Coeymans-Selkirk (524)"
    },
    {
      "label": "Schalmont (568)",
      "value": "Schalmont (568)"
    },
    {
      "label": "Schoharie (572)",
      "value": "Schoharie (572)"
    },
    {
      "label": "South Colonie (595)",
      "value": "South Colonie (595)"
    },
    {
      "label": "Voorheesville (660)",
      "value": "Voorheesville (660)"
    },
    {
      "label": "Watervliet (674)",
      "value": "Watervliet (674)"
    }
  ],
  "Allegany": [
    {
      "label": "Choose",
      "value": ""
    },
    {
      "label": "Alfred-Almond (010)",
      "value": "Alfred-Almond (010)"
    },
    {
      "label": "Andover (017)",
      "value": "Andover (017)"
    },
    {
      "label": "Arkport (021)",
      "value": "Arkport (021)"
    },
    {
      "label": "Belfast (044)",
      "value": "Belfast (044)"
    },
    {
      "label": "Bolivar-Richburg (054)",
      "value": "Bolivar-Richburg (054)"
    },
    {
      "label": "Canaseraga (083)",
      "value": "Canaseraga (083)"
    },
    {
      "label": "Canisteo-Greenwood (086)",
      "value": "Canisteo-Greenwood (086)"
    },
    {
      "label": "Cuba-Rushford (138)",
      "value": "Cuba-Rushford (138)"
    },
    {
      "label": "Dalton-Nunda (Keshequa) (320)",
      "value": "Dalton-Nunda (Keshequa) (320)"
    },
    {
      "label": "Fillmore (192)",
      "value": "Fillmore (192)"
    },
    {
      "label": "Friendship (209)",
      "value": "Friendship (209)"
    },
    {
      "label": "Genesee Valley (018)",
      "value": "Genesee Valley (018)"
    },
    {
      "label": "Hinsdale (277)",
      "value": "Hinsdale (277)"
    },
    {
      "label": "Letchworth (339)",
      "value": "Letchworth (339)"
    },
    {
      "label": "Pioneer (498)",
      "value": "Pioneer (498)"
    },
    {
      "label": "Portville (512)",
      "value": "Portville (512)"
    },
    {
      "label": "Scio (575)",
      "value": "Scio (575)"
    },
    {
      "label": "Wellsville (683)",
      "value": "Wellsville (683)"
    },
    {
      "label": "Whitesville (702)",
      "value": "Whitesville (702)"
    },
    {
      "label": " ()",
      "value": " ()"
    }
  ],
  "Bronx": [
    {
      "label": "Choose",
      "value": ""
    },
    {
      "label": "Bronx (068)",
      "value": "Bronx (068)"
    }
  ],
  "Broome": [
    {
      "label": "Choose",
      "value": ""
    },
    {
      "label": "Afton (003)",
      "value": "Afton (003)"
    },
    {
      "label": "Bainbridge-Guilford (031)",
      "value": "Bainbridge-Guilford (031)"
    },
    {
      "label": "Binghamton (053)",
      "value": "Binghamton (053)"
    },
    {
      "label": "Chenango Forks (107)",
      "value": "Chenango Forks (107)"
    },
    {
      "label": "Chenango Valley (108)",
      "value": "Chenango Valley (108)"
    },
    {
      "label": "Cincinnatus (113)",
      "value": "Cincinnatus (113)"
    },
    {
      "label": "Deposit (146)",
      "value": "Deposit (146)"
    },
    {
      "label": "Greene (238)",
      "value": "Greene (238)"
    },
    {
      "label": "Harpursville (259)",
      "value": "Harpursville (259)"
    },
    {
      "label": "Johnson City (313)",
      "value": "Johnson City (313)"
    },
    {
      "label": "Maine Endwell (364)",
      "value": "Maine Endwell (364)"
    },
    {
      "label": "Marathon (372)",
      "value": "Marathon (372)"
    },
    {
      "label": "Newark Valley (432)",
      "value": "Newark Valley (432)"
    },
    {
      "label": "South Mountain-Hickory (720)",
      "value": "South Mountain-Hickory (720)"
    },
    {
      "label": "Susquehanna Valley (627)",
      "value": "Susquehanna Valley (627)"
    },
    {
      "label": "Union-Endicott (651)",
      "value": "Union-Endicott (651)"
    },
    {
      "label": "Vestal (658)",
      "value": "Vestal (658)"
    },
    {
      "label": "Whitney Point (703)",
      "value": "Whitney Point (703)"
    },
    {
      "label": "Windsor (710)",
      "value": "Windsor (710)"
    }
  ],
  "Cattaraugus": [
    {
      "label": "Choose",
      "value": ""
    },
    {
      "label": "Allegany-Limestone (011)",
      "value": "Allegany-Limestone (011)"
    },
    {
      "label": "Cattaraugus-Little Valley (094)",
      "value": "Cattaraugus-Little Valley (094)"
    },
    {
      "label": "Cuba-Rushford (138)",
      "value": "Cuba-Rushford (138)"
    },
    {
      "label": "Ellicottville (181)",
      "value": "Ellicottville (181)"
    },
    {
      "label": "Forestville (198)",
      "value": "Forestville (198)"
    },
    {
      "label": "Franklinville (205)",
      "value": "Franklinville (205)"
    },
    {
      "label": "Frewsburg (208)",
      "value": "Frewsburg (208)"
    },
    {
      "label": "Gowanda (230)",
      "value": "Gowanda (230)"
    },
    {
      "label": "Hinsdale (277)",
      "value": "Hinsdale (277)"
    },
    {
      "label": "Olean (462)",
      "value": "Olean (462)"
    },
    {
      "label": "Pine Valley (497)",
      "value": "Pine Valley (497)"
    },
    {
      "label": "Pioneer (498)",
      "value": "Pioneer (498)"
    },
    {
      "label": "Portville (512)",
      "value": "Portville (512)"
    },
    {
      "label": "Randolph (522)",
      "value": "Randolph (522)"
    },
    {
      "label": "Salamanca (556)",
      "value": "Salamanca (556)"
    },
    {
      "label": "Springville-Griffith Institute (244)",
      "value": "Springville-Griffith Institute (244)"
    },
    {
      "label": "Ten Broeck Academy and Franklinville (205)",
      "value": "Ten Broeck Academy and Franklinville (205)"
    },
    {
      "label": "West Valley (690)",
      "value": "West Valley (690)"
    }
  ],
  "Cayuga": [
    {
      "label": "Choose",
      "value": ""
    },
    {
      "label": "Auburn (025)",
      "value": "Auburn (025)"
    },
    {
      "label": "Cato-Meridian (092)",
      "value": "Cato-Meridian (092)"
    },
    {
      "label": "Groton (245)",
      "value": "Groton (245)"
    },
    {
      "label": "Hannibal (257)",
      "value": "Hannibal (257)"
    },
    {
      "label": "Homer (281)",
      "value": "Homer (281)"
    },
    {
      "label": "Jordan-Elbridge (315)",
      "value": "Jordan-Elbridge (315)"
    },
    {
      "label": "Moravia (407)",
      "value": "Moravia (407)"
    },
    {
      "label": "Oswego (472)",
      "value": "Oswego (472)"
    },
    {
      "label": "Port Byron (507)",
      "value": "Port Byron (507)"
    },
    {
      "label": "Red Creek (525)",
      "value": "Red Creek (525)"
    },
    {
      "label": "Skaneateles (588)",
      "value": "Skaneateles (588)"
    },
    {
      "label": "Southern Cayuga (609)",
      "value": "Southern Cayuga (609)"
    },
    {
      "label": "Union Springs (650)",
      "value": "Union Springs (650)"
    },
    {
      "label": "Weedsport (681)",
      "value": "Weedsport (681)"
    }
  ],
  "Chautauqua": [
    {
      "label": "Choose",
      "value": ""
    },
    {
      "label": "Bemus Point (048)",
      "value": "Bemus Point (048)"
    },
    {
      "label": "Brocton (067)",
      "value": "Brocton (067)"
    },
    {
      "label": "Cassadaga Valley (091)",
      "value": "Cassadaga Valley (091)"
    },
    {
      "label": "Chautauqua Lake (104)",
      "value": "Chautauqua Lake (104)"
    },
    {
      "label": "Clymer (119)",
      "value": "Clymer (119)"
    },
    {
      "label": "Dunkirk (155)",
      "value": "Dunkirk (155)"
    },
    {
      "label": "Falconer (189)",
      "value": "Falconer (189)"
    },
    {
      "label": "Forestville (198)",
      "value": "Forestville (198)"
    },
    {
      "label": "Fredonia (206)",
      "value": "Fredonia (206)"
    },
    {
      "label": "Frewsburg (208)",
      "value": "Frewsburg (208)"
    },
    {
      "label": "Gowanda (230)",
      "value": "Gowanda (230)"
    },
    {
      "label": "Jamestown (306)",
      "value": "Jamestown (306)"
    },
    {
      "label": "Panama (479)",
      "value": "Panama (479)"
    },
    {
      "label": "Pine Valley (497)",
      "value": "Pine Valley (497)"
    },
    {
      "label": "Randolph (522)",
      "value": "Randolph (522)"
    },
    {
      "label": "Ripley (536)",
      "value": "Ripley (536)"
    },
    {
      "label": "Sherman (583)",
      "value": "Sherman (583)"
    },
    {
      "label": "Silver Creek (587)",
      "value": "Silver Creek (587)"
    },
    {
      "label": "Southwestern (611)",
      "value": "Southwestern (611)"
    },
    {
      "label": "Westfield Academy and Central (692)",
      "value": "Westfield Academy and Central (692)"
    }
  ],
  "Chemung": [
    {
      "label": "Choose",
      "value": ""
    },
    {
      "label": "Corning-Painted Post (132)",
      "value": "Corning-Painted Post (132)"
    },
    {
      "label": "Elmira (182)",
      "value": "Elmira (182)"
    },
    {
      "label": "Elmira Heights (183)",
      "value": "Elmira Heights (183)"
    },
    {
      "label": "Horseheads (287)",
      "value": "Horseheads (287)"
    },
    {
      "label": "Newfield (436)",
      "value": "Newfield (436)"
    },
    {
      "label": "Odessa Montour (460)",
      "value": "Odessa Montour (460)"
    },
    {
      "label": "Spencer Van Etten (613)",
      "value": "Spencer Van Etten (613)"
    },
    {
      "label": "Watkins Glen (675)",
      "value": "Watkins Glen (675)"
    },
    {
      "label": "Waverly (676)",
      "value": "Waverly (676)"
    }
  ],
  "Chenango": [
    {
      "label": "Choose",
      "value": ""
    },
    {
      "label": "Afton (003)",
      "value": "Afton (003)"
    },
    {
      "label": "Bainbridge-Guilford (031)",
      "value": "Bainbridge-Guilford (031)"
    },
    {
      "label": "Brookfield (070)",
      "value": "Brookfield (070)"
    },
    {
      "label": "Chenango Forks (107)",
      "value": "Chenango Forks (107)"
    },
    {
      "label": "Cincinnatus (113)",
      "value": "Cincinnatus (113)"
    },
    {
      "label": "DeRuyter (141)",
      "value": "DeRuyter (141)"
    },
    {
      "label": "Gilbertsville-Mount. Upton (222)",
      "value": "Gilbertsville-Mount. Upton (222)"
    },
    {
      "label": "Greene (238)",
      "value": "Greene (238)"
    },
    {
      "label": "Harpursville (259)",
      "value": "Harpursville (259)"
    },
    {
      "label": "Norwich (455)",
      "value": "Norwich (455)"
    },
    {
      "label": "Otselic Valley (606)",
      "value": "Otselic Valley (606)"
    },
    {
      "label": "Oxford Academy and Central (475)",
      "value": "Oxford Academy and Central (475)"
    },
    {
      "label": "Sherburne-Earlville (582)",
      "value": "Sherburne-Earlville (582)"
    },
    {
      "label": "Sidney (586)",
      "value": "Sidney (586)"
    },
    {
      "label": "Unadilla Valley (422)",
      "value": "Unadilla Valley (422)"
    },
    {
      "label": "Whitney Point (703)",
      "value": "Whitney Point (703)"
    }
  ],
  "Clinton": [
    {
      "label": "Choose",
      "value": ""
    },
    {
      "label": "AuSable Valley (026)",
      "value": "AuSable Valley (026)"
    },
    {
      "label": "Beekmantown (043)",
      "value": "Beekmantown (043)"
    },
    {
      "label": "Chateaugay (102)",
      "value": "Chateaugay (102)"
    },
    {
      "label": "Chazy (105)",
      "value": "Chazy (105)"
    },
    {
      "label": "Northeastern Clinton (418)",
      "value": "Northeastern Clinton (418)"
    },
    {
      "label": "Northern Adirondack (453)",
      "value": "Northern Adirondack (453)"
    },
    {
      "label": "Peru (492)",
      "value": "Peru (492)"
    },
    {
      "label": "Plattsburgh (503)",
      "value": "Plattsburgh (503)"
    },
    {
      "label": "Saranac (560)",
      "value": "Saranac (560)"
    },
    {
      "label": "Saranac Lake (561)",
      "value": "Saranac Lake (561)"
    }
  ],
  "Columbia": [
    {
      "label": "Choose",
      "value": ""
    },
    {
      "label": "Chatham (103)",
      "value": "Chatham (103)"
    },
    {
      "label": "East Greenbush (158)",
      "value": "East Greenbush (158)"
    },
    {
      "label": "Germantown (221)",
      "value": "Germantown (221)"
    },
    {
      "label": "Hudson (289)",
      "value": "Hudson (289)"
    },
    {
      "label": "Ichabod Crane (294)",
      "value": "Ichabod Crane (294)"
    },
    {
      "label": "New Lebanon (426)",
      "value": "New Lebanon (426)"
    },
    {
      "label": "Pine Plains (496)",
      "value": "Pine Plains (496)"
    },
    {
      "label": "Red Hook (526)",
      "value": "Red Hook (526)"
    },
    {
      "label": "Schodack (571)",
      "value": "Schodack (571)"
    },
    {
      "label": "Taconic Hills (632)",
      "value": "Taconic Hills (632)"
    },
    {
      "label": "Webutuck (680)",
      "value": "Webutuck (680)"
    }
  ],
  "Cortland": [
    {
      "label": "Choose",
      "value": ""
    },
    {
      "label": "Cincinnatus (113)",
      "value": "Cincinnatus (113)"
    },
    {
      "label": "Cortland (134)",
      "value": "Cortland (134)"
    },
    {
      "label": "DeRuyter (141)",
      "value": "DeRuyter (141)"
    },
    {
      "label": "Dryden (152)",
      "value": "Dryden (152)"
    },
    {
      "label": "Fabius-Pompey (187)",
      "value": "Fabius-Pompey (187)"
    },
    {
      "label": "Greene (238)",
      "value": "Greene (238)"
    },
    {
      "label": "Groton (245)",
      "value": "Groton (245)"
    },
    {
      "label": "Homer (281)",
      "value": "Homer (281)"
    },
    {
      "label": "Marathon (372)",
      "value": "Marathon (372)"
    },
    {
      "label": "McGraw (385)",
      "value": "McGraw (385)"
    },
    {
      "label": "Newark Valley (432)",
      "value": "Newark Valley (432)"
    },
    {
      "label": "Tully (646)",
      "value": "Tully (646)"
    },
    {
      "label": "Whitney Point (703)",
      "value": "Whitney Point (703)"
    }
  ],
  "Delaware": [
    {
      "label": "Choose",
      "value": ""
    },
    {
      "label": "Andes (016)",
      "value": "Andes (016)"
    },
    {
      "label": "Bainbridge Guilford (031)",
      "value": "Bainbridge Guilford (031)"
    },
    {
      "label": "Charlotte Valley (101)",
      "value": "Charlotte Valley (101)"
    },
    {
      "label": "Delhi (144)",
      "value": "Delhi (144)"
    },
    {
      "label": "Deposit (146)",
      "value": "Deposit (146)"
    },
    {
      "label": "Downsville (150)",
      "value": "Downsville (150)"
    },
    {
      "label": "Franklin (203)",
      "value": "Franklin (203)"
    },
    {
      "label": "Gilboa-Conesville (223)",
      "value": "Gilboa-Conesville (223)"
    },
    {
      "label": "Hancock (256)",
      "value": "Hancock (256)"
    },
    {
      "label": "Jefferson (310)",
      "value": "Jefferson (310)"
    },
    {
      "label": "Livingston Manor (349)",
      "value": "Livingston Manor (349)"
    },
    {
      "label": "Margaretville (375)",
      "value": "Margaretville (375)"
    },
    {
      "label": "Oneonta (464)",
      "value": "Oneonta (464)"
    },
    {
      "label": "Roscoe (545)",
      "value": "Roscoe (545)"
    },
    {
      "label": "Roxbury (547)",
      "value": "Roxbury (547)"
    },
    {
      "label": "Sidney (586)",
      "value": "Sidney (586)"
    },
    {
      "label": "South Kortright (601)",
      "value": "South Kortright (601)"
    },
    {
      "label": "Stamford (620)",
      "value": "Stamford (620)"
    },
    {
      "label": "Sullivan West (143)",
      "value": "Sullivan West (143)"
    },
    {
      "label": "Unatego (649)",
      "value": "Unatego (649)"
    },
    {
      "label": "Walton (663)",
      "value": "Walton (663)"
    },
    {
      "label": "Worcester (711)",
      "value": "Worcester (711)"
    }
  ],
  "Dutchess": [
    {
      "label": "Choose",
      "value": ""
    },
    {
      "label": "Arlington (022)",
      "value": "Arlington (022)"
    },
    {
      "label": "Beacon (040)",
      "value": "Beacon (040)"
    },
    {
      "label": "Carmel (089)",
      "value": "Carmel (089)"
    },
    {
      "label": "Dover (149)",
      "value": "Dover (149)"
    },
    {
      "label": "Haldane (249)",
      "value": "Haldane (249)"
    },
    {
      "label": "Hyde Park (293)",
      "value": "Hyde Park (293)"
    },
    {
      "label": "Millbrook (396)",
      "value": "Millbrook (396)"
    },
    {
      "label": "Pawling (483)",
      "value": "Pawling (483)"
    },
    {
      "label": "Pine Plains (496)",
      "value": "Pine Plains (496)"
    },
    {
      "label": "Poughkeepsie (514)",
      "value": "Poughkeepsie (514)"
    },
    {
      "label": "Red Hook (526)",
      "value": "Red Hook (526)"
    },
    {
      "label": "Rhinebeck (531)",
      "value": "Rhinebeck (531)"
    },
    {
      "label": "Spackenkill (612)",
      "value": "Spackenkill (612)"
    },
    {
      "label": "Taconic Hills (632)",
      "value": "Taconic Hills (632)"
    },
    {
      "label": "Wappingers (665)",
      "value": "Wappingers (665)"
    },
    {
      "label": "Webutuck (680)",
      "value": "Webutuck (680)"
    }
  ],
  "Erie": [
    {
      "label": "Choose",
      "value": ""
    },
    {
      "label": "Akron (004)",
      "value": "Akron (004)"
    },
    {
      "label": "Alden (007)",
      "value": "Alden (007)"
    },
    {
      "label": "Amherst (719)",
      "value": "Amherst (719)"
    },
    {
      "label": "Attica (024)",
      "value": "Attica (024)"
    },
    {
      "label": "Buffalo (073)",
      "value": "Buffalo (073)"
    },
    {
      "label": "Cheektowaga (106)",
      "value": "Cheektowaga (106)"
    },
    {
      "label": "Cheektowaga-Sloan (589)",
      "value": "Cheektowaga-Sloan (589)"
    },
    {
      "label": "Clarence (114)",
      "value": "Clarence (114)"
    },
    {
      "label": "Cleveland Hill (115)",
      "value": "Cleveland Hill (115)"
    },
    {
      "label": "Depew (145)",
      "value": "Depew (145)"
    },
    {
      "label": "East Aurora (156)",
      "value": "East Aurora (156)"
    },
    {
      "label": "Eden (171)",
      "value": "Eden (171)"
    },
    {
      "label": "Frontier (210)",
      "value": "Frontier (210)"
    },
    {
      "label": "Gowanda (230)",
      "value": "Gowanda (230)"
    },
    {
      "label": "Grand Island (232)",
      "value": "Grand Island (232)"
    },
    {
      "label": "Hamburg (251)",
      "value": "Hamburg (251)"
    },
    {
      "label": "Holland (278)",
      "value": "Holland (278)"
    },
    {
      "label": "Iroquois (300)",
      "value": "Iroquois (300)"
    },
    {
      "label": "Kenmore-Town of Tonawanda (319)",
      "value": "Kenmore-Town of Tonawanda (319)"
    },
    {
      "label": "Lackawanna (326)",
      "value": "Lackawanna (326)"
    },
    {
      "label": "Lake Shore (330)",
      "value": "Lake Shore (330)"
    },
    {
      "label": "Lancaster (332)",
      "value": "Lancaster (332)"
    },
    {
      "label": "Maryvale (378)",
      "value": "Maryvale (378)"
    },
    {
      "label": "North Collins (442)",
      "value": "North Collins (442)"
    },
    {
      "label": "Orchard Park (468)",
      "value": "Orchard Park (468)"
    },
    {
      "label": "Pioneer (498)",
      "value": "Pioneer (498)"
    },
    {
      "label": "Silver Creek (587)",
      "value": "Silver Creek (587)"
    },
    {
      "label": "Springville-Griffith Institute (244)",
      "value": "Springville-Griffith Institute (244)"
    },
    {
      "label": "Sweet Home (628)",
      "value": "Sweet Home (628)"
    },
    {
      "label": "Tonawanda City (638)",
      "value": "Tonawanda City (638)"
    },
    {
      "label": "West Seneca (689)",
      "value": "West Seneca (689)"
    },
    {
      "label": "Williamsville (706)",
      "value": "Williamsville (706)"
    }
  ],
  "Essex": [
    {
      "label": "Choose",
      "value": ""
    },
    {
      "label": "AuSable Valley (026)",
      "value": "AuSable Valley (026)"
    },
    {
      "label": "Boquet Valley (179)",
      "value": "Boquet Valley (179)"
    },
    {
      "label": "Crown Point (137)",
      "value": "Crown Point (137)"
    },
    {
      "label": "Keene (317)",
      "value": "Keene (317)"
    },
    {
      "label": "Lake Placid (328)",
      "value": "Lake Placid (328)"
    },
    {
      "label": "Minerva (399)",
      "value": "Minerva (399)"
    },
    {
      "label": "Moriah (408)",
      "value": "Moriah (408)"
    },
    {
      "label": "Newcomb (434)",
      "value": "Newcomb (434)"
    },
    {
      "label": "Putnam (517)",
      "value": "Putnam (517)"
    },
    {
      "label": "Saranac Lake (561)",
      "value": "Saranac Lake (561)"
    },
    {
      "label": "Schroon Lake (573)",
      "value": "Schroon Lake (573)"
    },
    {
      "label": "Ticonderoga (636)",
      "value": "Ticonderoga (636)"
    },
    {
      "label": "Willsboro (707)",
      "value": "Willsboro (707)"
    }
  ],
  "Franklin": [
    {
      "label": "Choose",
      "value": ""
    },
    {
      "label": "AuSable Valley (026)",
      "value": "AuSable Valley (026)"
    },
    {
      "label": "Brasher Falls (058)",
      "value": "Brasher Falls (058)"
    },
    {
      "label": "Brushton-Moira (072)",
      "value": "Brushton-Moira (072)"
    },
    {
      "label": "Chateaugay (102)",
      "value": "Chateaugay (102)"
    },
    {
      "label": "Malone (365)",
      "value": "Malone (365)"
    },
    {
      "label": "Northern Adirondack (453)",
      "value": "Northern Adirondack (453)"
    },
    {
      "label": "Salmon River (558)",
      "value": "Salmon River (558)"
    },
    {
      "label": "Saranac Lake (561)",
      "value": "Saranac Lake (561)"
    },
    {
      "label": "St. Regis Falls (619)",
      "value": "St. Regis Falls (619)"
    },
    {
      "label": "Tupper Lake (647)",
      "value": "Tupper Lake (647)"
    }
  ],
  "Fulton": [
    {
      "label": "Choose",
      "value": ""
    },
    {
      "label": "Amsterdam (015)",
      "value": "Amsterdam (015)"
    },
    {
      "label": "Broadalbin-Perth (065)",
      "value": "Broadalbin-Perth (065)"
    },
    {
      "label": "Dolgeville (148)",
      "value": "Dolgeville (148)"
    },
    {
      "label": "Edinburg (173)",
      "value": "Edinburg (173)"
    },
    {
      "label": "Fonda-Fultonville (197)",
      "value": "Fonda-Fultonville (197)"
    },
    {
      "label": "Fort Plain (201)",
      "value": "Fort Plain (201)"
    },
    {
      "label": "Galway (212)",
      "value": "Galway (212)"
    },
    {
      "label": "Gloversville (227)",
      "value": "Gloversville (227)"
    },
    {
      "label": "Johnstown (314)",
      "value": "Johnstown (314)"
    },
    {
      "label": "Mayfield (383)",
      "value": "Mayfield (383)"
    },
    {
      "label": "Northville (454)",
      "value": "Northville (454)"
    },
    {
      "label": "Oppenheim-Ephratah-St.Johnsville (467)",
      "value": "Oppenheim-Ephratah-St.Johnsville (467)"
    },
    {
      "label": "Wheelerville (698)",
      "value": "Wheelerville (698)"
    }
  ],
  "Greene": [
    {
      "label": "Choose",
      "value": ""
    },
    {
      "label": "Cairo-Durham (076)",
      "value": "Cairo-Durham (076)"
    },
    {
      "label": "Catskill (093)",
      "value": "Catskill (093)"
    },
    {
      "label": "Coxsackie-Athens (135)",
      "value": "Coxsackie-Athens (135)"
    },
    {
      "label": "Gilboa-Conesville (223)",
      "value": "Gilboa-Conesville (223)"
    },
    {
      "label": "Greenville (240)",
      "value": "Greenville (240)"
    },
    {
      "label": "Hunter-Tannersville (291)",
      "value": "Hunter-Tannersville (291)"
    },
    {
      "label": "Margaretville (375)",
      "value": "Margaretville (375)"
    },
    {
      "label": "Onteora (466)",
      "value": "Onteora (466)"
    },
    {
      "label": "Ravena-Coeymans-Selkirk (524)",
      "value": "Ravena-Coeymans-Selkirk (524)"
    },
    {
      "label": "Windham-Ashland-Jewett (709)",
      "value": "Windham-Ashland-Jewett (709)"
    }
  ],
  "Hamilton": [
    {
      "label": "Choose",
      "value": ""
    },
    {
      "label": "Indian Lake (296)",
      "value": "Indian Lake (296)"
    },
    {
      "label": "Inlet (298)",
      "value": "Inlet (298)"
    },
    {
      "label": "Lake Pleasant (329)",
      "value": "Lake Pleasant (329)"
    },
    {
      "label": "Long Lake (354)",
      "value": "Long Lake (354)"
    },
    {
      "label": "Northville (454)",
      "value": "Northville (454)"
    },
    {
      "label": "Piseco (499)",
      "value": "Piseco (499)"
    },
    {
      "label": "Poland (506)",
      "value": "Poland (506)"
    },
    {
      "label": "Raquette Lake (523)",
      "value": "Raquette Lake (523)"
    },
    {
      "label": "Wells (682)",
      "value": "Wells (682)"
    }
  ],
  "Herkimer": [
    {
      "label": "Choose",
      "value": ""
    },
    {
      "label": "Adirondack (002)",
      "value": "Adirondack (002)"
    },
    {
      "label": "Central Valley (295)",
      "value": "Central Valley (295)"
    },
    {
      "label": "Cherry Valley-Springfield (616)",
      "value": "Cherry Valley-Springfield (616)"
    },
    {
      "label": "Dolgeville (148)",
      "value": "Dolgeville (148)"
    },
    {
      "label": "Fort Plain (201)",
      "value": "Fort Plain (201)"
    },
    {
      "label": "Frankfort-Schuyler (202)",
      "value": "Frankfort-Schuyler (202)"
    },
    {
      "label": "Herkimer (268)",
      "value": "Herkimer (268)"
    },
    {
      "label": "Holland Patent (279)",
      "value": "Holland Patent (279)"
    },
    {
      "label": "Little Falls (346)",
      "value": "Little Falls (346)"
    },
    {
      "label": "Mount Markham (412)",
      "value": "Mount Markham (412)"
    },
    {
      "label": "New Hartford (424)",
      "value": "New Hartford (424)"
    },
    {
      "label": "Oppenheim-Ephratah-St. Johnsville (467)",
      "value": "Oppenheim-Ephratah-St. Johnsville (467)"
    },
    {
      "label": "Owen D. Young (Van Hornesville) (474)",
      "value": "Owen D. Young (Van Hornesville) (474)"
    },
    {
      "label": "Poland (506)",
      "value": "Poland (506)"
    },
    {
      "label": "Remsen (528)",
      "value": "Remsen (528)"
    },
    {
      "label": "Richfield Springs (533)",
      "value": "Richfield Springs (533)"
    },
    {
      "label": "Sauquoit Valley (564)",
      "value": "Sauquoit Valley (564)"
    },
    {
      "label": "Town of Webb (639)",
      "value": "Town of Webb (639)"
    },
    {
      "label": "West Canada Valley (685)",
      "value": "West Canada Valley (685)"
    }
  ],
  "Jefferson": [
    {
      "label": "Choose",
      "value": ""
    },
    {
      "label": "Alexandria (009)",
      "value": "Alexandria (009)"
    },
    {
      "label": "Belleville Henderson (045)",
      "value": "Belleville Henderson (045)"
    },
    {
      "label": "Carthage (090)",
      "value": "Carthage (090)"
    },
    {
      "label": "Copenhagen (129)",
      "value": "Copenhagen (129)"
    },
    {
      "label": "General Brown (217)",
      "value": "General Brown (217)"
    },
    {
      "label": "Gouverneur (229)",
      "value": "Gouverneur (229)"
    },
    {
      "label": "Hammond (253)",
      "value": "Hammond (253)"
    },
    {
      "label": "Indian River (297)",
      "value": "Indian River (297)"
    },
    {
      "label": "LaFargeville (324)",
      "value": "LaFargeville (324)"
    },
    {
      "label": "Lyme (356)",
      "value": "Lyme (356)"
    },
    {
      "label": "Sackets Harbor (288)",
      "value": "Sackets Harbor (288)"
    },
    {
      "label": "Sandy Creek (559)",
      "value": "Sandy Creek (559)"
    },
    {
      "label": "South Jefferson (600)",
      "value": "South Jefferson (600)"
    },
    {
      "label": "Thousand Islands (634)",
      "value": "Thousand Islands (634)"
    },
    {
      "label": "Watertown (672)",
      "value": "Watertown (672)"
    }
  ],
  "Kings": [
    {
      "label": "Choose",
      "value": ""
    },
    {
      "label": "Brooklyn (071)",
      "value": "Brooklyn (071)"
    }
  ],
  "Lewis": [
    {
      "label": "Choose",
      "value": ""
    },
    {
      "label": "Adirondack (002)",
      "value": "Adirondack (002)"
    },
    {
      "label": "Beaver River (041)",
      "value": "Beaver River (041)"
    },
    {
      "label": "Camden (079)",
      "value": "Camden (079)"
    },
    {
      "label": "Carthage (090)",
      "value": "Carthage (090)"
    },
    {
      "label": "Copenhagen (129)",
      "value": "Copenhagen (129)"
    },
    {
      "label": "Harrisville (261)",
      "value": "Harrisville (261)"
    },
    {
      "label": "Lowville Academy and Central (355)",
      "value": "Lowville Academy and Central (355)"
    },
    {
      "label": "Sandy Creek (559)",
      "value": "Sandy Creek (559)"
    },
    {
      "label": "South Jefferson (600)",
      "value": "South Jefferson (600)"
    },
    {
      "label": "South Lewis (602)",
      "value": "South Lewis (602)"
    }
  ],
  "Livingston": [
    {
      "label": "Choose",
      "value": ""
    },
    {
      "label": "Avon (029)",
      "value": "Avon (029)"
    },
    {
      "label": "Caledonia-Mumford (077)",
      "value": "Caledonia-Mumford (077)"
    },
    {
      "label": "Canaseraga (083)",
      "value": "Canaseraga (083)"
    },
    {
      "label": "Dalton-Nunda (Keshequa) (320)",
      "value": "Dalton-Nunda (Keshequa) (320)"
    },
    {
      "label": "Dansville (140)",
      "value": "Dansville (140)"
    },
    {
      "label": "Geneseo (218)",
      "value": "Geneseo (218)"
    },
    {
      "label": "Honeoye (282)",
      "value": "Honeoye (282)"
    },
    {
      "label": "Honeoye Falls-Lima (283)",
      "value": "Honeoye Falls-Lima (283)"
    },
    {
      "label": "Le Roy (338)",
      "value": "Le Roy (338)"
    },
    {
      "label": "Livonia (350)",
      "value": "Livonia (350)"
    },
    {
      "label": "Mount Morris (413)",
      "value": "Mount Morris (413)"
    },
    {
      "label": "Naples (420)",
      "value": "Naples (420)"
    },
    {
      "label": "Pavilion (482)",
      "value": "Pavilion (482)"
    },
    {
      "label": "Perry (490)",
      "value": "Perry (490)"
    },
    {
      "label": "Wayland-Cohocton (677)",
      "value": "Wayland-Cohocton (677)"
    },
    {
      "label": "Wheatland-Chili (697)",
      "value": "Wheatland-Chili (697)"
    },
    {
      "label": "York (716)",
      "value": "York (716)"
    }
  ],
  "Madison": [
    {
      "label": "Choose",
      "value": ""
    },
    {
      "label": "Brookfield (070)",
      "value": "Brookfield (070)"
    },
    {
      "label": "Canastota (084)",
      "value": "Canastota (084)"
    },
    {
      "label": "Cazenovia (095)",
      "value": "Cazenovia (095)"
    },
    {
      "label": "Chittenango (111)",
      "value": "Chittenango (111)"
    },
    {
      "label": "DeRuyter (141)",
      "value": "DeRuyter (141)"
    },
    {
      "label": "East Syracuse-Minoa (167)",
      "value": "East Syracuse-Minoa (167)"
    },
    {
      "label": "Edmeston (174)",
      "value": "Edmeston (174)"
    },
    {
      "label": "Fabius-Pompey (187)",
      "value": "Fabius-Pompey (187)"
    },
    {
      "label": "Fayetteville-Manlius (370)",
      "value": "Fayetteville-Manlius (370)"
    },
    {
      "label": "Hamilton (252)",
      "value": "Hamilton (252)"
    },
    {
      "label": "Madison (361)",
      "value": "Madison (361)"
    },
    {
      "label": "Morrisville-Eaton (411)",
      "value": "Morrisville-Eaton (411)"
    },
    {
      "label": "Mount Markham (412)",
      "value": "Mount Markham (412)"
    },
    {
      "label": "Oneida (463)",
      "value": "Oneida (463)"
    },
    {
      "label": "Otselic Valley (606)",
      "value": "Otselic Valley (606)"
    },
    {
      "label": "Sherburne-Earlville (582)",
      "value": "Sherburne-Earlville (582)"
    },
    {
      "label": "Stockbridge Valley (624)",
      "value": "Stockbridge Valley (624)"
    },
    {
      "label": "Unadilla Valley (422)",
      "value": "Unadilla Valley (422)"
    },
    {
      "label": "Vernon-Verona-Sherrill (584)",
      "value": "Vernon-Verona-Sherrill (584)"
    },
    {
      "label": "Waterville (673)",
      "value": "Waterville (673)"
    }
  ],
  "Monroe": [
    {
      "label": "Choose",
      "value": ""
    },
    {
      "label": "Avon (029)",
      "value": "Avon (029)"
    },
    {
      "label": "Brighton (063)",
      "value": "Brighton (063)"
    },
    {
      "label": "Brockport (066)",
      "value": "Brockport (066)"
    },
    {
      "label": "Byron Bergen (075)",
      "value": "Byron Bergen (075)"
    },
    {
      "label": "Caledonia-Mumford (077)",
      "value": "Caledonia-Mumford (077)"
    },
    {
      "label": "Churchville-Chili (112)",
      "value": "Churchville-Chili (112)"
    },
    {
      "label": "East Irondequoit (160)",
      "value": "East Irondequoit (160)"
    },
    {
      "label": "East Rochester (165)",
      "value": "East Rochester (165)"
    },
    {
      "label": "Fairport (188)",
      "value": "Fairport (188)"
    },
    {
      "label": "Gates Chili (216)",
      "value": "Gates Chili (216)"
    },
    {
      "label": "Greece (235)",
      "value": "Greece (235)"
    },
    {
      "label": "Hilton (276)",
      "value": "Hilton (276)"
    },
    {
      "label": "Holley (280)",
      "value": "Holley (280)"
    },
    {
      "label": "Honeoye Falls-Lima (283)",
      "value": "Honeoye Falls-Lima (283)"
    },
    {
      "label": "Kendall (318)",
      "value": "Kendall (318)"
    },
    {
      "label": "Penfield (488)",
      "value": "Penfield (488)"
    },
    {
      "label": "Pittsford (500)",
      "value": "Pittsford (500)"
    },
    {
      "label": "Rochester (538)",
      "value": "Rochester (538)"
    },
    {
      "label": "Rush-Henrietta (549)",
      "value": "Rush-Henrietta (549)"
    },
    {
      "label": "Spencerport (614)",
      "value": "Spencerport (614)"
    },
    {
      "label": "Victor (659)",
      "value": "Victor (659)"
    },
    {
      "label": "Wayne (678)",
      "value": "Wayne (678)"
    },
    {
      "label": "Webster (679)",
      "value": "Webster (679)"
    },
    {
      "label": "West Irondequoit (299)",
      "value": "West Irondequoit (299)"
    },
    {
      "label": "Wheatland-Chili (697)",
      "value": "Wheatland-Chili (697)"
    }
  ],
  "Montgomery": [
    {
      "label": "Choose",
      "value": ""
    },
    {
      "label": "Amsterdam (015)",
      "value": "Amsterdam (015)"
    },
    {
      "label": "Broadalbin-Perth (065)",
      "value": "Broadalbin-Perth (065)"
    },
    {
      "label": "Canajoharie (081)",
      "value": "Canajoharie (081)"
    },
    {
      "label": "Cherry Valley-Springfield (616)",
      "value": "Cherry Valley-Springfield (616)"
    },
    {
      "label": "Cobleskill-Richmondville (120)",
      "value": "Cobleskill-Richmondville (120)"
    },
    {
      "label": "Duanesburg (153)",
      "value": "Duanesburg (153)"
    },
    {
      "label": "Fonda-Fultonville (197)",
      "value": "Fonda-Fultonville (197)"
    },
    {
      "label": "Fort Plain (201)",
      "value": "Fort Plain (201)"
    },
    {
      "label": "Galway (212)",
      "value": "Galway (212)"
    },
    {
      "label": "Johnstown (314)",
      "value": "Johnstown (314)"
    },
    {
      "label": "Oppenheim-Ephratah-St. Johnsville (467)",
      "value": "Oppenheim-Ephratah-St. Johnsville (467)"
    },
    {
      "label": "Owen D. Young (Van Hornesville) (474)",
      "value": "Owen D. Young (Van Hornesville) (474)"
    },
    {
      "label": "Schalmont (568)",
      "value": "Schalmont (568)"
    },
    {
      "label": "Schoharie (572)",
      "value": "Schoharie (572)"
    },
    {
      "label": "Scotia-Glenville (576)",
      "value": "Scotia-Glenville (576)"
    },
    {
      "label": "Sharon Springs (579)",
      "value": "Sharon Springs (579)"
    }
  ],
  "New York": [
    {
      "label": "Choose",
      "value": ""
    },
    {
      "label": "Manhattan (369)",
      "value": "Manhattan (369)"
    }
  ],
  "Niagara": [
    {
      "label": "Choose",
      "value": ""
    },
    {
      "label": "Akron (004)",
      "value": "Akron (004)"
    },
    {
      "label": "Barker (035)",
      "value": "Barker (035)"
    },
    {
      "label": "Lewiston-Porter (341)",
      "value": "Lewiston-Porter (341)"
    },
    {
      "label": "Lockport (351)",
      "value": "Lockport (351)"
    },
    {
      "label": "Medina (387)",
      "value": "Medina (387)"
    },
    {
      "label": "Newfane (435)",
      "value": "Newfane (435)"
    },
    {
      "label": "Niagara Falls (437)",
      "value": "Niagara Falls (437)"
    },
    {
      "label": "Niagara Wheatfield (438)",
      "value": "Niagara Wheatfield (438)"
    },
    {
      "label": "North Tonawanda (450)",
      "value": "North Tonawanda (450)"
    },
    {
      "label": "Royalton-Hartland (548)",
      "value": "Royalton-Hartland (548)"
    },
    {
      "label": "Starpoint (621)",
      "value": "Starpoint (621)"
    },
    {
      "label": "Wilson (708)",
      "value": "Wilson (708)"
    }
  ],
  "Oneida": [
    {
      "label": "Choose",
      "value": ""
    },
    {
      "label": "Adirondack (002)",
      "value": "Adirondack (002)"
    },
    {
      "label": "Brookfield (070)",
      "value": "Brookfield (070)"
    },
    {
      "label": "Camden (079)",
      "value": "Camden (079)"
    },
    {
      "label": "Central Square (098)",
      "value": "Central Square (098)"
    },
    {
      "label": "Clinton (117)",
      "value": "Clinton (117)"
    },
    {
      "label": "Holland Patent (279)",
      "value": "Holland Patent (279)"
    },
    {
      "label": "Madison (361)",
      "value": "Madison (361)"
    },
    {
      "label": "Mount Markham (412)",
      "value": "Mount Markham (412)"
    },
    {
      "label": "New Hartford (424)",
      "value": "New Hartford (424)"
    },
    {
      "label": "New York Mills (430)",
      "value": "New York Mills (430)"
    },
    {
      "label": "Oneida (463)",
      "value": "Oneida (463)"
    },
    {
      "label": "Oriskany (469)",
      "value": "Oriskany (469)"
    },
    {
      "label": "Poland (506)",
      "value": "Poland (506)"
    },
    {
      "label": "Remsen (528)",
      "value": "Remsen (528)"
    },
    {
      "label": "Rome (541)",
      "value": "Rome (541)"
    },
    {
      "label": "Sauquoit Valley (564)",
      "value": "Sauquoit Valley (564)"
    },
    {
      "label": "Stockbridge Valley (624)",
      "value": "Stockbridge Valley (624)"
    },
    {
      "label": "Town of Webb (639)",
      "value": "Town of Webb (639)"
    },
    {
      "label": "Utica (653)",
      "value": "Utica (653)"
    },
    {
      "label": "Vernon-Verona-Sherrill (584)",
      "value": "Vernon-Verona-Sherrill (584)"
    },
    {
      "label": "Waterville (673)",
      "value": "Waterville (673)"
    },
    {
      "label": "West Canada Valley (685)",
      "value": "West Canada Valley (685)"
    },
    {
      "label": "Westmoreland (695)",
      "value": "Westmoreland (695)"
    },
    {
      "label": "Whitesboro (701)",
      "value": "Whitesboro (701)"
    }
  ],
  "Onondaga": [
    {
      "label": "Choose",
      "value": ""
    },
    {
      "label": "Baldwinsville (033)",
      "value": "Baldwinsville (033)"
    },
    {
      "label": "Cato Meridian (092)",
      "value": "Cato Meridian (092)"
    },
    {
      "label": "Cazenovia (095)",
      "value": "Cazenovia (095)"
    },
    {
      "label": "Central Square (098)",
      "value": "Central Square (098)"
    },
    {
      "label": "Chittenango (111)",
      "value": "Chittenango (111)"
    },
    {
      "label": "DeRuyter (141)",
      "value": "DeRuyter (141)"
    },
    {
      "label": "East Syracuse-Minoa (167)",
      "value": "East Syracuse-Minoa (167)"
    },
    {
      "label": "Fabius-Pompey (187)",
      "value": "Fabius-Pompey (187)"
    },
    {
      "label": "Fayetteville-Manlius (370)",
      "value": "Fayetteville-Manlius (370)"
    },
    {
      "label": "Homer (281)",
      "value": "Homer (281)"
    },
    {
      "label": "Jamesville-Dewitt (307)",
      "value": "Jamesville-Dewitt (307)"
    },
    {
      "label": "Jordan Elbridge (315)",
      "value": "Jordan Elbridge (315)"
    },
    {
      "label": "LaFayette (325)",
      "value": "LaFayette (325)"
    },
    {
      "label": "Liverpool (348)",
      "value": "Liverpool (348)"
    },
    {
      "label": "Lyncourt (358)",
      "value": "Lyncourt (358)"
    },
    {
      "label": "Marcellus (373)",
      "value": "Marcellus (373)"
    },
    {
      "label": "Moravia (407)",
      "value": "Moravia (407)"
    },
    {
      "label": "North Syracuse (449)",
      "value": "North Syracuse (449)"
    },
    {
      "label": "Onondaga (465)",
      "value": "Onondaga (465)"
    },
    {
      "label": "Phoenix (494)",
      "value": "Phoenix (494)"
    },
    {
      "label": "Skaneateles (588)",
      "value": "Skaneateles (588)"
    },
    {
      "label": "Solvay (593)",
      "value": "Solvay (593)"
    },
    {
      "label": "Syracuse (631)",
      "value": "Syracuse (631)"
    },
    {
      "label": "Tully (646)",
      "value": "Tully (646)"
    },
    {
      "label": "West Genesee (686)",
      "value": "West Genesee (686)"
    },
    {
      "label": "Westhill (694)",
      "value": "Westhill (694)"
    }
  ],
  "Ontario": [
    {
      "label": "Choose",
      "value": ""
    },
    {
      "label": "Bloomfield (157)",
      "value": "Bloomfield (157)"
    },
    {
      "label": "Canandaigua (082)",
      "value": "Canandaigua (082)"
    },
    {
      "label": "Geneva (219)",
      "value": "Geneva (219)"
    },
    {
      "label": "Honeoye (282)",
      "value": "Honeoye (282)"
    },
    {
      "label": "Honeoye Falls-Lima (283)",
      "value": "Honeoye Falls-Lima (283)"
    },
    {
      "label": "Livonia (350)",
      "value": "Livonia (350)"
    },
    {
      "label": "Lyons (360)",
      "value": "Lyons (360)"
    },
    {
      "label": "Manchester-Shortsville (Red Jacket) (527)",
      "value": "Manchester-Shortsville (Red Jacket) (527)"
    },
    {
      "label": "Marcus Whitman (374)",
      "value": "Marcus Whitman (374)"
    },
    {
      "label": "Naples (420)",
      "value": "Naples (420)"
    },
    {
      "label": "Newark (431)",
      "value": "Newark (431)"
    },
    {
      "label": "Palmyra-Macedon (478)",
      "value": "Palmyra-Macedon (478)"
    },
    {
      "label": "Penn Yan (489)",
      "value": "Penn Yan (489)"
    },
    {
      "label": "Phelps-Clifton Springs (Midlakes) (493)",
      "value": "Phelps-Clifton Springs (Midlakes) (493)"
    },
    {
      "label": "Pittsford (500)",
      "value": "Pittsford (500)"
    },
    {
      "label": "Victor (659)",
      "value": "Victor (659)"
    },
    {
      "label": "Wayland-Cohocton (677)",
      "value": "Wayland-Cohocton (677)"
    }
  ],
  "Orange": [
    {
      "label": "Choose",
      "value": ""
    },
    {
      "label": "Chester (110)",
      "value": "Chester (110)"
    },
    {
      "label": "Cornwall (133)",
      "value": "Cornwall (133)"
    },
    {
      "label": "Eldred (178)",
      "value": "Eldred (178)"
    },
    {
      "label": "Florida (196)",
      "value": "Florida (196)"
    },
    {
      "label": "Goshen (228)",
      "value": "Goshen (228)"
    },
    {
      "label": "Greenwood Lake (243)",
      "value": "Greenwood Lake (243)"
    },
    {
      "label": "Highland Falls-Fort Montgomery (275)",
      "value": "Highland Falls-Fort Montgomery (275)"
    },
    {
      "label": "Kiryas Joel Village (725)",
      "value": "Kiryas Joel Village (725)"
    },
    {
      "label": "Marlboro (377)",
      "value": "Marlboro (377)"
    },
    {
      "label": "Middletown (394)",
      "value": "Middletown (394)"
    },
    {
      "label": "Minisink Valley (400)",
      "value": "Minisink Valley (400)"
    },
    {
      "label": "Monroe-Woodbury (403)",
      "value": "Monroe-Woodbury (403)"
    },
    {
      "label": "Newburgh (433)",
      "value": "Newburgh (433)"
    },
    {
      "label": "North Rockland (445)",
      "value": "North Rockland (445)"
    },
    {
      "label": "Pine Bush (495)",
      "value": "Pine Bush (495)"
    },
    {
      "label": "Port Jervis (510)",
      "value": "Port Jervis (510)"
    },
    {
      "label": "Ramapo (626)",
      "value": "Ramapo (626)"
    },
    {
      "label": "Tuxedo (648)",
      "value": "Tuxedo (648)"
    },
    {
      "label": "Valley (405)",
      "value": "Valley (405)"
    },
    {
      "label": "Wallkill (662)",
      "value": "Wallkill (662)"
    },
    {
      "label": "Warwick Valley (668)",
      "value": "Warwick Valley (668)"
    },
    {
      "label": "Washingtonville (669)",
      "value": "Washingtonville (669)"
    }
  ],
  "Orleans": [
    {
      "label": "Choose",
      "value": ""
    },
    {
      "label": "Albion (006)",
      "value": "Albion (006)"
    },
    {
      "label": "Barker (035)",
      "value": "Barker (035)"
    },
    {
      "label": "Brockport (066)",
      "value": "Brockport (066)"
    },
    {
      "label": "Byron-Bergen (075)",
      "value": "Byron-Bergen (075)"
    },
    {
      "label": "Holley (280)",
      "value": "Holley (280)"
    },
    {
      "label": "Kendall (318)",
      "value": "Kendall (318)"
    },
    {
      "label": "Lyndonville (359)",
      "value": "Lyndonville (359)"
    },
    {
      "label": "Medina (387)",
      "value": "Medina (387)"
    },
    {
      "label": "Oakfield-Alabama (458)",
      "value": "Oakfield-Alabama (458)"
    },
    {
      "label": "Royalton-Hartland (548)",
      "value": "Royalton-Hartland (548)"
    }
  ],
  "Oswego": [
    {
      "label": "Choose",
      "value": ""
    },
    {
      "label": "Altmar-Parish-Williamstown (012)",
      "value": "Altmar-Parish-Williamstown (012)"
    },
    {
      "label": "Camden (079)",
      "value": "Camden (079)"
    },
    {
      "label": "Cato-Meridian (092)",
      "value": "Cato-Meridian (092)"
    },
    {
      "label": "Central Square (098)",
      "value": "Central Square (098)"
    },
    {
      "label": "Fulton (211)",
      "value": "Fulton (211)"
    },
    {
      "label": "Hannibal (257)",
      "value": "Hannibal (257)"
    },
    {
      "label": "Mexico Academy and Central (390)",
      "value": "Mexico Academy and Central (390)"
    },
    {
      "label": "Oswego (472)",
      "value": "Oswego (472)"
    },
    {
      "label": "Phoenix (494)",
      "value": "Phoenix (494)"
    },
    {
      "label": "Pulaski Academy and Central (516)",
      "value": "Pulaski Academy and Central (516)"
    },
    {
      "label": "Sandy Creek (559)",
      "value": "Sandy Creek (559)"
    },
    {
      "label": "South Jefferson (600)",
      "value": "South Jefferson (600)"
    }
  ],
  "Otsego": [
    {
      "label": "Choose",
      "value": ""
    },
    {
      "label": "Bainbridge-Guilford (031)",
      "value": "Bainbridge-Guilford (031)"
    },
    {
      "label": "Charlotte Valley (101)",
      "value": "Charlotte Valley (101)"
    },
    {
      "label": "Cherry Valley-Springfield (616)",
      "value": "Cherry Valley-Springfield (616)"
    },
    {
      "label": "Cobleskill-Richmondville (120)",
      "value": "Cobleskill-Richmondville (120)"
    },
    {
      "label": "Cooperstown (128)",
      "value": "Cooperstown (128)"
    },
    {
      "label": "Edmeston (174)",
      "value": "Edmeston (174)"
    },
    {
      "label": "Franklin (203)",
      "value": "Franklin (203)"
    },
    {
      "label": "Gilbertsville-Mount Upton (222)",
      "value": "Gilbertsville-Mount Upton (222)"
    },
    {
      "label": "Laurens (336)",
      "value": "Laurens (336)"
    },
    {
      "label": "Milford (395)",
      "value": "Milford (395)"
    },
    {
      "label": "Morris (409)",
      "value": "Morris (409)"
    },
    {
      "label": "Mount Markham (412)",
      "value": "Mount Markham (412)"
    },
    {
      "label": "Oneonta (464)",
      "value": "Oneonta (464)"
    },
    {
      "label": "Owen D. Young (Van Hornesville) (474)",
      "value": "Owen D. Young (Van Hornesville) (474)"
    },
    {
      "label": "Richfield Springs (533)",
      "value": "Richfield Springs (533)"
    },
    {
      "label": "Schenevus (570)",
      "value": "Schenevus (570)"
    },
    {
      "label": "Sharon Springs (579)",
      "value": "Sharon Springs (579)"
    },
    {
      "label": "Sidney (586)",
      "value": "Sidney (586)"
    },
    {
      "label": "Unadilla Valley (422)",
      "value": "Unadilla Valley (422)"
    },
    {
      "label": "Unatego (649)",
      "value": "Unatego (649)"
    },
    {
      "label": "Worcester (711)",
      "value": "Worcester (711)"
    }
  ],
  "Putnam": [
    {
      "label": "Choose",
      "value": ""
    },
    {
      "label": "Brewster (060)",
      "value": "Brewster (060)"
    },
    {
      "label": "Carmel (089)",
      "value": "Carmel (089)"
    },
    {
      "label": "Garrison (215)",
      "value": "Garrison (215)"
    },
    {
      "label": "Haldane (249)",
      "value": "Haldane (249)"
    },
    {
      "label": "Lakeland (331)",
      "value": "Lakeland (331)"
    },
    {
      "label": "Mahopac (363)",
      "value": "Mahopac (363)"
    },
    {
      "label": "North Salem (447)",
      "value": "North Salem (447)"
    },
    {
      "label": "Pawling (483)",
      "value": "Pawling (483)"
    },
    {
      "label": "Putnam Valley (518)",
      "value": "Putnam Valley (518)"
    },
    {
      "label": "Wappingers (665)",
      "value": "Wappingers (665)"
    }
  ],
  "Queens": [
    {
      "label": "Choose",
      "value": ""
    },
    {
      "label": "Queens (519)",
      "value": "Queens (519)"
    }
  ],
  "Rensselaer": [
    {
      "label": "Choose",
      "value": ""
    },
    {
      "label": "Averill Park (027)",
      "value": "Averill Park (027)"
    },
    {
      "label": "Berlin (049)",
      "value": "Berlin (049)"
    },
    {
      "label": "Brunswick (Brittonkill) (064)",
      "value": "Brunswick (Brittonkill) (064)"
    },
    {
      "label": "Cambridge (078)",
      "value": "Cambridge (078)"
    },
    {
      "label": "East Greenbush (158)",
      "value": "East Greenbush (158)"
    },
    {
      "label": "Hoosic Falls (285)",
      "value": "Hoosic Falls (285)"
    },
    {
      "label": "Hoosick Valley (284)",
      "value": "Hoosick Valley (284)"
    },
    {
      "label": "Ichabod Crane (294)",
      "value": "Ichabod Crane (294)"
    },
    {
      "label": "Lansingburgh (334)",
      "value": "Lansingburgh (334)"
    },
    {
      "label": "Mechanicville (386)",
      "value": "Mechanicville (386)"
    },
    {
      "label": "New Lebanon (426)",
      "value": "New Lebanon (426)"
    },
    {
      "label": "North Greenbush (Williams) (704)",
      "value": "North Greenbush (Williams) (704)"
    },
    {
      "label": "Rensselaer (530)",
      "value": "Rensselaer (530)"
    },
    {
      "label": "Schodack (571)",
      "value": "Schodack (571)"
    },
    {
      "label": "Stillwater (623)",
      "value": "Stillwater (623)"
    },
    {
      "label": "Troy (642)",
      "value": "Troy (642)"
    },
    {
      "label": "Wynantskill (713)",
      "value": "Wynantskill (713)"
    }
  ],
  "Richmond": [
    {
      "label": "Choose",
      "value": ""
    },
    {
      "label": "Richmond (622)",
      "value": "Richmond (622)"
    }
  ],
  "Rockland": [
    {
      "label": "Choose",
      "value": ""
    },
    {
      "label": "Clarkstown (423)",
      "value": "Clarkstown (423)"
    },
    {
      "label": "East Ramapo (615)",
      "value": "East Ramapo (615)"
    },
    {
      "label": "Nanuet (419)",
      "value": "Nanuet (419)"
    },
    {
      "label": "North Rockland (445)",
      "value": "North Rockland (445)"
    },
    {
      "label": "Nyack (457)",
      "value": "Nyack (457)"
    },
    {
      "label": "Pearl River (484)",
      "value": "Pearl River (484)"
    },
    {
      "label": "Ramapo (626)",
      "value": "Ramapo (626)"
    },
    {
      "label": "South Orangetown (605)",
      "value": "South Orangetown (605)"
    }
  ],
  "Saratoga": [
    {
      "label": "Choose",
      "value": ""
    },
    {
      "label": "Amsterdam (015)",
      "value": "Amsterdam (015)"
    },
    {
      "label": "Ballston Spa (034)",
      "value": "Ballston Spa (034)"
    },
    {
      "label": "Broadalbin-Perth (065)",
      "value": "Broadalbin-Perth (065)"
    },
    {
      "label": "Burnt Hills-Ballston Lake (074)",
      "value": "Burnt Hills-Ballston Lake (074)"
    },
    {
      "label": "Corinth (131)",
      "value": "Corinth (131)"
    },
    {
      "label": "Edinburg (173)",
      "value": "Edinburg (173)"
    },
    {
      "label": "Galway (212)",
      "value": "Galway (212)"
    },
    {
      "label": "Hadley Luzerne (247)",
      "value": "Hadley Luzerne (247)"
    },
    {
      "label": "Hudson Falls (290)",
      "value": "Hudson Falls (290)"
    },
    {
      "label": "Mechanicville (386)",
      "value": "Mechanicville (386)"
    },
    {
      "label": "Niskayuna (439)",
      "value": "Niskayuna (439)"
    },
    {
      "label": "Northville (454)",
      "value": "Northville (454)"
    },
    {
      "label": "Saratoga Springs (562)",
      "value": "Saratoga Springs (562)"
    },
    {
      "label": "Schuylerville (574)",
      "value": "Schuylerville (574)"
    },
    {
      "label": "Scotia Glenville (576)",
      "value": "Scotia Glenville (576)"
    },
    {
      "label": "Shenendehowa (581)",
      "value": "Shenendehowa (581)"
    },
    {
      "label": "South Glens Falls (597)",
      "value": "South Glens Falls (597)"
    },
    {
      "label": "Stillwater (623)",
      "value": "Stillwater (623)"
    },
    {
      "label": "Waterford-Halfmoon (670)",
      "value": "Waterford-Halfmoon (670)"
    }
  ],
  "Schenectady": [
    {
      "label": "Choose",
      "value": ""
    },
    {
      "label": "Amsterdam (015)",
      "value": "Amsterdam (015)"
    },
    {
      "label": "Burnt Hills-Ballston Lake (074)",
      "value": "Burnt Hills-Ballston Lake (074)"
    },
    {
      "label": "Duanesburg (153)",
      "value": "Duanesburg (153)"
    },
    {
      "label": "Galway (212)",
      "value": "Galway (212)"
    },
    {
      "label": "Mohonasen (402)",
      "value": "Mohonasen (402)"
    },
    {
      "label": "Niskayuna (439)",
      "value": "Niskayuna (439)"
    },
    {
      "label": "Schalmont (568)",
      "value": "Schalmont (568)"
    },
    {
      "label": "Schenectady (569)",
      "value": "Schenectady (569)"
    },
    {
      "label": "Schoharie (572)",
      "value": "Schoharie (572)"
    },
    {
      "label": "Scotia-Glenville (576)",
      "value": "Scotia-Glenville (576)"
    },
    {
      "label": "South Colonie (595)",
      "value": "South Colonie (595)"
    }
  ],
  "Schoharie": [
    {
      "label": "Choose",
      "value": ""
    },
    {
      "label": "Berne-Knox-Westerlo (050)",
      "value": "Berne-Knox-Westerlo (050)"
    },
    {
      "label": "Cairo-Durham (076)",
      "value": "Cairo-Durham (076)"
    },
    {
      "label": "Canajoharie (081)",
      "value": "Canajoharie (081)"
    },
    {
      "label": "Charlotte Valley (101)",
      "value": "Charlotte Valley (101)"
    },
    {
      "label": "Cobleskill-Richmondville (120)",
      "value": "Cobleskill-Richmondville (120)"
    },
    {
      "label": "Duanesburg (153)",
      "value": "Duanesburg (153)"
    },
    {
      "label": "Fonda-Fultonville (197)",
      "value": "Fonda-Fultonville (197)"
    },
    {
      "label": "Gilboa-Conesville (223)",
      "value": "Gilboa-Conesville (223)"
    },
    {
      "label": "Greenville (240)",
      "value": "Greenville (240)"
    },
    {
      "label": "Jefferson (310)",
      "value": "Jefferson (310)"
    },
    {
      "label": "Middleburgh (393)",
      "value": "Middleburgh (393)"
    },
    {
      "label": "Schoharie (572)",
      "value": "Schoharie (572)"
    },
    {
      "label": "Sharon Springs (579)",
      "value": "Sharon Springs (579)"
    },
    {
      "label": "Stamford (620)",
      "value": "Stamford (620)"
    }
  ],
  "Schuyler": [
    {
      "label": "Choose",
      "value": ""
    },
    {
      "label": "Bradford (057)",
      "value": "Bradford (057)"
    },
    {
      "label": "Corning-Painted Post (132)",
      "value": "Corning-Painted Post (132)"
    },
    {
      "label": "Dundee (154)",
      "value": "Dundee (154)"
    },
    {
      "label": "Hammondsport (254)",
      "value": "Hammondsport (254)"
    },
    {
      "label": "Horseheads (287)",
      "value": "Horseheads (287)"
    },
    {
      "label": "Odessa Montour (460)",
      "value": "Odessa Montour (460)"
    },
    {
      "label": "South Seneca (607)",
      "value": "South Seneca (607)"
    },
    {
      "label": "Spencer-Van Etten (613)",
      "value": "Spencer-Van Etten (613)"
    },
    {
      "label": "Trumansburg (643)",
      "value": "Trumansburg (643)"
    },
    {
      "label": "Watkins Glen (675)",
      "value": "Watkins Glen (675)"
    }
  ],
  "Seneca": [
    {
      "label": "Choose",
      "value": ""
    },
    {
      "label": "Clyde-Savannah (118)",
      "value": "Clyde-Savannah (118)"
    },
    {
      "label": "Geneva (219)",
      "value": "Geneva (219)"
    },
    {
      "label": "Lyons (360)",
      "value": "Lyons (360)"
    },
    {
      "label": "Phelps-Clifton Springs (Midlakes) (493)",
      "value": "Phelps-Clifton Springs (Midlakes) (493)"
    },
    {
      "label": "Romulus (542)",
      "value": "Romulus (542)"
    },
    {
      "label": "Seneca Falls (578)",
      "value": "Seneca Falls (578)"
    },
    {
      "label": "South Seneca (607)",
      "value": "South Seneca (607)"
    },
    {
      "label": "Trumansburg (643)",
      "value": "Trumansburg (643)"
    },
    {
      "label": "Waterloo (671)",
      "value": "Waterloo (671)"
    }
  ],
  "Steuben": [
    {
      "label": "Choose",
      "value": ""
    },
    {
      "label": "Addison (001)",
      "value": "Addison (001)"
    },
    {
      "label": "Alfred-Almond (010)",
      "value": "Alfred-Almond (010)"
    },
    {
      "label": "Andover (017)",
      "value": "Andover (017)"
    },
    {
      "label": "Arkport (021)",
      "value": "Arkport (021)"
    },
    {
      "label": "Avoca (028)",
      "value": "Avoca (028)"
    },
    {
      "label": "Bath (037)",
      "value": "Bath (037)"
    },
    {
      "label": "Bradford (057)",
      "value": "Bradford (057)"
    },
    {
      "label": "Campbell-Savona (080)",
      "value": "Campbell-Savona (080)"
    },
    {
      "label": "Canaseraga (083)",
      "value": "Canaseraga (083)"
    },
    {
      "label": "Canisteo-Greenwood (086)",
      "value": "Canisteo-Greenwood (086)"
    },
    {
      "label": "Corning-Painted Post (132)",
      "value": "Corning-Painted Post (132)"
    },
    {
      "label": "Dansville (140)",
      "value": "Dansville (140)"
    },
    {
      "label": "Elmira (182)",
      "value": "Elmira (182)"
    },
    {
      "label": "Hammondsport (254)",
      "value": "Hammondsport (254)"
    },
    {
      "label": "Hornell (286)",
      "value": "Hornell (286)"
    },
    {
      "label": "Jasper-Troupsburg (308)",
      "value": "Jasper-Troupsburg (308)"
    },
    {
      "label": "Naples (420)",
      "value": "Naples (420)"
    },
    {
      "label": "Penn Yan (489)",
      "value": "Penn Yan (489)"
    },
    {
      "label": "Prattsburg (515)",
      "value": "Prattsburg (515)"
    },
    {
      "label": "Wayland-Cohocton (677)",
      "value": "Wayland-Cohocton (677)"
    },
    {
      "label": "Whitesville (702)",
      "value": "Whitesville (702)"
    }
  ],
  "St. Lawrence": [
    {
      "label": "Choose",
      "value": ""
    },
    {
      "label": "Alexandria (009)",
      "value": "Alexandria (009)"
    },
    {
      "label": "Brasher Falls (058)",
      "value": "Brasher Falls (058)"
    },
    {
      "label": "Brushton-Moira (072)",
      "value": "Brushton-Moira (072)"
    },
    {
      "label": "Canton (087)",
      "value": "Canton (087)"
    },
    {
      "label": "Clifton-Fine (116)",
      "value": "Clifton-Fine (116)"
    },
    {
      "label": "Colton-Pierrepont (124)",
      "value": "Colton-Pierrepont (124)"
    },
    {
      "label": "Edwards-Knox (724)",
      "value": "Edwards-Knox (724)"
    },
    {
      "label": "Gouverneur (229)",
      "value": "Gouverneur (229)"
    },
    {
      "label": "Hammond (253)",
      "value": "Hammond (253)"
    },
    {
      "label": "Harrisville (261)",
      "value": "Harrisville (261)"
    },
    {
      "label": "Hermon-Dekalb (269)",
      "value": "Hermon-Dekalb (269)"
    },
    {
      "label": "Heuvelton (271)",
      "value": "Heuvelton (271)"
    },
    {
      "label": "Indian River (297)",
      "value": "Indian River (297)"
    },
    {
      "label": "Lisbon (345)",
      "value": "Lisbon (345)"
    },
    {
      "label": "Madrid-Waddington (362)",
      "value": "Madrid-Waddington (362)"
    },
    {
      "label": "Massena (380)",
      "value": "Massena (380)"
    },
    {
      "label": "Morristown (410)",
      "value": "Morristown (410)"
    },
    {
      "label": "Norwood-Norfolk (456)",
      "value": "Norwood-Norfolk (456)"
    },
    {
      "label": "Ogdensburg (461)",
      "value": "Ogdensburg (461)"
    },
    {
      "label": "Parishville-Hopkinton (480)",
      "value": "Parishville-Hopkinton (480)"
    },
    {
      "label": "Potsdam (513)",
      "value": "Potsdam (513)"
    },
    {
      "label": "Salmon River (558)",
      "value": "Salmon River (558)"
    },
    {
      "label": "St. Regis Falls (619)",
      "value": "St. Regis Falls (619)"
    },
    {
      "label": "Tupper Lake (647)",
      "value": "Tupper Lake (647)"
    }
  ],
  "Suffolk": [
    {
      "label": "Choose",
      "value": ""
    },
    {
      "label": "Amagansett (013)",
      "value": "Amagansett (013)"
    },
    {
      "label": "Amityville (014)",
      "value": "Amityville (014)"
    },
    {
      "label": "Babylon (030)",
      "value": "Babylon (030)"
    },
    {
      "label": "Bayport-Blue Point (039)",
      "value": "Bayport-Blue Point (039)"
    },
    {
      "label": "Bay Shore (038)",
      "value": "Bay Shore (038)"
    },
    {
      "label": "Brentwood (059)",
      "value": "Brentwood (059)"
    },
    {
      "label": "Bridgehampton (062)",
      "value": "Bridgehampton (062)"
    },
    {
      "label": "Center Moriches (096)",
      "value": "Center Moriches (096)"
    },
    {
      "label": "Central Islip (097)",
      "value": "Central Islip (097)"
    },
    {
      "label": "Cold Spring Harbor (123)",
      "value": "Cold Spring Harbor (123)"
    },
    {
      "label": "Commack (125)",
      "value": "Commack (125)"
    },
    {
      "label": "Comsewogue (126)",
      "value": "Comsewogue (126)"
    },
    {
      "label": "Connetquot (127)",
      "value": "Connetquot (127)"
    },
    {
      "label": "Copiague (130)",
      "value": "Copiague (130)"
    },
    {
      "label": "Deer Park (142)",
      "value": "Deer Park (142)"
    },
    {
      "label": "East Hampton (159)",
      "value": "East Hampton (159)"
    },
    {
      "label": "East Islip (161)",
      "value": "East Islip (161)"
    },
    {
      "label": "East Moriches (163)",
      "value": "East Moriches (163)"
    },
    {
      "label": "Eastport/South Manor (170)",
      "value": "Eastport/South Manor (170)"
    },
    {
      "label": "East Quogue (164)",
      "value": "East Quogue (164)"
    },
    {
      "label": "Elwood (186)",
      "value": "Elwood (186)"
    },
    {
      "label": "Farmingdale (191)",
      "value": "Farmingdale (191)"
    },
    {
      "label": "Fire Island (193)",
      "value": "Fire Island (193)"
    },
    {
      "label": "Fishers Island (194)",
      "value": "Fishers Island (194)"
    },
    {
      "label": "Greenport (239)",
      "value": "Greenport (239)"
    },
    {
      "label": "Half Hollow Hills (250)",
      "value": "Half Hollow Hills (250)"
    },
    {
      "label": "Hampton Bays (255)",
      "value": "Hampton Bays (255)"
    },
    {
      "label": "Harborfields (258)",
      "value": "Harborfields (258)"
    },
    {
      "label": "Hauppauge (264)",
      "value": "Hauppauge (264)"
    },
    {
      "label": "Huntington (292)",
      "value": "Huntington (292)"
    },
    {
      "label": "Islip (304)",
      "value": "Islip (304)"
    },
    {
      "label": "Kings Park (321)",
      "value": "Kings Park (321)"
    },
    {
      "label": "Lindenhurst (344)",
      "value": "Lindenhurst (344)"
    },
    {
      "label": "Longwood (392)",
      "value": "Longwood (392)"
    },
    {
      "label": "Mattituck-Cutchogue (382)",
      "value": "Mattituck-Cutchogue (382)"
    },
    {
      "label": "Middle Country (391)",
      "value": "Middle Country (391)"
    },
    {
      "label": "Miller Place (397)",
      "value": "Miller Place (397)"
    },
    {
      "label": "Montauk (404)",
      "value": "Montauk (404)"
    },
    {
      "label": "Mount Sinai (414)",
      "value": "Mount Sinai (414)"
    },
    {
      "label": "New Suffolk (429)",
      "value": "New Suffolk (429)"
    },
    {
      "label": "North Babylon (440)",
      "value": "North Babylon (440)"
    },
    {
      "label": "Northport-East Northport (452)",
      "value": "Northport-East Northport (452)"
    },
    {
      "label": "Oysterponds (477)",
      "value": "Oysterponds (477)"
    },
    {
      "label": "Patchogue-Medford (481)",
      "value": "Patchogue-Medford (481)"
    },
    {
      "label": "Port Jefferson (509)",
      "value": "Port Jefferson (509)"
    },
    {
      "label": "Quogue (521)",
      "value": "Quogue (521)"
    },
    {
      "label": "Remsenburg-Speonk (529)",
      "value": "Remsenburg-Speonk (529)"
    },
    {
      "label": "Riverhead (537)",
      "value": "Riverhead (537)"
    },
    {
      "label": "Rocky Point (540)",
      "value": "Rocky Point (540)"
    },
    {
      "label": "Sachem (553)",
      "value": "Sachem (553)"
    },
    {
      "label": "Sagaponack (555)",
      "value": "Sagaponack (555)"
    },
    {
      "label": "Sag Harbor (554)",
      "value": "Sag Harbor (554)"
    },
    {
      "label": "Sayville (566)",
      "value": "Sayville (566)"
    },
    {
      "label": "Shelter Island (580)",
      "value": "Shelter Island (580)"
    },
    {
      "label": "Shoreham-Wading River (585)",
      "value": "Shoreham-Wading River (585)"
    },
    {
      "label": "Smithtown (590)",
      "value": "Smithtown (590)"
    },
    {
      "label": "Southampton (608)",
      "value": "Southampton (608)"
    },
    {
      "label": "South Country (596)",
      "value": "South Country (596)"
    },
    {
      "label": "South Huntington (599)",
      "value": "South Huntington (599)"
    },
    {
      "label": "Southold (610)",
      "value": "Southold (610)"
    },
    {
      "label": "Springs (617)",
      "value": "Springs (617)"
    },
    {
      "label": "Three Village (635)",
      "value": "Three Village (635)"
    },
    {
      "label": "Tuckahoe Common (645)",
      "value": "Tuckahoe Common (645)"
    },
    {
      "label": "Wainscott (661)",
      "value": "Wainscott (661)"
    },
    {
      "label": "West Babylon (684)",
      "value": "West Babylon (684)"
    },
    {
      "label": "Westhampton Beach (693)",
      "value": "Westhampton Beach (693)"
    },
    {
      "label": "West Islip (688)",
      "value": "West Islip (688)"
    },
    {
      "label": "William Floyd (381)",
      "value": "William Floyd (381)"
    },
    {
      "label": "Wyandanch (712)",
      "value": "Wyandanch (712)"
    }
  ],
  "Sullivan": [
    {
      "label": "Choose",
      "value": ""
    },
    {
      "label": "Eldred (178)",
      "value": "Eldred (178)"
    },
    {
      "label": "Ellenville (180)",
      "value": "Ellenville (180)"
    },
    {
      "label": "Fallsburgh (190)",
      "value": "Fallsburgh (190)"
    },
    {
      "label": "Liberty (342)",
      "value": "Liberty (342)"
    },
    {
      "label": "Livingston Manor (349)",
      "value": "Livingston Manor (349)"
    },
    {
      "label": "Minisink Valley (400)",
      "value": "Minisink Valley (400)"
    },
    {
      "label": "Monticello (406)",
      "value": "Monticello (406)"
    },
    {
      "label": "Pine Bush (495)",
      "value": "Pine Bush (495)"
    },
    {
      "label": "Port Jervis (510)",
      "value": "Port Jervis (510)"
    },
    {
      "label": "Sullivan West (143)",
      "value": "Sullivan West (143)"
    },
    {
      "label": "Roscoe (545)",
      "value": "Roscoe (545)"
    },
    {
      "label": "Tri-Valley (640)",
      "value": "Tri-Valley (640)"
    }
  ],
  "Tioga": [
    {
      "label": "Choose",
      "value": ""
    },
    {
      "label": "Candor (085)",
      "value": "Candor (085)"
    },
    {
      "label": "Dryden (152)",
      "value": "Dryden (152)"
    },
    {
      "label": "Ithaca (305)",
      "value": "Ithaca (305)"
    },
    {
      "label": "Maine-Endwell (364)",
      "value": "Maine-Endwell (364)"
    },
    {
      "label": "Marathon (372)",
      "value": "Marathon (372)"
    },
    {
      "label": "Newark Valley (432)",
      "value": "Newark Valley (432)"
    },
    {
      "label": "Owego Apalachin (473)",
      "value": "Owego Apalachin (473)"
    },
    {
      "label": "Spencer-Van Etten (613)",
      "value": "Spencer-Van Etten (613)"
    },
    {
      "label": "Tioga (637)",
      "value": "Tioga (637)"
    },
    {
      "label": "Union-Endicott (651)",
      "value": "Union-Endicott (651)"
    },
    {
      "label": "Vestal (658)",
      "value": "Vestal (658)"
    },
    {
      "label": "Waverly (676)",
      "value": "Waverly (676)"
    },
    {
      "label": "Whitney Point (703)",
      "value": "Whitney Point (703)"
    }
  ],
  "Tompkins": [
    {
      "label": "Choose",
      "value": ""
    },
    {
      "label": "Candor (085)",
      "value": "Candor (085)"
    },
    {
      "label": "Cortland (134)",
      "value": "Cortland (134)"
    },
    {
      "label": "Dryden (152)",
      "value": "Dryden (152)"
    },
    {
      "label": "Groton (245)",
      "value": "Groton (245)"
    },
    {
      "label": "Homer (281)",
      "value": "Homer (281)"
    },
    {
      "label": "Ithaca (305)",
      "value": "Ithaca (305)"
    },
    {
      "label": "Lansing (333)",
      "value": "Lansing (333)"
    },
    {
      "label": "Moravia (407)",
      "value": "Moravia (407)"
    },
    {
      "label": "Newark Valley (432)",
      "value": "Newark Valley (432)"
    },
    {
      "label": "Newfield (436)",
      "value": "Newfield (436)"
    },
    {
      "label": "Odessa-Montour (460)",
      "value": "Odessa-Montour (460)"
    },
    {
      "label": "Southern Cayuga (609)",
      "value": "Southern Cayuga (609)"
    },
    {
      "label": "Spencer-Van Etten (613)",
      "value": "Spencer-Van Etten (613)"
    },
    {
      "label": "Trumansburg (643)",
      "value": "Trumansburg (643)"
    }
  ],
  "Ulster": [
    {
      "label": "Choose",
      "value": ""
    },
    {
      "label": "Ellenville (180)",
      "value": "Ellenville (180)"
    },
    {
      "label": "Fallsburgh (190)",
      "value": "Fallsburgh (190)"
    },
    {
      "label": "Highland (274)",
      "value": "Highland (274)"
    },
    {
      "label": "Kingston (322)",
      "value": "Kingston (322)"
    },
    {
      "label": "Livingston Manor (349)",
      "value": "Livingston Manor (349)"
    },
    {
      "label": "Margaretville (375)",
      "value": "Margaretville (375)"
    },
    {
      "label": "Marlboro (377)",
      "value": "Marlboro (377)"
    },
    {
      "label": "New Paltz (427)",
      "value": "New Paltz (427)"
    },
    {
      "label": "Onteora (466)",
      "value": "Onteora (466)"
    },
    {
      "label": "Pine Bush (495)",
      "value": "Pine Bush (495)"
    },
    {
      "label": "Rondout Valley (543)",
      "value": "Rondout Valley (543)"
    },
    {
      "label": "Saugerties (563)",
      "value": "Saugerties (563)"
    },
    {
      "label": "Tri-Valley (640)",
      "value": "Tri-Valley (640)"
    },
    {
      "label": "Valley (405)",
      "value": "Valley (405)"
    },
    {
      "label": "Wallkill (662)",
      "value": "Wallkill (662)"
    }
  ],
  "Warren": [
    {
      "label": "Choose",
      "value": ""
    },
    {
      "label": "Abraham Wing (226)",
      "value": "Abraham Wing (226)"
    },
    {
      "label": "Bolton (055)",
      "value": "Bolton (055)"
    },
    {
      "label": "Corinth (131)",
      "value": "Corinth (131)"
    },
    {
      "label": "Glens Falls (225)",
      "value": "Glens Falls (225)"
    },
    {
      "label": "Hadley-Luzerne (247)",
      "value": "Hadley-Luzerne (247)"
    },
    {
      "label": "Hudson Falls (290)",
      "value": "Hudson Falls (290)"
    },
    {
      "label": "Johnsburg (312)",
      "value": "Johnsburg (312)"
    },
    {
      "label": "Lake George (327)",
      "value": "Lake George (327)"
    },
    {
      "label": "Minerva (399)",
      "value": "Minerva (399)"
    },
    {
      "label": "North Warren (451)",
      "value": "North Warren (451)"
    },
    {
      "label": "Queensbury (520)",
      "value": "Queensbury (520)"
    },
    {
      "label": "Schroon Lake (573)",
      "value": "Schroon Lake (573)"
    },
    {
      "label": "Ticonderoga (636)",
      "value": "Ticonderoga (636)"
    },
    {
      "label": "Warrensburg (666)",
      "value": "Warrensburg (666)"
    }
  ],
  "Washington": [
    {
      "label": "Choose",
      "value": ""
    },
    {
      "label": "Argyle (020)",
      "value": "Argyle (020)"
    },
    {
      "label": "Cambridge (078)",
      "value": "Cambridge (078)"
    },
    {
      "label": "Fort Ann (199)",
      "value": "Fort Ann (199)"
    },
    {
      "label": "Fort Edward (200)",
      "value": "Fort Edward (200)"
    },
    {
      "label": "Granville (233)",
      "value": "Granville (233)"
    },
    {
      "label": "Greenwich (241)",
      "value": "Greenwich (241)"
    },
    {
      "label": "Hartford (262)",
      "value": "Hartford (262)"
    },
    {
      "label": "Hoosick Falls (285)",
      "value": "Hoosick Falls (285)"
    },
    {
      "label": "Hoosic Valley (284)",
      "value": "Hoosic Valley (284)"
    },
    {
      "label": "Hudson Falls (290)",
      "value": "Hudson Falls (290)"
    },
    {
      "label": "Lake George (327)",
      "value": "Lake George (327)"
    },
    {
      "label": "Putnam (517)",
      "value": "Putnam (517)"
    },
    {
      "label": "Salem (557)",
      "value": "Salem (557)"
    },
    {
      "label": "Schuylerville (574)",
      "value": "Schuylerville (574)"
    },
    {
      "label": "Stillwater (623)",
      "value": "Stillwater (623)"
    },
    {
      "label": "Whitehall (700)",
      "value": "Whitehall (700)"
    }
  ],
  "Wayne": [
    {
      "label": "Choose",
      "value": ""
    },
    {
      "label": "Cato-Meridian (092)",
      "value": "Cato-Meridian (092)"
    },
    {
      "label": "Clyde-Savannah (118)",
      "value": "Clyde-Savannah (118)"
    },
    {
      "label": "Gananda (213)",
      "value": "Gananda (213)"
    },
    {
      "label": "Lyons (360)",
      "value": "Lyons (360)"
    },
    {
      "label": "Marion (376)",
      "value": "Marion (376)"
    },
    {
      "label": "North Rose-Wolcott (446)",
      "value": "North Rose-Wolcott (446)"
    },
    {
      "label": "Newark (431)",
      "value": "Newark (431)"
    },
    {
      "label": "Palmyra-Macedon (478)",
      "value": "Palmyra-Macedon (478)"
    },
    {
      "label": "Penfield (488)",
      "value": "Penfield (488)"
    },
    {
      "label": "Phelps-Clifton Springs (Midlakes) (493)",
      "value": "Phelps-Clifton Springs (Midlakes) (493)"
    },
    {
      "label": "Port Byron (507)",
      "value": "Port Byron (507)"
    },
    {
      "label": "Red Creek (525)",
      "value": "Red Creek (525)"
    },
    {
      "label": "Sodus (592)",
      "value": "Sodus (592)"
    },
    {
      "label": "Victor (659)",
      "value": "Victor (659)"
    },
    {
      "label": "Wayne (678)",
      "value": "Wayne (678)"
    },
    {
      "label": "Webster (679)",
      "value": "Webster (679)"
    },
    {
      "label": "Williamson (705)",
      "value": "Williamson (705)"
    }
  ],
  "Westchester": [
    {
      "label": "Choose",
      "value": ""
    },
    {
      "label": "Ardsley (019)",
      "value": "Ardsley (019)"
    },
    {
      "label": "Bedford (042)",
      "value": "Bedford (042)"
    },
    {
      "label": "Blind Brook (535)",
      "value": "Blind Brook (535)"
    },
    {
      "label": "Briarcliff Manor (061)",
      "value": "Briarcliff Manor (061)"
    },
    {
      "label": "Bronxville (069)",
      "value": "Bronxville (069)"
    },
    {
      "label": "Byram Hills (023)",
      "value": "Byram Hills (023)"
    },
    {
      "label": "Chappaqua (100)",
      "value": "Chappaqua (100)"
    },
    {
      "label": "Croton-Harmon (136)",
      "value": "Croton-Harmon (136)"
    },
    {
      "label": "Dobbs Ferr (147)",
      "value": "Dobbs Ferr (147)"
    },
    {
      "label": "Eastchester (169)",
      "value": "Eastchester (169)"
    },
    {
      "label": "Edgemont (172)",
      "value": "Edgemont (172)"
    },
    {
      "label": "Elmsford (185)",
      "value": "Elmsford (185)"
    },
    {
      "label": "Greenburgh Central 7 (237)",
      "value": "Greenburgh Central 7 (237)"
    },
    {
      "label": "Harrison (260)",
      "value": "Harrison (260)"
    },
    {
      "label": "Hastings-on-Hudson (263)",
      "value": "Hastings-on-Hudson (263)"
    },
    {
      "label": "Hendrick Hudson (267)",
      "value": "Hendrick Hudson (267)"
    },
    {
      "label": "Irvington (301)",
      "value": "Irvington (301)"
    },
    {
      "label": "Katonah-Lewisboro (316)",
      "value": "Katonah-Lewisboro (316)"
    },
    {
      "label": "Lakeland (331)",
      "value": "Lakeland (331)"
    },
    {
      "label": "Mamaroneck (367)",
      "value": "Mamaroneck (367)"
    },
    {
      "label": "Mount Pleasant (417)",
      "value": "Mount Pleasant (417)"
    },
    {
      "label": "Mount Vernon (416)",
      "value": "Mount Vernon (416)"
    },
    {
      "label": "New Rochelle (428)",
      "value": "New Rochelle (428)"
    },
    {
      "label": "North Salem (447)",
      "value": "North Salem (447)"
    },
    {
      "label": "Ossining (471)",
      "value": "Ossining (471)"
    },
    {
      "label": "Peekskill (485)",
      "value": "Peekskill (485)"
    },
    {
      "label": "Pelham (486)",
      "value": "Pelham (486)"
    },
    {
      "label": "Pleasantville (504)",
      "value": "Pleasantville (504)"
    },
    {
      "label": "Pocantico Hills (505)",
      "value": "Pocantico Hills (505)"
    },
    {
      "label": "Port Chester (508)",
      "value": "Port Chester (508)"
    },
    {
      "label": "Putnam Valley (518)",
      "value": "Putnam Valley (518)"
    },
    {
      "label": "Rye (551)",
      "value": "Rye (551)"
    },
    {
      "label": "Rye Neck (552)",
      "value": "Rye Neck (552)"
    },
    {
      "label": "Scarsdale (567)",
      "value": "Scarsdale (567)"
    },
    {
      "label": "Somers (594)",
      "value": "Somers (594)"
    },
    {
      "label": "Tarrytowns (633)",
      "value": "Tarrytowns (633)"
    },
    {
      "label": "Tuckahoe Union Free (644)",
      "value": "Tuckahoe Union Free (644)"
    },
    {
      "label": "Valhalla (654)",
      "value": "Valhalla (654)"
    },
    {
      "label": "White Plains (699)",
      "value": "White Plains (699)"
    },
    {
      "label": "Yonkers (715)",
      "value": "Yonkers (715)"
    },
    {
      "label": "Yorktown (717)",
      "value": "Yorktown (717)"
    }
  ],
  "Wyoming": [
    {
      "label": "Choose",
      "value": ""
    },
    {
      "label": "Alden (007)",
      "value": "Alden (007)"
    },
    {
      "label": "Alexander (008)",
      "value": "Alexander (008)"
    },
    {
      "label": "Attica (024)",
      "value": "Attica (024)"
    },
    {
      "label": "Dalton-Nunda (Keshequa) (320)",
      "value": "Dalton-Nunda (Keshequa) (320)"
    },
    {
      "label": "Fillmore (192)",
      "value": "Fillmore (192)"
    },
    {
      "label": "Holland (278)",
      "value": "Holland (278)"
    },
    {
      "label": "Iroquois (300)",
      "value": "Iroquois (300)"
    },
    {
      "label": "Letchworth (339)",
      "value": "Letchworth (339)"
    },
    {
      "label": "Pavilion (482)",
      "value": "Pavilion (482)"
    },
    {
      "label": "Perry (490)",
      "value": "Perry (490)"
    },
    {
      "label": "Pioneer (498)",
      "value": "Pioneer (498)"
    },
    {
      "label": "Warsaw (667)",
      "value": "Warsaw (667)"
    },
    {
      "label": "Wyoming (714)",
      "value": "Wyoming (714)"
    },
    {
      "label": "York (716)",
      "value": "York (716)"
    }
  ],
  "Yates": [
    {
      "label": "Choose",
      "value": ""
    },
    {
      "label": "Dundee (154)",
      "value": "Dundee (154)"
    },
    {
      "label": "Geneva (219)",
      "value": "Geneva (219)"
    },
    {
      "label": "Marcus Whitman (374)",
      "value": "Marcus Whitman (374)"
    },
    {
      "label": "Naples (420)",
      "value": "Naples (420)"
    },
    {
      "label": "Penn Yan (489)",
      "value": "Penn Yan (489)"
    },
    {
      "label": "Prattsburg (515)",
      "value": "Prattsburg (515)"
    }
  ]
}