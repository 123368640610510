import React from 'react';
import { View, Text, StyleSheet } from 'react-native';
import LottieAnimation from './LottieAnimation';
import { globalColors } from '../style';
import animationData from '../assets/animations/checkmark.json';

const AnimatedCheckCircle = ({ status }) => {
  return (
    <View style={styles.container}>
      {status === 'complete' ? (
        <View style={styles.animationContainer}>
          <LottieAnimation
            source={animationData}
            loop={false}
            autoplay={true}
            height={24}
            width={24}
          />
        </View>
      ) : (
        <View style={styles.circle}>
        </View>
      )}
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    width: 20,
    height: 20,
    position: 'relative', // Allows absolute positioning within
    overflow: 'visible'  // Ensures content can render outside the container bounds
  },
  animationContainer: {
    position: 'absolute', // Positioning absolutely to center in container
    left: -12,  // Centers the 40px animation in the 20px container
    top: -12,
    width: 40,
    height: 40,
    justifyContent: 'center',
    alignItems: 'center'
  },
  circle: {
    position: 'absolute', // Positioning absolutely to center in container
    width: 16,
    height: 16,
    borderRadius: 8,  // Half of width and height to make perfect circle
    borderWidth: 2,
    borderColor: globalColors.accent,
    justifyContent: 'center',
    alignItems: 'center'
  }
});

export default AnimatedCheckCircle;
