import { View } from 'react-native';
import React, { useState, useEffect } from 'react';
import { useIsFocused } from '@react-navigation/native'
import { Formik } from 'formik';
import ContentContainer from '../../components/ContentContainer';
import ContentHeader from '../../components/ContentHeader';
import TextFieldFormik from '../../components/TextFieldFormik';
import DateFieldFormik from '../../components/DateFieldFormik';
import BooleanField from '../../components/BooleanFieldFormik';
import {globalStyle} from '../../style';
import { replaceEmptyWithNull, handleFilingIdParam, navigateFiling, handleFormIdParam, editFormValues } from '../../utils';

export default function AddSpouseScreen({route, navigation}) {
  let initialValues = {
    relationship_id: '',
    firstName: '', 
    middleInitial: '', 
    lastName: '', 
    dob: '',
    ssn: '',
    disabledBool: '',
    studentBool: ''
  }
  const [ formValues, setFormValues ] = useState(initialValues);
  const [ filingId, setFilingId ] = useState('');
  const [ formId, setFormId ] = useState('');
  const isFocused = useIsFocused();

  // if we are editing an existing dependent then we would have received the dependent if as a parameter 
  // we use this to load the existing dependent details upon render and focus
  useEffect(() => {
    const newFilingId = handleFilingIdParam(navigation, route, setFilingId);
    const cleanFormId = handleFormIdParam(route, setFormValues, setFormId, initialValues, newFilingId, isFocused, [], 'Spouse'); 
  }, [isFocused]);


  const continuePressed = (formValues, setSubmitting, setValues) => {
    let cleanFormValues = replaceEmptyWithNull(structuredClone(formValues), ['dob', 'disabled_bool', 'student_bool']);
    editFormValues(setSubmitting, setValues, filingId, formId, 'Spouse', cleanFormValues, initialValues, navigation, 'contactInfo', []);
  };

  const backPressed = (setValues) => {
    navigateFiling(navigation, 'personalInfo', { filingId: filingId });
  };

  return (
    <Formik 
      initialValues={formValues} 
      enableReinitialize={true}
      onSubmit={(values, { setSubmitting, setValues }) => {continuePressed(values, setSubmitting, setValues)}}>
     {({ handleChange, handleBlur, handleSubmit, values, setValues, isSubmitting }) => (

      <ContentContainer nextPress={handleSubmit} nextDisabled={isSubmitting} backPress={backPressed}>
        <ContentHeader icon={"group"} 
          headerText={"Share details on your spouse"} 
          subHeaderText="Filing together with your spouse can sometimes reduce your tax bill"/>

        <View style={globalStyle.fieldRow}>
          <TextFieldFormik name="firstName" fieldName="First Name" placeholder="First name" width={200} />  
          <TextFieldFormik name="middleInitial" fieldName="Middle Initial" placeholder="M.I." width={100} /> 
          <TextFieldFormik name="lastName" fieldName="Last Name" placeholder="Last name" width={250} /> 
        </View>
        <View style={globalStyle.fieldRow}>
          <DateFieldFormik name="dob" fieldName="Date Of Birth" placeholder="First name" width={200} /> 
          <TextFieldFormik name="ssn" fieldName="Social Security Number" placeholder="###-###-####" width={200} />  
        </View>
        <View style={globalStyle.fieldRow}>
          <BooleanField name="disabledBool" fieldName={"Is this person considered disabled?"} trueLabel={'Yes'} falseLabel={'No'}/>
        </View>
        <View style={globalStyle.fieldRow}>
          <BooleanField name="studentBool" fieldName={"Is this person a full time student?"} trueLabel={'Yes'} falseLabel={'No'}/>
        </View>
      </ContentContainer>
    )}
  </Formik>
  )
}