import React, { useState, useEffect }  from 'react';
import { StyleSheet, View, Text, TouchableOpacity, useWindowDimensions} from 'react-native';
import Collapsible from 'react-native-collapsible';
import { MaterialIcons } from '@expo/vector-icons';
import Logo from './Logo';
import { navigateFiling, logOut } from '../utils';
import StyledText from './StyledText';
import { globalColors } from '../style';
import { DrawerActions } from '@react-navigation/native';
import { largeScreen, drawerWidth } from '../assets/constants';
import AnimatedCheckCircle from './AnimatedCheckCircle';

const sectionDictionary = {
    'Personal': {
        'index': 0, 
        'items': {
            'Personal Info': {'index': 0, 'routes': ['personalInfo', 'addSpouse']},
            'Contact Info': {'index': 1, 'routes': ['contactInfo', 'residency']},
            'Dependents': {'index': 2, 'routes': ['dependents', 'addDependent', 'qualifiedRelative', 'qualifiedChild', 'qualifiedDependentResult']},
            },
    },
    'Federal': {
        'index': 1, 
        'items': {
            'Income': {'index': 0, 'routes': ['incomeSources', 'income', 'w2', 'addW2', 'f1099Int', 'addF1099Int', 'f1099Div', 'addF1099Div', 'f1099B', 'addF1099B', 'addF1099BItem']},
            'Deductions': {'index': 1, 'routes': ['deductionsIntro']},
            'Other Situations': {'index': 2, 'routes': ['otherSituations', 'foreignAccount', 'foreignAccountSummary', 'addForeignAccount', 'hsaAccount', 'hsaContributions', 'hsaDistributions', 'extensionPayment']},
            },
    },
    'State': {
        'index': 3, 
        'items': {
            'NY Intro': {'index': 0, 'routes': ['nyIntro']},
            },
        },
    'Review': {
        'index': 4, 
        'items': {
            'Bank Account': {'index': 0, 'routes': ['bankAccount']},
            'Generate Filing': {'index': 1, 'routes': ['generateFiling']},
            },
        }
};

const sectionStyles = {
    'complete': {circle: 'completeCircle'},
    'active': {circle: 'activeCircle'},
    'incomplete': {circle: 'incompleteCircle'}
};


const activeSection = (state) => {
    const activeRoute = state.routes[0].state && state.routes[0].state.routes.at(-1).name || 'personalInfo';
    let activeSectionName = '';
    let activeItemName = ''
    Object.keys(sectionDictionary).forEach(section => {
        const items = sectionDictionary[section]['items'];
        Object.keys(items).forEach(item => {
            if (items[item]['routes'].includes(activeRoute)) {
                activeSectionName = section;
                activeItemName = item;
                return;
            }
        });
    });
    return activeSectionName ? {
        'activeSection': activeSectionName,
        'activeSectionIndex': sectionDictionary[activeSectionName]['index'],
        'activeItem': activeItemName, 
        'activeItemIndex': sectionDictionary[activeSectionName]['items'][activeItemName]['index']} : 
        {'activeSection': '', 'activeSectionIndex': 100, 'activeItem': '', 'activeItemIndex': 100};
};

const isActiveSection = (state, section) => {
    return activeSection(state)['activeSection'] === section;
}

const CollapsibleSection = ({ state, title, isActive, firstInStepper=false, lastInStepper=false, children }) => {
    const [collapsed, setCollapsed] = useState(true);
    const [sectionStatus, setSectionStatus] = useState('incomplete');

    useEffect(() => {
        setCollapsed(!isActive);
        if (sectionDictionary[title]['index'] < activeSection(state)['activeSectionIndex']) {setSectionStatus('complete');}
        else if (sectionDictionary[title]['index'] === activeSection(state)['activeSectionIndex']) {setSectionStatus('active');}
        else {setSectionStatus('incomplete');}
    }, [state]);

    return (
        <View style={styles.stepperRow}> 
            <TouchableOpacity onPress={() => setCollapsed(!collapsed)} activeOpacity={0.7} style={{width: 20, height: '100%'}}>
                <View style={styles.stepper}>
                    {firstInStepper ? <View style={{ height: 5 }}/> : <View style={styles.topLine} />}
                    <View style={{marginTop: 12, marginLeft: 2}}>
                        <AnimatedCheckCircle status={sectionStatus}/>
                    </View>
                    {lastInStepper ? null : <View style={styles.longLine} />}
                </View>
            </TouchableOpacity>
            
            <View style={isActive ? styles.activeCollapsibleContainer : styles.collapsibleContainer}>
                <TouchableOpacity onPress={() => setCollapsed(!collapsed)} style={isActive ? styles.activeHeader : styles.header} activeOpacity={0.7}>
                    <Text style={isActive ? styles.activeHeaderText : styles.headerText}>{title}</Text>
                    <MaterialIcons name={collapsed ? "keyboard-arrow-down" : "keyboard-arrow-up"} size={24} color={isActive ? globalColors.accent : globalColors.text} />
                </TouchableOpacity>

                <Collapsible collapsed={collapsed} style={styles.collapsible}>
                    {children}
                </Collapsible>
            </View>
        </View>
    );
};

const DrawerItem = ({ state, section, label, onPress }) => {
    const [sectionStatus, setSectionStatus] = useState('incomplete');
    useEffect(() => {
        // every time state changes, check if this item is before or after the active section as that will determine the status circle 
        let activeSectionDetails = activeSection(state);
        if (sectionDictionary[section]['index'] < activeSectionDetails['activeSectionIndex']) {setSectionStatus('complete');}
        else if (sectionDictionary[section]['index'] > activeSectionDetails['activeSectionIndex']) {setSectionStatus('incomplete');}
        else {
            // it looks like the current section is active. check if this route 
            if (sectionDictionary[section]['items'][label]['index'] < activeSectionDetails['activeItemIndex']) {setSectionStatus('complete');}
            else if (sectionDictionary[section]['items'][label]['index'] === activeSectionDetails['activeItemIndex']) {setSectionStatus('active');}
            else {setSectionStatus('incomplete');}
        }
    }, [state]);


    return (
        <TouchableOpacity style={styles.item} onPress={onPress} activeOpacity={0.7}>
            <AnimatedCheckCircle status={sectionStatus} />
            <StyledText style={sectionStatus === 'active' ? styles.activeItemText : styles.itemText}>{label}</StyledText>
        </TouchableOpacity>
    );
};

const DrawerContainer = ({ state, descriptors, navigation }) => {
    const dimensions = useWindowDimensions();
    const isLargeScreen = dimensions.width >= largeScreen;

    const getFilingIdFromCurrentRoute = () => {
        let params;
        try {
            params = state['routes'][0]['state']['routes'].at(-1).params;
        } catch (error) {
            params = state['routes'][0]['params'].params;
        }
        return params && params.filingId ? params.filingId : null;
    };

    const navigateWithFilingId = (screenName) => {
        const filingId = getFilingIdFromCurrentRoute();
        navigateFiling(navigation, screenName, {'filingId': filingId ? filingId : ''});
    };

    
    return (
    <View style={styles.container}>
        {isLargeScreen ? 
            <Logo /> :
            <View style={{ flexDirection: 'row', justifyContent: 'space-between', paddingHorizontal: 20 }}>
                <Logo />
                <TouchableOpacity onPress={() => navigation.dispatch(DrawerActions.closeDrawer())}>
                    <MaterialIcons name="close" size={24} color={globalColors.text} />
                </TouchableOpacity>
            </View>
        }

        <View style={styles.stepperContainer}>
            <CollapsibleSection state={state} title="Personal" isActive={isActiveSection(state, 'Personal')} firstInStepper={true}>
                <DrawerItem state={state}  section="Personal" label="Personal Info" onPress={() => navigateWithFilingId('personalInfo')}/>
                <DrawerItem state={state}  section="Personal" label="Contact Info" onPress={() => navigateWithFilingId('contactInfo')}/>
                <DrawerItem state={state}  section="Personal" label="Dependents" onPress={() => navigateWithFilingId('dependents')}/>
            </CollapsibleSection>
            
            <CollapsibleSection state={state} title="Federal" isActive={isActiveSection(state, 'Federal')}>
                <DrawerItem state={state}  section="Federal" label="Income" onPress={() => navigateWithFilingId('incomeSources')}/>
                <DrawerItem state={state}  section="Federal" label="Deductions"  onPress={() => navigateWithFilingId('deductionsIntro')}/>
                <DrawerItem state={state}  section="Federal" label="Other Situations" onPress={() => navigateWithFilingId('otherSituations')}/>
            </CollapsibleSection>

            <CollapsibleSection state={state} title="State" isActive={isActiveSection(state, 'State')} lastInStepper={true}>
                <DrawerItem state={state} section="State" label="NY Intro" onPress={() => navigateWithFilingId('nyIntro')}/>
            </CollapsibleSection>

            <CollapsibleSection state={state} title="Review" isActive={isActiveSection(state, 'Review')} lastInStepper={true}>
                <DrawerItem state={state}  section="Review" label="Bank Account" onPress={() => navigateWithFilingId('bankAccount')}/>
                <DrawerItem state={state}  section="Review" label="Generate Filing" onPress={() => navigateWithFilingId('generateFiling')}/>
            </CollapsibleSection>
        </View>


        <View style={styles.signOutContainer}>
            <View>
                <StyledText style={{color: globalColors.text, fontWeight: 'bold'}}>Federal</StyledText>
                <StyledText style={{color: globalColors.primary, fontWeight: 'bold', fontSize: 30, paddingBottom: 20}}>$100</StyledText>

                <StyledText style={{color: globalColors.text, fontWeight: 'bold'}}>State</StyledText>
                <StyledText style={{color: globalColors.primary, fontWeight: 'bold', fontSize: 30, paddingBottom: 20}}>$20</StyledText>
            </View>

            <TouchableOpacity onPress={() => logOut(navigation)} style={{flexDirection: 'row'}}>
                <StyledText style={{color: globalColors.text, fontWeight: 'bold'}}>Sign Out   </StyledText>
                <MaterialIcons name={"logout"} size={18} color={ globalColors.text } style={{paddingTop: 3}}/>
            </TouchableOpacity>
        </View>
    </View>
    );
};

const styles = StyleSheet.create({
    container: {
        flex: 1,
        paddingTop: 40,
        backgroundColor: globalColors.background,
        paddingHorizontal: 12,
    },
    stepperContainer: {
        marginTop: 40,   
    },
    stepperRow: {
        paddingLeft: 15, 
        flexDirection: 'row', 
    },
    stepper: {
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'flex-start',
        flex: 1,
        width: 20,
    },
    circle: {
        width: 16,
        height: 16,
        borderRadius: 8,
    },
    completeCircle: {
        backgroundColor: globalColors.accent,
        justifyContent: 'center',
        alignItems: 'center'
    },
    activeCircle: {
        borderColor: globalColors.accent,
        backgroundColor: globalColors.accentLight,
        borderWidth: 2,
    },
    incompleteCircle: {
        borderWidth: 2,
        borderColor: globalColors.text,
    },
    topLine: {
        height: 12,
        width: 2,
        backgroundColor: globalColors.grey,
    },
    longLine: {
        flex: 1,
        width: 2, 
        backgroundColor: globalColors.grey,
        marginTop: 10,
    },


    collapsibleContainer: {
        flex: 1,
        marginLeft: 10,
        marginRight: 20,
        padding: 15

    },
    activeCollapsibleContainer: {
        flex: 1,
        backgroundColor: globalColors.accentLight, // Active header background
        borderRadius: 10,
        marginLeft: 10,
        marginRight: 15,
        paddingTop: 15,
        paddingBottom: 20,
        paddingHorizontal: 20,
    },
    header: {
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
    },
    activeHeader: {
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
    },
    headerText: {
        fontSize: 16,
        fontWeight: 'bold',
        color: globalColors.text,
    },
    activeHeaderText: {
        fontSize: 16,
        fontWeight: 'bold',
        color: globalColors.accent,
    },
    collapsible: {
        marginTop: 10,
    },
    item: {
        paddingVertical: 10,
        flexDirection: 'row',
        justifyContent: 'flex-start',
        alignItems: 'center'
    },
    itemText: {
        color: globalColors.text,
        fontSize: 15,
        paddingLeft: 10,
    },
    activeItemText: {
        fontWeight: 'bold',
        color: globalColors.accent,
        fontSize: 15,
        paddingLeft: 10,
    },
    signOutContainer: {
        flex: 1,
        justifyContent: 'flex-end',
        paddingBottom: 50,
        paddingLeft: 25
    }
});

export default DrawerContainer;