import axiosInstance from "../axiosInstance";
import AsyncStorage from '@react-native-async-storage/async-storage';
import { CommonActions } from '@react-navigation/native';
import {Animated} from "react-native";

function replaceObjectValues(obj, keys, exceptKeys, needle, replacement) {
    keys.forEach(key => {
        if (obj[key] === needle && !exceptKeys.includes(key)) {
            obj[key] = replacement;
        }
    });
    return obj
}

export function replaceEmptyWithNull(obj, keys=[], exceptKeys=[]) {
    if (keys.length === 0) {keys = Object.keys(obj)}
    return replaceObjectValues(obj, keys, exceptKeys, '', null)
}

export function replaceNullWithEmpty(obj, keys=[], exceptKeys=[]) {
    if (keys.length === 0) {keys = Object.keys(obj)}
    return replaceObjectValues(obj, keys, exceptKeys, null, '')
}

export function replaceNullWithFalse(obj, keys=[], exceptKeys=[]) {
    if (keys.length === 0) {keys = Object.keys(obj)}
    obj = replaceObjectValues(obj, keys, exceptKeys, null, false)
    return obj
}

export function prettifyNumber(inputNumber) {
  // Remove any characters that are not digits or decimal points
  let cleanedInput = String(inputNumber).replace(/[^0-9.]/g, '');

  // Split the string by the decimal point
  const parts = cleanedInput.split('.');

  if (parts.length > 2) {
    // If there are more than one decimal points, keep only the first part and the first occurrence of the decimal point
    cleanedInput = parts[0] + '.' + parts[1];
  }

  // Ensure only up to 2 decimal places for the fractional part
  if (parts.length === 2 && parts[1].length > 2) {
    parts[1] = parts[1].substring(0, 2);
    cleanedInput = parts[0] + '.' + parts[1];
  }

  // Format the integer part with commas every three digits
  const integerPart = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  // Reassemble the number with formatted integer part and decimal part if present
  const formattedValue = parts.length === 2 ? `${integerPart}.${parts[1]}` : integerPart;

    return formattedValue;

};

export function prettifyNumericFields(obj, keys) {
    keys.forEach(key => {
        if (obj.hasOwnProperty(key)) {
            obj[key] = prettifyNumber(obj[key]);
        }
    });
    return obj;
}

export function unPrettifyNumber(inputNumber) {
    return parseFloat(String(inputNumber).replace(/,/g, ''));
}

export function unPrettifyNumericFields(obj, keys) {
    keys.forEach(key => {
        if (obj.hasOwnProperty(key)) {
            obj[key] = unPrettifyNumber(obj[key]);
        }
    });
    return obj;
}

export function updateWithDictOverlap(target, newDict) {
    // function to update target dictionary with new dictionary values for overlapping keys only
    const updatedValues = { ...target };
    Object.keys(target).forEach(key => {
      if (newDict.hasOwnProperty(key)) {
        updatedValues[key] = newDict[key];
      }
    });
    return updatedValues;
  }


export function navigateFiling(navigation, navigateTo, params) {
    navigation.navigate('filing', {screen: 'filingStack', params: {screen: navigateTo, params: params}});
}

export function pullFormValues(filingId, formName, formFields=null) {
    return axiosInstance
        .post('/loadForms', JSON.stringify({'filingId': filingId, 'formName': formName}), { headers: { 'Content-Type': 'application/json' }, withCredentials: true })
        .then(response => response)
        .catch(error => Promise.reject(error));
}

export function pullAndSetFormValues(filingId, formId, setFormValues, initialValues, numericFields=[]) {
    // pulls full form details from backend and sets the form values
    axiosInstance
        .post('loadFormEditor', JSON.stringify({ 'userFormId': formId, 'filingId': filingId }), {
            headers: { 'Content-Type': 'application/json' },
            withCredentials: true
        })
        .then(function (response) {
            console.log(response);
            setFormValues(updateWithDictOverlap(initialValues, prettifyNumericFields(replaceNullWithEmpty(response['data']), numericFields)));
        })
        .catch(function (error) {
            console.log(error);
        });
}

export function pullAndSetFormList(formName, filingId, setFormList) {
    // pulls list of user's forms of a particular type and the sets the form values
    // this is used when loadings screens that list out all of the user's forms of a particular type (e.g. W2s)
    let formCount = 0;
    axiosInstance
      .post('loadForms', JSON.stringify({'formName': formName, 'filingId': filingId}), { headers: {'Content-Type': 'application/json' }, withCredentials: true})
      .then(function (response) {
            setFormList(response['data']);
            formCount = response['data'].length;})
      .catch(function (error) {console.log(error)});
    return formCount;
}

export function deleteFormAndResetFormList(formId, formName, filingId, setFormList) {
    // deletes a form from the backend
    let formCount = 0;
    var jsonData = JSON.stringify({'userFormId': formId, 'filingId': filingId});
    axiosInstance
        .post('deleteUserForm', jsonData, { headers: {'Content-Type': 'application/json' }, withCredentials: true})
        .then(function (response) {formCount = pullAndSetFormList(formName, filingId, setFormList);})
        .catch(function (error) {console.log(error.message);})
    return formCount
}

export function handleFilingIdParam(navigation, route, setFilingId) {
    const filingId = route.params?.filingId;
    if (filingId === undefined) {
        navigation.navigate('landing', { 'screen': 'filings' });
    }
    else {
        setFilingId(filingId);
        return filingId;
    }
}

export function handleFormIdParam(route, setFormValues, setFormId, initialValues, filingId, isFocused, numericFields=[], checkFormExists=false) {
    const formId = route.params?.formId;
    if (isFocused && (formId === null || formId === undefined || formId === '')) {
        // no form ID has been provided. 
        if (checkFormExists !== false) {
            // let's first check if we should request to see if the form type exists
            // this is helpful for any screens that contribute to a catch-all form type. These are forms that have a 1:1 relationship with the filing e.g. "Miscellaneous Inputs"
            pullFormValues(filingId, checkFormExists).then((response) => { 
                if (response['data'].length > 0) {
                    setFormId(response['data'][0]['id']);
                    pullAndSetFormValues(filingId, response['data'][0]['id'], setFormValues,initialValues, numericFields);
                } else {
                    setFormId('');
                    setFormValues(initialValues);
                }
            })
            .catch(error => { console.log(error); });
        } else {
            // no form ID has been provided, and we have not been asked to check if the form exists
            setFormId('');
            setFormValues(initialValues);
        }

        return '';
    } else if (isFocused) {
        // Use Form ID to pull form values from backend
        setFormId(formId);
        pullAndSetFormValues(filingId, formId, setFormValues, initialValues, numericFields);
        return formId;
    }
    return '';
}

export function editFormValues(setSubmitting, setValues, filingId, formId, formName, formValues, initialValues, navigation, navigateTo='', numericFields=[]) {
    let answers = replaceEmptyWithNull(unPrettifyNumericFields(structuredClone(formValues), numericFields));
    answers['filingId'] = filingId;
    let apiURL = '/addUserForm';

    // if we are editing an existing form, then the query needs to change
    if (formId !== null && formId !== '' && formId !== undefined) {
        apiURL = '/editUserForm';
        answers['userFormId'] = formId;
    }
    else {
        answers['userFormName'] = formName;
    }
    let jsonData = JSON.stringify(answers);
    axiosInstance
        .post(apiURL, jsonData, { headers: { 'Content-Type': 'application/json' }, withCredentials: true })
        .then((response) => {
            setValues(initialValues);
            setSubmitting(false);
            console.log(response);  
            navigateFiling(navigation, navigateTo, { filingId: filingId});
        })
        .catch(error => { console.log(error); });
}

export const logOut = async (navigation) => {
    try {
        axiosInstance
        .get('logout', { withCredentials: true })
        .then(async function (response) {
            await AsyncStorage.removeItem('accessToken');  
            navigation.dispatch(
                CommonActions.reset({
                  index: 0,
                  routes: [ { name: 'landing', state: {routes: [{ name: 'home' }] } }], 
                })
            );
        })
        .catch(function (error) {
        });
    } catch (error) {
        console.log(error);
    }
};
