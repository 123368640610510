import React from 'react';
import { Text as RNText, StyleSheet, TextProps } from 'react-native';
import { globalColors } from '../style.js';
import { useFonts } from 'expo-font';


interface StyledTextProps extends TextProps {
  children: React.ReactNode;
  style?: any; // Adjust the style type as needed
}

const StyledText: React.FC<StyledTextProps> = ({ children, style = {}, ...props }) => {
  const [fontsLoaded] = useFonts({
    'OpenSans-Regular': require('../assets/fonts/OpenSans-Regular.ttf'),
    'OpenSans-C': require('../assets/fonts/OpenSans_Condensed-Regular.ttf'),
    'OpenSans-Italic': require('../assets/fonts/OpenSans-Italic.ttf'),
    'OpenSans-Bold': require('../assets/fonts/OpenSans-Bold.ttf'),
    'OpenSans-CB': require('../assets/fonts/OpenSans_Condensed-Bold.ttf'),
    'OpenSans-CEBI': require('../assets/fonts/OpenSans_Condensed-ExtraBoldItalic.ttf'),
    'Montserrat-Regular': require('../assets/fonts/Montserrat-Regular.ttf'),
    'Montserrat-Bold': require('../assets/fonts/Montserrat-Bold.ttf'),
    'Montserrat-ExtraBold': require('../assets/fonts/Montserrat-ExtraBold.ttf'),
  });

  if (!fontsLoaded) {
    return null; // or render a loading indicator
  }

  const isBold = style && (style as { fontWeight: string }).fontWeight === 'bold';
  const isItalic = style && (style as { fontWeight: string }).fontWeight === 'italic';
  const isC = style && (style as { fontWeight: string }).fontWeight === 'C';
  const isCB = style && (style as { fontWeight: string }).fontWeight === 'CB';
  const isCEBI = style && (style as { fontWeight: string }).fontWeight === 'CEBI';
  const isMontserratRegular = style && (style as { fontWeight: string }).fontWeight === 'MontserratRegular';
  const isMontserratBold = style && (style as { fontWeight: string }).fontWeight === 'MontserratBold';
  const isMontserratExtraBold = style && (style as { fontWeight: string }).fontWeight === 'MontserratExtraBold';

  return (
    <RNText style={[styles.defaultStyle, isC && styles.C, isCB && styles.CB, isCEBI && styles.CEBI, isBold && styles.bold, isItalic && styles.italic, 
      , isMontserratRegular && styles.MontserratRegular, isMontserratBold && styles.MontserratBold, 
      isMontserratExtraBold && styles.MontserratExtraBold, style ]} {...props}>
      {children}
    </RNText>
  );
};

const styles = StyleSheet.create({
  defaultStyle: {
    fontFamily: 'OpenSans-Regular',
    color: globalColors.text,
    fontSize: 16
  },
  bold: {
    fontFamily: 'OpenSans-Bold',
  },
  italic: {
    fontFamily: 'OpenSans-Italic',
  },
  C: {
    fontFamily: 'OpenSans-C', 
  },
  CB: {
    fontFamily: 'OpenSans-CB', 
  },
  CEBI: {
    fontFamily: 'OpenSans-CEBI', // Replace with your bold font name
  },
  MontserratRegular: {
    fontFamily: 'Montserrat-Regular', 
  },
  MontserratBold: {
    fontFamily: 'Montserrat-Bold', 
  },
  MontserratExtraBold: {
    fontFamily: 'Montserrat-ExtraBold', 
  },
});

export default StyledText;