import { StyleSheet, View, Text, TouchableOpacity, useWindowDimensions } from 'react-native';
import { useNavigation } from '@react-navigation/native';
import { globalColors } from '../style';
import StyledText from './StyledText';
import { largeScreen, drawerWidth } from '../assets/constants';

const Logo = ({ style = {}, onPress=undefined, color=globalColors.primary}) => {
    const dimensions = useWindowDimensions();
    const isLargeScreen = dimensions.width >= largeScreen;

    if (onPress === undefined) {
        const navigation = useNavigation();
        onPress = () => navigation.navigate('home' as never);
    }

    return (
        // @ts-ignore 
        <TouchableOpacity style={[styles.logoContainer, style]} onPress={onPress}>
            <View style={{ borderBottomWidth: 4, borderBottomColor: color, paddingHorizontal: 4,alignItems: 'center',}}>
                <StyledText style={{...(isLargeScreen ? styles.logoFont : styles.logoFontMobile), lineHeight: 16, color: color }}>THE TAX</StyledText>
                <StyledText style={{...(isLargeScreen ? styles.logoFont : styles.logoFontMobile), lineHeight: 25, color: color }}>PROJECT</StyledText>
            </View>

        </TouchableOpacity>
    );
}
const styles = StyleSheet.create({
    logoContainer: {
        alignItems: 'center',
    },
    logoFont: { 
        fontSize: 23, 
        fontWeight: 'MontserratExtraBold', 
    },
    logoFontMobile: { 
        fontSize: 20, 
        fontWeight: 'MontserratExtraBold', 
    },
});
export default Logo;