import React, { useState, createElement } from 'react';
import { StyleSheet, View } from 'react-native';
import { useFormikContext } from 'formik';
import { useFonts } from 'expo-font';
import StyledText from './StyledText';
import { globalStyle, globalColors } from '../style';
import { format } from 'date-fns';

interface DateFieldProps {
  name: string;
  fieldName: string;
  placeholder?: string;
  fieldStyle?: object;
  width?: number | string;
}

export default function DateField({ name, fieldName, placeholder, fieldStyle, width }: DateFieldProps) {
  const { handleChange, handleBlur, values, errors, touched } = useFormikContext();
  const [fontsLoaded] = useFonts({
    'OpenSans-Regular': require('../assets/fonts/OpenSans-Regular.ttf'),
    'OpenSans-Bold': require('../assets/fonts/OpenSans-Bold.ttf'),
  });
  const [isHovered, setIsHovered] = useState(false);

  if (!fontsLoaded) {
    return null; // or render a loading indicator
  }

  const normalStyle = {
    ...styles.webDatePicker,
    borderWidth: 1,
    borderColor: globalColors.grey,
    margin: 1,
  };
  const hoveredStyle = {
    ...styles.webDatePicker,
    borderColor: globalColors.primary, // Example: change border color on hover
    borderWidth: 2,
  };
  return (
    <View style={{ ...styles.dateContainer, ...fieldStyle, width: width }}>
      <StyledText style={globalStyle.fieldNameStyle}>
        {fieldName}
      </StyledText>
      {
        createElement('input', {
          style: isHovered ? hoveredStyle : normalStyle,
          type: 'date',
          value: (values[name] === '' || values[name] === null) ? format(new Date(1990, 1, 1), "yyyy-MM-dd") : values[name],
          onInput: handleChange(name),
          onBlur: handleBlur(name),
          onMouseEnter: () => setIsHovered(true),
          onMouseLeave: () => setIsHovered(false),
        })
      }
      {touched[name] && errors[name] && <StyledText style={styles.errorText}>{errors[name]}</StyledText>}
    </View>
  );
}

const styles = StyleSheet.create({
  dateContainer: {
    alignItems: 'flex-start',
    zIndex: 1000,
    marginRight: 30
  },
  webDatePicker: {
    fontFamily: 'OpenSans-Regular',
    backgroundColor: "white",
    height: 52,
    borderRadius:5,
    borderStyle: 'solid',
    paddingLeft:7,
    zIndex:100,
    width: '100%'
  },
  errorText: {
    fontSize: 12,
    color: 'red',
    marginTop: 5,
  },
});
