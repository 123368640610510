import { View } from 'react-native';
import React, { useState, useEffect } from 'react';
import { useFocusEffect, useIsFocused } from '@react-navigation/native'
import { Formik } from 'formik';
import ContentContainer from '../../components/ContentContainer';
import ContentHeader from '../../components/ContentHeader';
import BooleanField from '../../components/BooleanFieldFormik';
import DropdownField from '../../components/DropdownFieldFormik';
import axiosInstance from '../../axiosInstance';
import {globalStyle} from '../../style';
import { replaceEmptyWithNull, editFormValues, navigateFiling, handleFilingIdParam, handleFormIdParam } from '../../utils/index';
import { monthCount } from '../../assets/constants';

export default function QualifiedRelativeScreen({route, navigation}) {
  const initialValues = {
    'firstName': '',
    'sharedResidencyBool': '', 
    'sharedResidencyMonths': '',
  }
  const [ formValues, setFormValues ] = useState(initialValues);
  const [ filingId, setFilingId ] = useState('');
  const [ formId, setFormId ] = useState('');
  const isFocused = useIsFocused();

  // if we are editing an existing dependent then we would have received the dependent if as a parameter 
  // we use this to load the existing dependent details upon render and focus
  useEffect(() => {
    const newFilingId = handleFilingIdParam(navigation, route, setFilingId);
    const cleanFormId = handleFormIdParam(route, setFormValues, setFormId, initialValues, newFilingId, isFocused, []); 
  }, [isFocused]);

  const continuePressed = (formValues, setSubmitting, setValues) => {
    // first update the dependent details
    let cleanFormValues = replaceEmptyWithNull(structuredClone(formValues), ['sharedResidencyBool', 'sharedResidencyMonths']);
    editFormValues(setSubmitting, setValues, filingId, formId, 'Dependent', cleanFormValues, initialValues, navigation, '', [], setFormId);

    // now check whether the dependent IS qualified as this will determine the next screen
    cleanFormValues['type'] = 'relative';
    cleanFormValues['filingId'] = filingId;
    axiosInstance
      .post('qualifiedDependentCheck', JSON.stringify(cleanFormValues), { headers: {'Content-Type': 'application/json' }, withCredentials: true})
      .then(function (response) {
        setSubmitting(false);
        navigateFiling(navigation, 'qualifiedDependentResult', 
        {'firstName': formValues['firstName'], 
          'qualifiedBool': response['data']['qualifiedBool'],
          'formid': formId,
          'relationship': 'qualifiedRelative',
          'filingId': filingId
        });
      })
      .catch(function (error) {console.log(error.message);
    })
  };
  const backPressed = () => {
    navigateFiling(navigation, 'addDependent', {'filingId': filingId, 'formId': formId});
  };

  const sharedResidencyMonthsQ = (sharedResidencyBool, firstName) => {
    return (sharedResidencyBool=== true) ? 
    <View style={globalStyle.fieldRow}>
      <DropdownField name="sharedResidencyMonths" fieldName={`How many months did ${firstName} live with you?`} placeholder="Choose" items={monthCount} width={200} /> 
    </View>: 
      <View></View>;
  };

  return (
    <Formik 
      initialValues={formValues} 
      enableReinitialize={true}
      onSubmit={(values, { setSubmitting, setValues }) => {continuePressed(values, setSubmitting, setValues)}}>
     {({ handleChange, handleBlur, handleSubmit, values, setValues, isSubmitting }) => (
    <ContentContainer nextPress={handleSubmit} nextDisabled={isSubmitting} backPress={backPressed}>
      <ContentHeader icon={"family-restroom"} headerText="Share details on your dependent"/>
  
      <View style={globalStyle.fieldRow}>
        <BooleanField name="sharedResidencyBool" fieldName={`Did ${values['firstName']} live with you for more than half of the year?`} />
      </View>

      {sharedResidencyMonthsQ(values['sharedResidencyBool'], values['firstName'])}
    </ContentContainer>
     )}
     </Formik>
  );
}