import React, { useState, useEffect } from 'react';
import { TouchableOpacity, View, StyleSheet, useWindowDimensions } from 'react-native';
import axiosInstance from '../../axiosInstance';
import UploadFileWeb from '../../components/UploadFileWeb';
import {globalColors, globalStyle} from '../../style';
import StyledText from '../../components/StyledText';
import StyledButton from '../../components/StyledButton';
import StyledCard from '../../components/StyledCard';
import { MaterialIcons } from '@expo/vector-icons';
import { largeScreen } from '../../assets/constants';
import { logOut, navigateFiling } from '../../utils';

 export default function UploadFilingScreen({navigation}) {
  const [filesToUpload, setFilesToUpload] = useState([]);
  const [formsFound, setFormsFound] = useState([]);
  const { width } = useWindowDimensions();

  let contentWidth;
  if (width > 1300) {
    contentWidth = 900;
  } else if (width > largeScreen) {
    contentWidth = width * 0.7;
  } else {
    contentWidth = width;
  }

  const addFile = (file) => {
    setFilesToUpload([file]);
    setFormsFound(['Processing file...'])
    let formData = new FormData();
    formData.append('files[]', file);
    axiosInstance
      .post('uploadFiling', formData, { headers: { 'Content-Type': 'multipart/form-data' }, withCredentials: true})
      .then((response) => {
        const forms = Object.keys(response.data);
        const filteredForms = forms.filter(form => form !== '');
        if (filteredForms.length === 0) {
          setFormsFound(['Invalid file: we couldn\'t find any forms in this file.']);
        }
        else {
          setFormsFound(filteredForms);
        }
      })
      .catch(error => {console.log(error);
      }) 
  }

  const removeFile = (index) => {
    let newFiles = [...filesToUpload];
    newFiles.splice(index, 1);
    setFilesToUpload(newFiles);
  }

  const continuePressed = () => {
    navigateFiling(navigation, 'PersonalInfoScreen', { filingId: null });
  }

  return (
    <View style={{...globalStyle.landingContainer, backgroundColor: 'white'}}>
      <View style={globalStyle.landingContainerCentralWide}>
        <StyledText style={{paddingTop: 16, paddingBottom: 16, fontSize: 28, fontWeight: 'bold'}}>Upload your previous filing</StyledText>
        <StyledText style={{paddingBottom: 16, marginBottom: 12}}>You can grab your completed filing from the tax accounting service you used last year</StyledText>

        <UploadFileWeb addFile={addFile} acceptedFileTypes={{'application/pdf': ['.pdf']}}/>

        <View style={{marginTop: 16, width: '100%'}}>
            {filesToUpload.map((file, index) => (
            <StyledCard 
              key={index}
              firstLine={file.name} 
              secondLine={formsFound.join(', ')} 
              rightContainer={
              <TouchableOpacity onPress={()=>removeFile(index)}>
                <MaterialIcons name="delete-outline" size={24} color={globalColors.negative} />
              </TouchableOpacity>
              }
              onPress={false}/>
            ))}
        </View>

      </View>

      <View style={{ ...styles.buttonContainer, width: contentWidth }}>
        <StyledButton 
          title={'Back'} 
          onPress={() => navigation.navigate('landing', {'screen': 'getStarted'})} 
          disabled={false} 
          style={{ backgroundColor: 'transparent' }} 
          textStyle={{ color: globalColors.text }}
        />
        <StyledButton 
          title={'Next'} 
          onPress={() => continuePressed()} 
          disabled={(formsFound.length === 0) || (formsFound[0] === 'Processing file...') || (formsFound[0] === 'Invalid file: we couldn\'t find any forms in this file.')}
        />
      </View>

      <View style={styles.signOutContainer}>
        <TouchableOpacity onPress={() => logOut(navigation)} style={{flexDirection: 'row'}}>
          <MaterialIcons name={"logout"} size={20} color={ globalColors.text } />
          <StyledText style={{color: globalColors.text, paddingLeft: 10, fontWeight: 'bold'}}>Sign Out</StyledText>
        </TouchableOpacity>
      </View>        
    </View>
  )
 };

 const styles = StyleSheet.create({
  uploadedFile: {
    width: '100%',
    padding: 16,
    borderWidth: 1,
    borderColor: globalColors.grey,
    borderRadius: 8,
    marginTop: 16,
    flexDirection: 'row',
  },
  signOutContainer: {
    position: 'absolute',
    bottom: 50,
  },
  buttonContainer: {
    alignSelf: 'center',
    marginTop: 32,
    paddingVertical: 30,
    flexDirection: 'row',
    justifyContent:'space-between',
    borderTopWidth: 1,
    borderColor: globalColors.grey,
  }
});