import React, { useEffect, useRef } from 'react';
import { Animated, StyleSheet } from 'react-native';
import { MaterialIcons } from '@expo/vector-icons';
import { Easing } from 'react-native-reanimated';
import { globalColors } from '../style';

const AnimatedSwingingIcon = ({icon}) => {
    const swing = useRef(new Animated.Value(0)).current;

    const startAnimation = () => {
        swing.setValue(0);
        Animated.sequence([
            Animated.timing(swing, {
                toValue: 1,
                duration: 300,
                useNativeDriver: true,
                easing: Easing.out(Easing.cubic),
            }),
            Animated.timing(swing, {
                toValue: -1,
                duration: 600,
                useNativeDriver: true,
                easing: Easing.out(Easing.cubic),
            }),
            Animated.timing(swing, {
                toValue: 0,
                duration: 300,
                useNativeDriver: true,
                easing: Easing.out(Easing.cubic),
            })
        ]).start(() => {
            setTimeout(startAnimation, 10000);  // Wait for 5 seconds before restarting
        });
    };

    useEffect(() => {
        startAnimation();
    }, []);

    const interpolated = swing.interpolate({
        inputRange: [-1, 1],
        outputRange: ['-15deg', '15deg']
    });

    return (
        <Animated.View style={[styles.icon, { transform: [{ rotate: interpolated }] }]}>
            <MaterialIcons name={icon} size={50} color={globalColors.accent} />
        </Animated.View>
    );
};

const styles = StyleSheet.create({
    icon: {
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center'
    }
});

export default AnimatedSwingingIcon;
