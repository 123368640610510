import React, { useMemo, useState }  from 'react';
import { StyleSheet, TouchableOpacity, Text, View } from 'react-native';
import { useFormikContext, Field, FieldInputProps } from 'formik';
import { MaterialIcons } from '@expo/vector-icons'; 
import StyledText from './StyledText';
import {globalStyle, globalColors} from '../style'

interface BooleanBoxProps {
  name: string;
  fieldName: string;
  trueLabel?: string;
  falseLabel?: string;
}

export default function BooleanField({ name, fieldName, trueLabel='Yes', falseLabel='No'}: BooleanBoxProps) {
  const { handleChange, setFieldValue, values, errors, touched } = useFormikContext();

  const radioButton = (id: string, label: string, newValue: boolean) => {
    return (
      <TouchableOpacity key={id} style={styles.radioButton} onPress={() => setFieldValue(name, newValue)}>
        <MaterialIcons
          name={values[name] === newValue ? 'radio-button-checked' : 'radio-button-unchecked'}
          size={28}
          color={values[name] === newValue ? (newValue ? globalColors.positive : globalColors.negative) : globalColors.text}
        />
        <StyledText style={styles.radioButtonLabel}>{label}</StyledText>
      </TouchableOpacity>
    );
  };

  return (
    <View style={styles.container}>
      <StyledText style={globalStyle.fieldNameStyle}>
        {fieldName}
      </StyledText>
      {radioButton('1', trueLabel, true)}
      {radioButton('2', falseLabel, false)}
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    width: '100%',
  },
  radioButton: {
    flexDirection: 'row',
    marginTop: 16
  },
  radioButtonLabel: {
    fontSize: 16,
    paddingLeft: 12,
  },

});
