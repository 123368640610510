import { StyleSheet, Button, TouchableOpacity, Text, View, ActivityIndicator  } from 'react-native';
import React, { useState, useEffect } from 'react';
import { useFocusEffect, useIsFocused } from '@react-navigation/native'
import { Formik } from 'formik';
import ContentContainer from '../../../components/ContentContainer';
import ContentHeader from '../../../components/ContentHeader';
import TextFieldFormik from '../../../components/TextFieldFormik';
import DollarFieldFormik from '../../../components/DollarFieldFormik';
import {globalStyle} from '../../../style';
import { handleFilingIdParam, handleFormIdParam, editFormValues, navigateFiling } from '../../../utils';

export default function AddF1099IntScreen({route, navigation}) {
  const initialValues = {
    'payerName': '', 
    'interestIncome': '', 
    'earlyWithdrawalPenalty': '', 
    'savingsBondsInterest': '', 
    'federalIncomeTax': '', 
    'investmentExpenses': '', 
    'foreignTaxPaid': '', 
    'taxExemptInterest': '', 
    'privateBondInterest': '',
    'marketDiscount': '',
    'bondPremium': '',
    'bondPremiumOnTreasury': '',
    'bondPremiumOnTaxExempt': ''
  }
  const [formValues, setFormValues] = useState(initialValues);
  const [filingId, setFilingId] = useState('');
  const [formId, setFormId] = useState('');
  const isFocused = useIsFocused();
  const numericFields  = ['interestIncome', 'earlyWithdrawalPenalty', 'savingsBondsInterest', 'federalIncomeTax', 'investmentExpenses', 'foreignTaxPaid', 'taxExemptInterest', 'privateBondInterest', 'marketDiscount', 'bondPremium', 'bondPremiumOnTreasury', 'bondPremiumOnTaxExempt']

  // if we are editing an existing dependent then we would have received the dependent if as a parameter 
  // we use this to load the existing dependent details upon render and focus
  useEffect(() => {
    const newFilingId = handleFilingIdParam(navigation, route, setFilingId);
    const cleanFormId = handleFormIdParam(route, setFormValues, setFormId, initialValues, newFilingId, isFocused, numericFields); 
  }, [isFocused]);


  const continuePressed = (formValues, setSubmitting, setValues) => {
    editFormValues(setSubmitting, setValues, filingId, formId, 'Form 1099-INT', formValues, initialValues, navigation, 'f1099Int', numericFields);
  };

  const backPressed = (setValues) => {
    navigateFiling(navigation, 'f1099Int', { filingId: filingId });
  };

  return (
    <Formik 
    initialValues={formValues} 
    enableReinitialize={true}
    onSubmit={(values, { setSubmitting, setValues }) => {continuePressed(values, setSubmitting, setValues)}}>
    {({ handleChange, handleBlur, handleSubmit, values, setValues, isSubmitting }) => (
      <ContentContainer nextPress={handleSubmit} nextDisabled={isSubmitting} backPress={backPressed} backDisabled={false}>
        <ContentHeader icon={"attach-money"} headerText={"Enter details from your F1099-Int"} />
 
        <View>
          <View style={globalStyle.fieldRow}>
            <TextFieldFormik name="payerName" fieldName="Payer Name" placeholder="" fieldStyle={{'flex': 2, minWidth: 250}} /> 
            <View style={{'flex': 2}}></View>
          </View>
          <View style={globalStyle.fieldRow}>
            <DollarFieldFormik name="interestIncome" fieldName="1. Interest Income" placeholder="" fieldStyle={{'flex': 2, minWidth: 250}} /> 
            <DollarFieldFormik name="earlyWithdrawalPenalty" fieldName="2. Early Withdrawal Penalty" placeholder="" fieldStyle={{'flex': 2, minWidth: 250}} /> 
          </View>
          <View style={globalStyle.fieldRow}>
            <DollarFieldFormik name="savingsBondsInterest" fieldName="3. Interest on U.S. Savings Bonds" placeholder="" fieldStyle={{'flex': 2, minWidth: 250}} /> 
            <DollarFieldFormik name="federalIncomeTax" fieldName="4. Federal income tax withheld" placeholder="" fieldStyle={{'flex': 2, minWidth: 250}} /> 
          </View>
          <View style={globalStyle.fieldRow}>
            <DollarFieldFormik name="investmentExpenses" fieldName="5. Investment expenses" placeholder="" fieldStyle={{'flex': 2, minWidth: 250}} /> 
            <DollarFieldFormik name="foreignTaxPaid" fieldName="6. Foreign tax paid" placeholder="" fieldStyle={{'flex': 2, minWidth: 250}} /> 
          </View>
          <View style={globalStyle.fieldRow}>
            <DollarFieldFormik name="taxExemptInterest" fieldName="8. Tax-exempt interest" placeholder="" fieldStyle={{'flex': 2, minWidth: 250}}/> 
            <DollarFieldFormik name="privateBondInterest" fieldName="9. Specified private activity bond interest" placeholder="" fieldStyle={{'flex': 2, minWidth: 250}}/> 
          </View>
        </View>
      </ContentContainer>
    )}
    </Formik>
  );
}