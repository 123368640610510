import React, { useLayoutEffect, useRef } from 'react';
import { View, StyleSheet, SafeAreaView, ScrollView, useWindowDimensions, Text } from 'react-native';
import StyledText from '../../components/StyledText';
import StyledButton from '../../components/StyledButton';
import { globalColors, globalStyle } from '../../style';
import LottieAnimation from '../../components/LottieAnimation';
import animationData from '../../assets/animations/home.json'
import greedyAnimationData from '../../assets/animations/greedy.json';
import confusedAnimationData from '../../assets/animations/home_confused.json';
import trashAnimationData from '../../assets/animations/trash.json';
import { largeScreen, drawerWidth } from '../../assets/constants';
import { MaterialIcons } from '@expo/vector-icons';
import Logo from '../../components/Logo';

export default function HomeScreen({navigation}) {
    const scrollViewRef = useRef(null);
    const dimensions = useWindowDimensions();
    const isLargeScreen = dimensions.width >= largeScreen;

    // Function to scroll the view
    const scrollDown = () => {
      scrollViewRef.current.scrollTo({
        y: 720,
        animated: true,
      });
    };

    // Set the navigation options
    useLayoutEffect(() => {
      navigation.setOptions({
        headerRight: () => (
          <View style={{ flexDirection: 'row', alignItems: 'center' }}>
            {isLargeScreen ? 
            (<View style={{ flexDirection: 'row', alignItems: 'center' }}>
              <StyledButton title="Our mission" onPress={scrollDown} style={{marginRight: 32, backgroundColor: '#fff'}} textStyle={{fontSize: 18, color: globalColors.text}}/>
              <StyledButton title="Sign in" onPress={() => {navigation.navigate('login' as never)}} style={{marginRight: 48, paddingHorizontal: 48}} textStyle={{fontSize: 18}}/>
            </View> ) :
            (<View style={{ flexDirection: 'row', alignItems: 'center' }}>
              <StyledButton title="Sign in" onPress={() => {navigation.navigate('login' as never)}} style={{marginRight: 20, paddingHorizontal: 36}} textStyle={{fontSize: 18}}/>
            </View> )}
          </View>
        ),
      });
    }, [navigation, scrollDown]);
    
    const CoveredBullet = ({ children, iconName, iconColor }) => (
      <View style={styles.coveredBullet}>
      <MaterialIcons name={iconName} size={24} color={iconColor} />
      <StyledText style={{ marginLeft: 8 }}>{children}</StyledText>
      </View>
    );

    return (
    <SafeAreaView style={{ flex: 1 }}>
      <ScrollView ref={scrollViewRef} style={{height: '100%'}}>
      <View style={{...globalStyle.landingContainer, backgroundColor: globalColors.background}}>
        {/* HOME CARD */}
        <View style={styles.centralContainer}>

          <View style={styles.animationContainer}>
            <LottieAnimation
              source={animationData}
              loop={true}
              autoplay={true}
              height={300}
              width={360}
            />
          </View>
          <Text style={{ flexDirection: 1, textAlign: 'center'}}>
            <StyledText style={isLargeScreen ? styles.homeTextBold : styles.homeTextBoldMobile}>We're filing taxes for </StyledText>
            <StyledText style={{ ...(isLargeScreen ? styles.homeTextBold : styles.homeTextBoldMobile), color: globalColors.accent }}>free</StyledText>
            <StyledText style={isLargeScreen ? styles.homeTextBold : styles.homeTextBoldMobile}>.</StyledText>
          </Text>
          <View style={{ flexDirection: 'row', marginTop: 60}}>
            <Text style={{textAlign: 'center'}}>
              <StyledText style={styles.homeTextH3}>$0 federal. $0 state. </StyledText>
              <StyledText style={{ ...styles.homeTextH3, color: globalColors.accent, fontWeight: 'bold' }}>No exceptions.</StyledText>
            </Text>

          </View>
          <StyledButton title="Join the revolution!" onPress={() => navigation.navigate('signup')} style={[styles.loginButton, {marginTop: 28, marginBottom: 100}]} textStyle={styles.joinButtonText}/>
        
          {/* WHY ARE WE DOING THIS CARD: CORRUPTION */}
          <View style={{...styles.twoColumnCardContainer, flexDirection: isLargeScreen ? 'row' : 'column'}}>
            <View style={styles.twoColumnCard}>
              <StyledText style={{...styles.homeTextH4, fontWeight: 'bold'}}>Why are we doing this?</StyledText>
              <StyledText style={{...styles.homeTextH4, fontWeight: 'bold', color: globalColors.accent}}>The system is corrupt.</StyledText>
              <StyledText style={styles.homeTextH5}>Tax prep companies have spent $100m lobbying to make sure you have to waste time and money on tax prep.</StyledText>
            </View>
            <View style={styles.twoColumnCard}>
              <LottieAnimation source={greedyAnimationData} loop={true} autoplay={true} height={200} />
            </View>
          </View>

          {/* WHY ARE WE DOING THIS CARD: SIMPLER TAXES */}
          <View style={{...styles.twoColumnCardContainer, flexDirection: isLargeScreen ? 'row' : 'column'}}>
            <View style={styles.twoColumnCard}>
              <LottieAnimation source={confusedAnimationData} loop={true} autoplay={true} height={200} />
            </View>
            <View style={styles.twoColumnCard}>
              <StyledText style={{...styles.homeTextH4, fontWeight: 'bold', color: globalColors.accent}}>Taxes should be simpler.</StyledText>
              <StyledText style={styles.homeTextH5}>The IRS should adopt a “Pay-As-You-Earn” system, where taxes are automatically deducted from paychecks.</StyledText>
              <StyledText style={styles.homeTextH5}> </StyledText>
              <StyledText style={{...styles.homeTextH5}}>Most Americans would never have to file taxes again!</StyledText>
            </View>
          </View>

          {/* Take out the trash */}
          <View style={{...styles.twoColumnCardContainer, flexDirection: isLargeScreen ? 'row' : 'column'}}>
            <View style={styles.twoColumnCard}>
              <StyledText style={{...styles.homeTextH4, fontWeight: 'bold', color: globalColors.accent}}>Let's take out the trash.</StyledText>
              <StyledText style={styles.homeTextH5}>Until we get a system that works for Americans, we’re filing taxes for free to take down the greedy tax-prep industry.</StyledText>
            </View>
            <View style={styles.twoColumnCard}>
              <LottieAnimation source={trashAnimationData} loop={true} autoplay={true} height={200} />
            </View>
          </View>
        </View>
        
        {/* CHECK IF WE'RE READY FOR YOU */}
        <View style={styles.centralContainer}>
          <StyledText style={{...styles.homeTextH2, fontWeight: 'bold', marginBottom: 10}}>Check if we're ready for you</StyledText>
          <StyledText style={{...styles.homeTextH5, marginBottom: 40}}>We’re making sure we get it right, so we only cover basic situations for now</StyledText>
          <View style={{ flexDirection: isLargeScreen ? 'row' : 'column', justifyContent: 'center', alignItems: 'center', columnGap: 32, paddingBottom: 100}}>
            <View style={{...styles.coverageBox}}>
              <StyledText style={{...styles.homeTextH5, fontWeight: 'bold', marginBottom: 8}}>Covered situations</StyledText>

              <CoveredBullet iconName='check' iconColor={globalColors.primary}>Wages (W2)</CoveredBullet>
              <CoveredBullet iconName='check' iconColor={globalColors.primary}>Interest, Dividend, Capital Gains</CoveredBullet>
              <CoveredBullet iconName='check' iconColor={globalColors.primary}>Standard deductions only</CoveredBullet>
              <CoveredBullet iconName='check' iconColor={globalColors.primary}>States: NY, NH, TX, FL</CoveredBullet>
              <View style={{marginTop: 12, width: '100%', justifyContent: 'center', alignItems: 'center'}}>
                <StyledText style={{...styles.homeTextBold, fontWeight: 'bold', color: globalColors.accent}}>$0</StyledText>
              </View>

              <StyledButton title="Get Started" onPress={() => {navigation.navigate('signup' as never)}} style={{paddingHorizontal: 48, alignSelf: 'center', marginTop: 'auto'}} textStyle={{fontSize: 18}}/>
            </View>

            <View style={{...styles.coverageBox}}>
              <StyledText style={{...styles.homeTextH5, fontWeight: 'bold', marginBottom: 8}}>Coming soon!</StyledText>
              <CoveredBullet iconName='close' iconColor={globalColors.negative}>Other states</CoveredBullet>
              <CoveredBullet iconName='close' iconColor={globalColors.negative}>Itemized deductions</CoveredBullet>
            </View>
          </View>
        </View>
        <View style={{...styles.homeCard, backgroundColor: globalColors.primary}}>
          <Logo color='white'/>
        </View>
      </View>
    </ScrollView>
  </SafeAreaView>
  )
}

const styles = StyleSheet.create({

  centralContainer: {
    width: '100%', 
    maxWidth: 900, 
    justifyContent: 'center', 
    alignItems: 'center',
    paddingTop: 20,
    paddingBottom: 80,
    paddingHorizontal: 24,
  },
  homeTextBold: {
    fontSize: 60,
    fontWeight: 'bold',
    color: globalColors.text,
    textAlign: 'center',
  },
  homeTextBoldMobile: {
    fontSize: 32,
    fontWeight: 'bold',
    color: globalColors.text,
    textAlign: 'center',
  },
  homeTextH2: {
    fontSize: 32,
    color: globalColors.text,
    textAlign: 'left',
  },
  homeTextH3: {
    fontSize: 28,
    color: globalColors.text,
    textAlign: 'left',
  },
  homeTextH4: {
    fontSize: 24,
    color: globalColors.text,
    textAlign: 'left',
  },
  homeTextH5: {
    fontSize: 20,
    color: globalColors.text,
    textAlign: 'left',
  },
  animationContainer: {
    marginVertical: 8,
    width: '100%',
    maxWidth: 432,
  },
  loginButton: {
    marginTop: 20,
    paddingVertical: 11,
    backgroundColor: globalColors.primary, 
    width: 340
  },
  joinButtonText: {
    color: '#fff',
    fontWeight: 'bold',
    fontSize: 20
  },
  twoColumnCardContainer: {
    justifyContent: 'center',
    columnGap: 100,
    width: '100%',
    alignItems: 'center',
    paddingVertical: 88
  },
  twoColumnCard: {
    maxWidth: 432,
  },
  coverageBox: {
    backgroundColor: 'white',
    height: 420,
    width: 360,
    padding: 28,
    marginBottom: 12,
    borderRadius: 12,
    shadowColor: '#000',
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.25,
    shadowRadius: 3.84,
    elevation: 5,
  }, 
  coveredBullet: {
    flexDirection: 'row',
    marginTop: 16
  },
  homeCard: {
    width: '100%', 
    justifyContent: 'center', 
    alignItems: 'center', 
    paddingVertical: 50,
    paddingHorizontal: 24,
    
  },
});
  