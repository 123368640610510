import { StyleSheet, Button, TouchableOpacity, Text, View, TextInput  } from 'react-native';
import StyledText from '../../../components/StyledText';
import { useIsFocused } from '@react-navigation/native'
import React, { useState, useEffect } from 'react';
import axiosInstance from '../../../axiosInstance';
import StyledButton from '../../../components/StyledButton';
import ContentContainer from '../../../components/ContentContainer';
import {globalStyle} from '../../../style';
import { MaterialIcons } from '@expo/vector-icons'; 
import { handleFilingIdParam, navigateFiling } from '../../../utils';

export default function F1099BScreen({navigation, route}) {
  // declare field state variables
  const [f1099Bs, setF1099Bs] = useState([])
  const [filingId, setFilingId] = useState('');
  const isFocused = useIsFocused()

  // re-render screen every time its opened
  useEffect(() => {
    if (isFocused) {
      const newFilingId = handleFilingIdParam(navigation, route, setFilingId);
      pullF1099Bs(newFilingId);
    }
  } , [isFocused]);

  const pullF1099Bs = (newFilingId) => {
    axiosInstance.post('loadF1099BCollections', JSON.stringify({'filingId': newFilingId}), {headers: { 'Content-Type': 'application/json' }, withCredentials: true})
    .then((response) => {
      // pre-populate the payer name
      console.log(response['data']);
      setF1099Bs(response['data']);
    })
    .catch(error => {
      console.log(error);
    });
  };

  const deleteF1099B = (payerName) => {
    axiosInstance
      .post('deleteF1099BCollection', JSON.stringify({'filingId': filingId, 'payerName': payerName}), { headers: {'Content-Type': 'application/json' }, withCredentials: true})
      .then(function (response) {pullF1099Bs(filingId);})
      .catch(function (error) {console.log(error.message);})
  };

  const continuePressed = () => {
    navigateFiling(navigation, 'incomeSources', { filingId: filingId });
  };
  const backPressed = () => {
    navigateFiling(navigation, 'incomeSources', { filingId: filingId });
  };

  return (
    <ContentContainer nextPress={continuePressed} nextDisabled={false} backPress={backPressed}>
      <StyledText style={globalStyle.screenHeaderText}>Your Capital Gains and Losses (Form 1099-B)</StyledText>
      <StyledText style={globalStyle.screenSubHeaderText}>Enter any investment sales you made.</StyledText>
      
      {// create a row for each dependent in the state
        f1099Bs.map((form, index) => (
          <View style={globalStyle.fieldRow} key={index}>
            <View>
              <StyledText style={[globalStyle.fieldNameStyle, {fontWeight: 'bold', paddingRight:10}]}>{form.payerName}</StyledText>
            </View>
            
            <TouchableOpacity onPress={() => navigateFiling(navigation, 'addF1099B', {'filingId': filingId, payerName:form.payerName}) }>
              <MaterialIcons name="edit" size={24} color="black" />
            </TouchableOpacity>
            <TouchableOpacity onPress={()=>deleteF1099B(form.payerName)}>
              <MaterialIcons name="delete-outline" size={24} color="black" />
            </TouchableOpacity>
          </View>
        ))
      }
      {
        <View style={globalStyle.fieldRow}>
          <StyledButton title='Add a F1099-B form' onPress={() => navigateFiling(navigation, 'addF1099B', { filingId: filingId, payerName: null }) }/>
        </View>
      }
    </ContentContainer>
  );
}