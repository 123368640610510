import { StyleSheet, Button, TouchableOpacity, View, TextInput  } from 'react-native';
import React, { useState, useEffect } from 'react';
import { useFocusEffect, useIsFocused } from '@react-navigation/native'
import { Formik } from 'formik';
import ContentContainer from '../../components/ContentContainer';
import ContentHeader from '../../components/ContentHeader';
import StyledText from '../../components/StyledText';
import BooleanField from '../../components/BooleanFieldFormik';
import DropdownField from '../../components/DropdownFieldFormik';
import axiosInstance from '../../axiosInstance';
import {globalStyle} from '../../style';
import { replaceEmptyWithNull, editFormValues, navigateFiling, handleFilingIdParam, handleFormIdParam } from '../../utils/index';
import { monthCount } from '../../assets/constants';

export default function QualifiedChildScreen({route, navigation}) {
  const initialValues = {
    'firstName': '',
    'usCitizenBool': '',
    'sharedResidencyBool': '', 
    'sharedResidencyMonths': '',
    'financialSupportBool': '', 
    'jointTaxBool': '', 
  }
  const [ formValues, setFormValues ] = useState(initialValues);
  const [ filingId, setFilingId ] = useState('');
  const [formId, setFormId] = useState('');
  const isFocused = useIsFocused();

  useEffect(() => {
    const newFilingId = handleFilingIdParam(navigation, route, setFilingId);
    const cleanFormId = handleFormIdParam(route, setFormValues, setFormId, initialValues, newFilingId, isFocused, []); 
  }, [isFocused]);

  const continuePressed = (formValues, setSubmitting, setValues) => {
    // first update the dependent details
    let cleanFormValues = replaceEmptyWithNull(structuredClone(formValues), ['usCitizenBool', 'sharedResidencyBool', 'sharedResidencyMonths', 'financialSupportBool', 'jointTaxBool']);
    editFormValues(setSubmitting, setValues, filingId, formId, 'Dependent', cleanFormValues, initialValues, navigation, '', [], setFormId);

    // now check whether the dependent IS qualified as this will determine the next screen
    cleanFormValues['type'] = 'child';
    cleanFormValues['filingId'] = filingId;
    axiosInstance
      .post('qualifiedDependentCheck', JSON.stringify(cleanFormValues), { headers: {'Content-Type': 'application/json' }, withCredentials: true})
      .then(function (response) {
        setSubmitting(false);
        navigateFiling(navigation, 'qualifiedDependentResult', 
          {'firstName': formValues['firstName'], 
          'qualifiedBool': response['data']['qualifiedBool'],
          'formId': formId,
          'relationship': 'qualifiedChild',
          'filingId': filingId}
        );
      })
      .catch(function (error) {console.log(error.message);
    })
  };

  const backPressed = () => {
    navigateFiling(navigation, 'addDependent', {'filingId': filingId, 'formId': formId});
  };

  const sharedResidencyMonthsQ = (sharedResidencyBool) => {
    return (sharedResidencyBool=== true) ? 
    <View style={globalStyle.fieldRow}>
      <DropdownField name="sharedResidencyMonths" fieldName='How many months did live with you?' placeholder="Choose" items={monthCount} width={200} /> 
    </View>: 
      <View></View>;
  };

  return (
    <Formik 
      initialValues={formValues} 
      enableReinitialize={true}
      onSubmit={(values, { setSubmitting, setValues }) => {continuePressed(values, setSubmitting, setValues)}}>
     {({ handleChange, handleBlur, handleSubmit, values, setValues, isSubmitting }) => (
    <ContentContainer nextPress={handleSubmit} nextDisabled={isSubmitting} backPress={backPressed}>
      <ContentHeader icon={"family-restroom"} headerText="Share details on your dependent" subHeaderText={`We need more information to see if ${values['firstName']} can help reduce your tax bill`}/>

      <View style={globalStyle.fieldRow}>
        <BooleanField name="usCitizenBool" fieldName={`Is ${values['firstName']} a U.S. citizen, U.S. resident alien, U.S. national, or a resident of Canada or Mexico?`} trueLabel="Yes" falseLabel="No" />
      </View>

      <View style={globalStyle.fieldRow}>
        <BooleanField name="sharedResidencyBool" fieldName={`Did ${values['firstName']} live with you for more than half of the year?`} trueLabel="Yes" falseLabel="No" />
      </View>

      {sharedResidencyMonthsQ(values['sharedResidencyBool'])}

      <View style={globalStyle.fieldRow}>
        <BooleanField name="financialSupportBool" fieldName={`Did you provide more than half of ${values['firstName']}'s financial support?`} trueLabel="Yes" falseLabel="No" />
      </View>

      <View style={globalStyle.fieldRow}>
        <BooleanField name="jointTaxBool" fieldName={`Is ${values['firstName']} filing a joint tax return with someone else?`} trueLabel="Yes" falseLabel="No" />
      </View>

    </ContentContainer>
     )}
     </Formik>
  );
}