import React, { useState, useEffect } from 'react';
import { useIsFocused } from '@react-navigation/native';
import { Button, TouchableOpacity, View, StyleSheet } from 'react-native';
import StyledButton from '../../../components/StyledButton';
import StyledText from '../../../components/StyledText';
import {globalStyle} from '../../../style';
import ContentContainer from '../../../components/ContentContainer';
import axiosInstance from '../../../axiosInstance';

 export default function DeductionScreen({navigation}) {

  const initialValues = {
    'Medical and dental expenses': {'count': 0, 'total': 0, 'name': 'Medical'},
    'Charity donations': {'count': 0, 'total': 0, 'name': 'Charity'}
  }
  const isFocused = useIsFocused();
  const [incomeSources, setIncomeSources] = useState(initialValues);
  const formNavigationMapping = {
    'Medical': 'medicalDeduction',
    'Charity': 'f1099Int',
  }
  useEffect(() => {
    if (isFocused) {
      axiosInstance
        .get('loadDeductionScreen', {withCredentials: true})
        .then(function (response) {
          if (response['data'].length > 0) {
            console.log(response['data']);
          }
        })
        .catch(function (error) {});
    }
  }, [isFocused]);

  const continuePressed = () => {
    navigation.navigate('otherSituations');
  }
  const backPressed = () => {
    navigation.navigate('deductionsIntro');
  };
  return (
    <ContentContainer nextPress={continuePressed} nextDisabled={false} backPress={backPressed}>
      <StyledText style={globalStyle.screenHeaderText}>What deductibles apply to you?</StyledText>

      <View style={styles.container}>
        <View style={styles.row}>
          <View style={styles.cell}><StyledText style={styles.headerText}>Deductible</StyledText></View>
          <View style={styles.cell}><StyledText style={styles.headerText}>2023</StyledText></View>
          <View style={styles.cell}></View>
        </View>
        {Object.entries(incomeSources).map(([formDescription, formSummary]) => (
          <View key={formSummary.name} style={styles.row}>
            <View style={styles.cell}>
              <StyledText style={styles.cellText}>{formDescription}</StyledText>
            </View>
            <View style={styles.cell}>
              <StyledText style={styles.cellText}>${formSummary.total}</StyledText>
            </View>
            <View style={styles.cell}>
              <StyledButton 
                title={formSummary.count > 0 ? 'Edit' : 'Start'} 
                 onPress={() => navigation.navigate(formNavigationMapping[formSummary.name])}/>:
            </View>
          </View>
        ))}
          <View style={styles.row}>
            <View style={styles.cell}>
              <StyledText style={styles.cellText}>Total Income</StyledText>
            </View>
            <View style={styles.cell}>
              <StyledText style={styles.cellText}>$</StyledText>
            </View>
            <View style={styles.cell}>
            </View>
          </View>

      </View>
      
    </ContentContainer>
  )
 };

 const styles = StyleSheet.create({
  container: {
    padding: 20,
  },
  row: {
    flexDirection: 'row',
    borderBottomWidth: 1,
    borderBottomColor: '#e5e5e5',
  },
  cell: {
    flex: 1,
    padding: 10,
    justifyContent: 'center',
    alignItems: 'center',
  },
  headerText: {
    fontWeight: 'bold',
    fontSize: 16,
  },
  cellText: {
    fontSize: 14,
  },
});
