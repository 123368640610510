import React, { useState, useEffect } from 'react';
import { useIsFocused } from '@react-navigation/native';
import { Formik } from 'formik';
import { Button, TouchableOpacity, View, StyleSheet } from 'react-native';
import Collapsible from 'react-native-collapsible';
import ContentContainer from '../../../components/ContentContainer';
import ContentHeader from '../../../components/ContentHeader';
import { replaceEmptyWithNull } from '../../../utils/index'; 
import { handleFilingIdParam, navigateFiling, prettifyNumber } from '../../../utils';
import axiosInstance from '../../../axiosInstance';
import StyledText from '../../../components/StyledText';
import {globalColors} from '../../../style'
import StyledButton from '../../../components/StyledButton';

 export default function IncomeSourcesScreen({navigation, route}) {
  /* 
    To add an Income Source box, you need to add a row to the Fields database.
    The row should be under the 'Miscellaneous' column and have 'income_bool' as the section name.
  */
  const initialIncomeCategories = {
    'Wages and Salaries': {
      description: 'You received a W-2 from your employer.',
      totalIncome: 0,
      sources: {'Wages and salaries (W2)': {status: false, description: 'You received a W-2 from your employer.', navigation: 'w2'}}
    },
    'Investments': {
      description: 'You made money.',
      totalIncome: 0,
      sources: {
        'Earned interest (Form 1099-INT)': {status: false, income: 0, description: 'You received interest income.', navigation: 'f1099Int'},
        'Received dividends (Form 1099-DIV)': {status: false, income: 0, description: 'You received dividend income.', navigation: 'f1099Div'},
        'Form 1099-B': {status: false, income: 0, description: 'You sold an investment.', navigation: 'f1099B'},
      }
    },
    'Retirement & Social Security': {
      description: 'Retirement',
      totalIncome: 0,
      sources: {
        '1099-R': {status: false, income: 0, description: 'Retirement'},
      }
    }
  }
  const isFocused = useIsFocused();
  const [incomeCategories, setIncomeCategories] = useState(initialIncomeCategories);
  const [filingId, setFilingId] = useState('');

  const updateCategoryByKey = (currentCategories:Object, keyToUpdate:string, sourceDetails:Object) => {
    const newIncomeCategories = {...currentCategories};
    for (const category in newIncomeCategories) {
      const sources = newIncomeCategories[category].sources;
      if (sources && sources[keyToUpdate]) {
        // Update the specific key within the sources object
        sources[keyToUpdate] = {
          ...sources[keyToUpdate],
          status: true,
          income: Number(sourceDetails['total']),
          sources: sourceDetails['sources']
        };
        break;
      }
    }
    return newIncomeCategories;
  };

  const incomeCategory = (index:number, categoryName:string) => {
    const [collapsed, setCollapsed] = useState(false);
    const categoryDetails = incomeCategories[categoryName];
    return (
    <View key={index} style={{ width: '100%', marginTop: 16}} >
      <TouchableOpacity onPress={() => setCollapsed(!collapsed)} style={{borderTopLeftRadius: 8, borderTopRightRadius:8, padding: 16, backgroundColor: globalColors.background, borderWidth: 1, borderColor: globalColors.grey}}>
        <StyledText style={{fontWeight: 'bold', fontSize: 16}}>
          {categoryName}
        </StyledText>
      </TouchableOpacity>
      <Collapsible collapsed={collapsed}>
        {Object.keys(categoryDetails['sources']).map((sourceName, sourceKey) => (
          incomeSource(sourceKey, sourceName, categoryDetails['sources'][sourceName])
        ))}
      </Collapsible>
    </View>
  )};

  const incomeSource = (sourceKey:number, sourceName:string, sourceDetails:Object) => {
    return (
      <View key={sourceKey} style={{width: '100%', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', padding: 16, borderBottomWidth: 1, borderLeftWidth: 1, borderRightWidth: 1, borderColor: globalColors.grey, }}>
        <View>
          <StyledText style={{fontWeight: 'bold'}}>{sourceName}</StyledText>
          <StyledText>{sourceDetails['sources']}</StyledText>
        </View>
        <View style={{flex: 1}}></View>
        <StyledText style={{paddingRight: 40}}>${prettifyNumber(sourceDetails['income'])}</StyledText>
        {sourceDetails['status'] ? 
          <StyledButton title='Edit' onPress={() => navigateFiling(navigation, sourceDetails['navigation'], { filingId: filingId }) } /> : 
          <StyledButton title='Start' onPress={() => navigateFiling(navigation, sourceDetails['navigation'], { filingId: filingId }) } />}
      </View>
    )}; 

  useEffect(() => {
    if (isFocused) {
      const newFilingId = handleFilingIdParam(navigation, route, setFilingId);
      axiosInstance
        .post('loadIncomeScreen', JSON.stringify({'filingId': newFilingId}), { headers: {'Content-Type': 'application/json'}, withCredentials: true})
        .then(function (response) {
          // Convert object to array and sort
          let newIncomeCategories = {...initialIncomeCategories};
          for (const [sourceName, sourceDetails] of Object.entries(response['data']['formSummary'])) {
            newIncomeCategories = updateCategoryByKey(newIncomeCategories, sourceName, sourceDetails);
          };
          console.log(newIncomeCategories);
          setIncomeCategories(newIncomeCategories);
        })
        .catch(function (error) {});
    }
  }, [isFocused]);

  const continuePressed = (formValues, setSubmitting, setValues, resetForm)  => {
    navigateFiling(navigation, 'deductionsIntro', { filingId: filingId });
  }
  const backPressed = (setValues) => {
    navigateFiling(navigation, 'dependents', { filingId: filingId });
  };
  return (
      <ContentContainer nextPress={continuePressed} backPress={backPressed}>
        <ContentHeader icon={"attach-money"} headerText="Your Income Sources" subHeaderText="Share details on your income"/>
        {Object.keys(incomeCategories).map((categoryName, categoryIndex) => (
          incomeCategory(categoryIndex, categoryName)
        ))}
      </ContentContainer>
  )
 };

 const styles = StyleSheet.create({

});
