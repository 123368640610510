import { TouchableOpacity, View  } from 'react-native';
import React, { useState, useEffect } from 'react';
import { Formik } from 'formik';
import { useIsFocused } from '@react-navigation/native'
import ContentContainer from '../../components/ContentContainer';
import StyledText from '../../components/StyledText';
import TextField from '../../components/TextFieldFormik';
import BooleanField from '../../components/BooleanFieldFormik';
import DropdownField from '../../components/DropdownFieldFormik';
import {globalStyle, globalColors} from '../../style';
import { countries, residencyStates } from '../../assets/constants';
import { editFormValues, replaceEmptyWithNull, navigateFiling, handleFormIdParam } from '../../utils/index'; 
import { handleFilingIdParam } from '../../utils';
import ContentHeader from '../../components/ContentHeader';

export default function ContactInfoScreen({navigation, route}) {
  const initialValues = {
    phoneNumber: '',
    addressLine1: '',
    addressAptNo: '',
    addressCity: '',
    addressZipCode: '',
    addressState: '',
    addressForeignProvince: '',
    addressForeignPostCode: '',
    addressForeignCountry: '',
    addressForeignBool: false,
    residencyStatePrimaryAllYear: '',
  }
  const [formValues, setFormValues] = useState(initialValues);
  const [ filingId, setFilingId ] = useState('');
  const [ formId, setFormId ] = useState('');
  const isFocused = useIsFocused();

  useEffect(() => {
      const newFilingId = handleFilingIdParam(navigation, route, setFilingId);
      const cleanFormId = handleFormIdParam(route, setFormValues, setFormId, initialValues, newFilingId, isFocused, [], 'Personal Info'); 
  }, [isFocused]);

  useEffect(() => {
    setFormValues((prevFormValues) => ({
      ...prevFormValues, 
      phoneNumber: formatPhoneNumber(prevFormValues.phoneNumber)
    }));
  }, [formValues.phoneNumber]);

  const formatPhoneNumber = (value) => {
    // Strip all non-numeric characters
    const digits = value.replace(/\D/g, '');
  
    // Break the string into parts based on US phone number format
    const areaCode = digits.slice(0, 3);
    const middle = digits.slice(3, 6);
    const last = digits.slice(6, 10);
  
    // Format the parts and combine them
    if (digits.length === 0) {
      return '';
    } else if (digits.length <= 3) {
      return `(${areaCode}`;
    } else if (digits.length <= 6) {
      return `(${areaCode}) ${middle}`;
    } else {
      return `(${areaCode}) ${middle}-${last}`;
    }
  };

  const phoneNumberChanged = (text, setFieldValue) => {
    setFieldValue('phoneNumber', formatPhoneNumber(text));
  }

  const continuePressed = (formValues, setSubmitting, setValues) => {
    const processedValues = {
      ...formValues,
      phoneNumber: formValues.phoneNumber.replace(/\D/g, ''),
    };
    let cleanFormValues = replaceEmptyWithNull(structuredClone(processedValues), ['residencyStatePrimaryAllYear']);
    let nextScreen = (processedValues['residencyStatePrimaryAllYear'] === false) ? 'residency' : 'dependents';
    editFormValues(setSubmitting, setValues, filingId, formId, 'Personal Info', cleanFormValues, initialValues, navigation, nextScreen, []);

  };
  const backPressed = () => {
    navigateFiling(navigation, 'personalInfo', { filingId: filingId });
  };
  return (
    <Formik 
      initialValues={formValues} 
      enableReinitialize={true}
      onSubmit={(values, { setSubmitting, setValues }) => {continuePressed(values, setSubmitting, setValues)}}>
     {({ handleChange, handleBlur, handleSubmit, values, setValues, isSubmitting }) => (

      <ContentContainer nextPress={handleSubmit} nextDisabled={isSubmitting} backPress={backPressed} backDisabled={false}>
        <ContentHeader icon={"mail"} headerText={"What's your current address?"} />

        <View style={globalStyle.fieldRow}>
          <TextField name="addressLine1" fieldName="Home Address" placeholder="" fieldStyle={{'flex': 4}} />
          <TextField name="addressAptNo" fieldName="Apt. no." placeholder="" fieldStyle={{'flex': 2}} />
        </View>

        {values['addressForeignBool'] ?
          <View style={globalStyle.fieldRow}>
            <TextField name="addressCity" fieldName="City" placeholder="" fieldStyle={{'flex': 4, 'minWidth': 150}}  />
            <TextField name="addressForeignProvince" fieldName="Province" placeholder="" fieldStyle={{'flex': 4, 'minWidth': 150}} />
            <TextField name="addressForeignPostCode" fieldName="Post Code" placeholder="" fieldStyle={{'flex': 4, 'minWidth': 150}} />
          </View> :
          <View style={globalStyle.fieldRow}>
            <TextField name="addressCity" fieldName="City" placeholder="" fieldStyle={{'flex': 4, 'minWidth': 150}}  />
            <TextField name="addressZipCode" fieldName="Zip Code" placeholder="" fieldStyle={{'flex': 4, 'minWidth': 150}} />
            <DropdownField name="addressState" fieldName="State" placeholder="Choose" items={residencyStates} fieldStyle={{'flex': 4, 'minWidth': 150}} />
          </View>}

        {values['addressForeignBool'] ?
          <View style={globalStyle.fieldRow}>
            <DropdownField name="addressForeignCountry" fieldName="Country" placeholder="Choose" items={countries} width={200} />
          </View> :
          <TouchableOpacity></TouchableOpacity>}

        <View style={globalStyle.fieldRow}>
          <TextField name="phoneNumber" fieldName="Phone Number" placeholder="(___) ___-____" width={200} keyboardType='numeric' onChangeText={phoneNumberChanged}/>
        </View>

        <View style={globalStyle.fieldRow}>
          <BooleanField name="addressForeignBool" fieldName="Is this a foreign address?" />
        </View>
        { !values['addressForeignBool'] && values['addressState'] !== '' ?
          <View style={globalStyle.fieldRow}>
            <BooleanField name="residencyStatePrimaryAllYear" fieldName={"Did you live in " + values['addressState'] + " for at least 6 months in 2021?"} />
          </View>:
          <StyledText style={{}}> </StyledText>
        }

      </ContentContainer>
     )}
     </Formik>
   )
  };