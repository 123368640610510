import React, {useCallback} from 'react'
import { StyleSheet, Image, TouchableOpacity, View  } from 'react-native';
import {useDropzone} from 'react-dropzone'
import StyledText from '../components/StyledText';


export default function UploadFileWeb({ addFile, acceptedFileTypes } : { addFile:Function, acceptedFileTypes:object}) {

  const onDrop = useCallback(acceptedFiles => {
    if (acceptedFiles.length > 0) {
      acceptedFiles.forEach((file: File) => {
        addFile(file);
      });
    }
  }, [])

  const { acceptedFiles, fileRejections, isDragActive, isFocused, isDragAccept, isDragReject, getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: acceptedFileTypes
  });

  return (
    <TouchableOpacity style={styles.uploadContainer}>
      <View style={styles.baseStyle} {...getRootProps()} >
        <input {...getInputProps()} />
        <Image source={require('../assets/icons/upload_grey.png')} style={{ width: 88, height: 88 }} />
        <StyledText style={{fontWeight: 'bold'}}>Drop or select file</StyledText>
      </View>
    </TouchableOpacity>
  )
}

const styles = StyleSheet.create({
  uploadContainer: {
    width: '100%',
    maxWidth: 700,
    height: 260,
  },

  baseStyle: {
    width: '100%',
    height: '100%',
    alignItems: 'center',
    justifyContent: 'center',
    padding: 16,
    borderWidth: 2,
    borderRadius: 8,
    borderColor: '#eee',
    borderStyle: 'dashed',
    backgroundColor: '#fafafa',
  }

});