import { StyleSheet, Button, TouchableOpacity, View  } from 'react-native';
import { useIsFocused } from '@react-navigation/native'
import React, { useState, useEffect } from 'react';
import axiosInstance from '../../../axiosInstance';
import ContentContainer from '../../../components/ContentContainer';
import StyledText from '../../../components/StyledText';
import StyledButton from '../../../components/StyledButton';
import {globalStyle} from '../../../style';
import { MaterialIcons } from '@expo/vector-icons'; 
import { handleFilingIdParam, navigateFiling, pullAndSetFormList, deleteFormAndResetFormList  } from '../../../utils';

export default function W2Screen({navigation, route}) {
  // declare field state variables
  const [ W2s, setW2s ] = useState([])
  const [ filingId, setFilingId ] = useState('');
  const isFocused = useIsFocused()

  // re-render screen every time its opened
  useEffect(() => {
    if (isFocused) {
      const newFilingId = handleFilingIdParam(navigation, route, setFilingId);
      const formCount = pullAndSetFormList('W2', newFilingId, setW2s);
    }
  } , [isFocused]);

  const deleteW2 = (formId) => {
    deleteFormAndResetFormList(formId, 'W2', filingId, setW2s);
  };

  const continuePressed = () => {
    navigateFiling(navigation, 'incomeSources', { filingId: filingId });
  };
  const backPressed = () => {
    navigateFiling(navigation, 'incomeSources', { filingId: filingId });
  };

  return (
    <ContentContainer nextPress={continuePressed} nextDisabled={false} backPress={backPressed}>
      <StyledText style={globalStyle.screenHeaderText}>Share details on any jobs you had</StyledText>
      <StyledText style={globalStyle.screenSubHeaderText}>Your employer(s) should have sent you a W2 form shortly after the year's end</StyledText>
      <View style={globalStyle.fieldRow}>
        <StyledText style={{}}>{W2s.length == 0 ? 'No jobs added so far' : ''}</StyledText>
      </View>
      {// create a row for each dependent in the state
        W2s.map((W2, index) => (
          <View style={globalStyle.fieldRow} key={index}>
            <View>
              <StyledText style={[globalStyle.fieldNameStyle, {fontWeight: 'bold', paddingRight:10}]}>{W2.employerName}</StyledText>
            </View>
            
            <TouchableOpacity onPress={() => navigateFiling(navigation, 'addW2', {'filingId': filingId, formId: W2.id}) }>
              <MaterialIcons name="edit" size={24} color="black" />
            </TouchableOpacity>
            <TouchableOpacity onPress={()=>deleteW2(W2.id)}>
              <MaterialIcons name="delete-outline" size={24} color="black" />
            </TouchableOpacity>
          </View>
        ))
      }
      {
        <View style={globalStyle.fieldRow}>
          <StyledButton title='Add a W2 form' onPress={() => navigateFiling(navigation, 'addW2', {'filingId': filingId, formId: null}) } />
        </View>
      }

    </ContentContainer>
  );
}