import { StyleSheet, Button, TouchableOpacity, View, ActivityIndicator  } from 'react-native';
import React, { useState, useEffect } from 'react';
import { useFocusEffect, useIsFocused } from '@react-navigation/native'
import { Formik } from 'formik';
import ContentContainer from '../../../components/ContentContainer';
import StyledText from '../../../components/StyledText';
import StyledButton from '../../../components/StyledButton';
import TextFieldFormik from '../../../components/TextFieldFormik';
import DollarFieldFormik from '../../../components/DollarFieldFormik';
import DropdownField from '../../../components/DropdownFieldFormik';
import BooleanCheckBox from '../../../components/BooleanFieldFormik';
import axiosInstance from '../../../axiosInstance';
import UploadFileWeb from '../../../components/UploadFileWeb';
import { MaterialIcons } from '@expo/vector-icons'; 
import {globalStyle} from '../../../style';
import { replaceNullWithEmpty, replaceEmptyWithNull } from '../../../utils/index'; 
import { handleFilingIdParam, prettifyNumericFields, unPrettifyNumericFields, navigateFiling, handleFormIdParam } from '../../../utils';
import { residencyStates, w2Box12 } from '../../../assets/constants';
import ContentHeader from '../../../components/ContentHeader';

export default function AddW2Screen({route, navigation}) {
  const initialValues = {
    'employerEin': '', 
    'employerName': '',
    'employerAddress': '',
    'employerCity': '',
    'employerState': '',
    'employerZipCode': '',

    'wages': '', 
    'federalIncomeTax': '', 
    'socialSecurityWages': '', 
    'socialSecurityTax': '', 
    'medicareWages': '', 
    'medicareTax': '', 
    'socialSecurityTips': '',
    'allocatedTips': '',
    'dependentCareBenefits': '',
    'nonQualifiedPlans': '',

    '12Description1': '',
    '12Amount1': '',
    '12Description2': '',
    '12Amount2': '',
    '12Description3': '',
    '12Amount3': '',
    '12Description4': '',
    '12Amount4': '',

    'retirementPlan': '',
    'statutoryEmployee': '',
    'thirdPartySickPay': '',

    'state1': '',
    'employerStateId': '',
    'stateWages': '', 
    'stateIncomeTax': '',
    'localWages': '',
    'localIncomeTax': '',
    'localityName': ''
  }
  // declare w2 form fields
  const [ filingId, setFilingId ] = useState('');
  const [formId, setFormId] = useState('');
  const [formValues, setFormValues] = useState(initialValues);
  const [box12Count, setBox12Count] = useState(2);
  const [displayState, setDisplayState] = useState('upload'); // takes: upload, parsingUpload, uploadComplete, enterManually
  const [fileToUpload, setFileToUpload] = useState();
  const isFocused = useIsFocused();
  const numericFields = ['wages', 'federalIncomeTax', 'socialSecurityWages', 'socialSecurityTax', 'medicareWages', 'medicareTax', 
    'socialSecurityTips', 'allocatedTips', 'dependentCareBenefits', 'nonQualifiedPlans', '12Amount1', '12Amount2', '12Amount3', '12Amount4', 
    'stateWages', 'stateIncomeTax', 'localWages', 'localIncomeTax'];

  
  // if we are editing an existing dependent then we would have received the dependent if as a parameter 
  // we use this to load the existing dependent details upon render and focus
  useEffect(() => {
    const newFilingId = handleFilingIdParam(navigation, route, setFilingId);
    const cleanFormId = handleFormIdParam(route, setFormValues, setFormId, initialValues, newFilingId, isFocused, numericFields); 
    if (isFocused && cleanFormId === null) {
      setFormValues(initialValues);
      setDisplayState('upload');
    } else if (isFocused && cleanFormId !== null) {
      setDisplayState('enterManually');
      pullW2(newFilingId, cleanFormId);
    }
  }, [isFocused]);

  const pullW2 = (newFilingId, formId) => {
    var jsonData = JSON.stringify({'userFormId': formId, 'filingId': newFilingId});
    axiosInstance
      .post('loadFormEditor', jsonData, { headers: {'Content-Type': 'application/json' }, withCredentials: true})
      .then(function (response) {
        console.log(response['data']);
        const formattedResponseData = prettifyNumericFields(replaceNullWithEmpty(response['data']), numericFields);
        setFormValues(prevState => {
          let updatedState = { ...prevState };
          for (const key in formattedResponseData) {
            if (formattedResponseData.hasOwnProperty(key) && prevState.hasOwnProperty(key)) {
              updatedState[key] = formattedResponseData[key];
            }
          }
          return updatedState;
        });
        let count = 0; 
        for (const key in response['data']) { if (key.includes("12Amount") && response['data'][key] !== '') { count++; } }
        setBox12Count(count);
      })
      .catch(function (error) {console.log(error);});
  };

  const removeBox12 = (index, values) => {
    let newValues = { ...values };
    for (let i = index; i <= 3; i++) {
      newValues[`12Description${i}`] = values[`12Description${i+1}`];
      newValues[`12Amount${i}`] = values[`12Amount${i+1}`];
    }
    newValues['12Description4'] = '';
    newValues['12Amount4'] = '';
    setFormValues(newValues);
    setBox12Count(box12Count-1);
  };
  

  const continuePressed = (formValues, setSubmitting, setValues) => {
    let answers = replaceEmptyWithNull(unPrettifyNumericFields(structuredClone(formValues), numericFields));
    answers['filingId'] = filingId;
    let apiURL = '/addUserForm';
    // if we are editing an existing W2, then the query needs to change
    if (formId !== null && formId !== '') {
      apiURL ='/editUserForm';
      answers['userFormId'] = formId;
    }
    else {
      answers['userFormName'] = 'W2';
    }
    console.log(answers);
    let jsonData = JSON.stringify(answers);
    axiosInstance
    .post(apiURL, jsonData, { headers: {'Content-Type': 'application/json'}, withCredentials: true})
    .then((response) => {
      setValues(initialValues);
      setSubmitting(false);
      navigateFiling(navigation, 'w2', { filingId: filingId });
    })
    .catch(error => {console.log(error);})  
  };

  const backPressed = () => {
    navigateFiling(navigation, 'w2', { filingId: filingId });
  };

  return (
    <Formik 
    initialValues={formValues} 
    enableReinitialize={true}
    onSubmit={(values, { setSubmitting, setValues }) => {continuePressed(values, setSubmitting, setValues)}}>
    {({ handleChange, handleBlur, handleSubmit, values, setValues, isSubmitting }) => (
      <ContentContainer nextPress={handleSubmit} nextDisabled={isSubmitting} backPress={backPressed} backDisabled={false}>
        <ContentHeader icon={"home"} headerText={"Enter details from your W2"}  subHeaderText='Your employer should have provided this form to you' />

        <View style={globalStyle.sectionHeaderContainer}>
          <StyledText style={globalStyle.sectionHeaderText}>Employer details</StyledText>
        </View>
        {/* employer details */}
        <View style={globalStyle.fieldRow}>
          <TextFieldFormik name="employerEin" fieldName="Box B - Employer Identification Number" fieldStyle={{'flex': 1}} /> 
          <TextFieldFormik name="employerName" fieldName="Box C - Employer Name" placeholder="" fieldStyle={{'flex': 1}}  /> 
        </View>
        <View style={globalStyle.fieldRow}>
          <TextFieldFormik name="employerAddress" fieldName="Box C - Employer address" fieldStyle={{'flex': 1}} /> 
          <TextFieldFormik name="employerCity" fieldName="Box C - Employer City" fieldStyle={{'flex': 1}} /> 
        </View>
        <View style={globalStyle.fieldRow}>
          <DropdownField name="employerState" fieldName="Box C - Employer State" placeholder="Choose" items={residencyStates} fieldStyle={{'flex': 1}} />
          <TextFieldFormik name="employerZipCode" fieldName="Box C - Employer Zip Code" fieldStyle={{'flex': 1}} /> 
        </View>
        {/* federal details */}
        <View style={globalStyle.sectionHeaderContainer}>
          <StyledText style={globalStyle.sectionHeaderText}>Federal details</StyledText>
        </View>

        <View style={globalStyle.fieldRow}>
          <DollarFieldFormik name="wages" fieldName="Box 1 - Wages, tips, other compensation" fieldStyle={{'flex': 1}} /> 
          <DollarFieldFormik name="federalIncomeTax" fieldName="Box 2 - Federal income tax witheld" fieldStyle={{'flex': 1}} /> 
        </View>
        <View style={globalStyle.fieldRow}>
          <DollarFieldFormik name="socialSecurityWages" fieldName="Box 3 - Social security wages" fieldStyle={{'flex': 1}} /> 
          <DollarFieldFormik name="socialSecurityTax" fieldName="Box 4 - Social security tax withheld" fieldStyle={{'flex': 1}}/> 
        </View>
        <View style={globalStyle.fieldRow}>
          <DollarFieldFormik name="medicareWages" fieldName="Box 5 - Medicare income" placeholder="" fieldStyle={{'flex': 1}}/> 
          <DollarFieldFormik name="medicareTax" fieldName="Box 6 - Medicare tax withheld" placeholder="" fieldStyle={{'flex': 1}} /> 
        </View>
        <View style={globalStyle.fieldRow}>
          <DollarFieldFormik name="socialSecurityTips" fieldName="Box 7 - Social Security Tips" placeholder="" fieldStyle={{'flex': 1}} /> 
          <DollarFieldFormik name="allocatedTips" fieldName="Box 8 - Allocated Tips" placeholder="" fieldStyle={{'flex': 1}} /> 
        </View>
        <View style={globalStyle.fieldRow}>
          <DollarFieldFormik name="dependentCareBenefits" fieldName="Box 10 - Dependent Care Benefits" placeholder="" fieldStyle={{'flex': 1}} /> 
          <DollarFieldFormik name="nonQualifiedPlans" fieldName="Box 11 - Non-Qualified Plans" placeholder='' fieldStyle={{'flex': 1}} />
        </View>
        {
          Array.from({length: box12Count}, (v, i) => {
            return (
              <View style={globalStyle.fieldRow} key={i}>
                <DropdownField name={`12Description${i+1}`} fieldName={`Box 12 - Code ${i+1}`} placeholder="" items={w2Box12} fieldStyle={{'flex': 1}} />
                <DollarFieldFormik name={`12Amount${i+1}`} fieldName={`Box 12 - Amount ${i+1}`} placeholder="" fieldStyle={{'flex': 1}} /> 
                <TouchableOpacity onPress={()=>removeBox12(i+1, values)} style={{bottom: 0}}>
                  <MaterialIcons name="delete" size={18} color="black" />
                </TouchableOpacity>
              </View>
            )
          })
        }
        {
          box12Count < 5 ? 
          <View style={globalStyle.fieldRow}>
            <StyledButton title='Add Box 12' onPress={()=>setBox12Count(box12Count+1)} style={{width: 200}}/>
          </View> : null
        }

        <View style={globalStyle.fieldRow}>
          <BooleanCheckBox name="statutoryEmployee" fieldName="Box 13 - Statutory Employee" />
        </View>
        <View style={globalStyle.fieldRow}>
          <BooleanCheckBox name="retirementPlan" fieldName="Box 13 - Retirement Plan" />
        </View>
        <View style={globalStyle.fieldRow}>
          <BooleanCheckBox name="thirdPartySickPay" fieldName="Box 13 - Third Party Sick Pay" />
        </View>

        {/* state details */}
        <View style={globalStyle.sectionHeaderContainer}>
          <StyledText style={globalStyle.sectionHeaderText}>State details</StyledText>
        </View>
        
        <View style={globalStyle.fieldRow}>
          <DropdownField name="state1" fieldName="Box 15 - State" placeholder="Choose" items={residencyStates} fieldStyle={{'flex': 1}} />
          <TextFieldFormik name="employerStateId" fieldName="Box 16 - Employer State ID Number" placeholder="" fieldStyle={{'flex': 1}} /> 
        </View>
        <View style={globalStyle.fieldRow}>
          <DollarFieldFormik name="stateWages" fieldName="Box 16 - State wages, tips, etc" placeholder="" fieldStyle={{'flex': 1}} /> 
          <DollarFieldFormik name="stateIncomeTax" fieldName="Box 17 - State income tax" placeholder="" fieldStyle={{'flex': 1}} /> 
        </View>
        <View style={globalStyle.fieldRow}>
          <DollarFieldFormik name="localWages" fieldName="Box 18 - Local wages" placeholder="" fieldStyle={{'flex': 1}} /> 
          <DollarFieldFormik name="localIncomeTax" fieldName="Box 19 - Local income tax" placeholder="" fieldStyle={{'flex': 1}} /> 
          <TextFieldFormik name="localityName" fieldName="Box 20 - Locality name" placeholder="" fieldStyle={{'flex': 1}} />
        </View>
      </ContentContainer>
    )}
    </Formik>
  );
}

// const parseUpload = () => {
//   if (fileToUpload !== undefined) {
//     setDisplayState('parsingUpload');
//     setFileToUpload(undefined);
//     let formData = new FormData();
//     formData.append('files[]', fileToUpload);

//     axiosInstance
//       .post('uploadW2', {file: fileToUpload, 'filingId': filingId}, { headers: { 'Content-Type': 'multipart/form-data' }, withCredentials: true})
//       .then((response) => {
//         setDisplayState('uploadComplete');
//         setFormValues(replaceNullWithEmpty(response['data']));
//       })
//       .catch(error => {console.log(error);})      
//   }
// }

// var subHeader;
// var displayComponent;
// if (displayState == 'upload') {
//   subHeader = 'Upload your file using the box below'
//   displayComponent =       
//   <View>
//     <View style={globalStyle.fieldRow}>
//       <UploadFileWeb fileToUpload={fileToUpload} setFileToUpload={setFileToUpload} parseUpload={parseUpload}/>
//     </View>
//     <View>
//       <StyledText>or</StyledText>
//     </View>
//     <StyledButton title="Enter fields manually" onPress={()=>setDisplayState('enterManually')} style={{width: 200}}/> 
//   </View>
// }
// else if (displayState == 'parsingUpload') {
//   subHeader = ''
//   displayComponent =
//   <View>
//     <ActivityIndicator size="large" color="#0000ff"/>
//     <View style={globalStyle.fieldRow}>
//       <StyledText>We're processing your upload, this should take roughly 1 minute...</StyledText>
//     </View>
//   </View>
// }
// else if (displayState == 'uploadComplete') {
//   subHeader = "We extracted what we could, but please double check the numbers" 
//   displayComponent = <View>{textForm}</View>
// } else {
//   subHeader = 'Enter fields manually'
//   displayComponent = <View>{textForm}</View>
// }