import React, { useState, useEffect } from 'react';
import { Button, TouchableOpacity, View, Text } from 'react-native';
import { useIsFocused } from '@react-navigation/native';
import { Formik } from 'formik';
import {globalStyle} from '../../../style';
import axiosInstance from '../../../axiosInstance';
import ContentContainer from '../../../components/ContentContainer';
import StyledText from '../../../components/StyledText';
import StyledButton from '../../../components/StyledButton';
import { MaterialIcons } from '@expo/vector-icons'; 
import { handleFilingIdParam, navigateFiling } from '../../../utils';

 export default function ForeignAccountSummaryScreen({navigation, route}) {
  const [filingId, setFilingId] = useState('');
  const [foreignAccounts, setForeignAccounts] = useState([]);
  const [f8939Required, setF8939Required] = useState(false);
  const isFocused = useIsFocused();
  const [ miscFormId, setMiscFormId ] = useState('');

  // re-render screen every time its opened
  useEffect(() => {
    if (isFocused) {
      const newFilingId = handleFilingIdParam(navigation, route, setFilingId);
      pullForeignAccounts(newFilingId)
    }
  } , [isFocused]);

  const pullForeignAccounts = (newFilingId) => {
    axiosInstance
      .post('loadForeignAccountSummary', JSON.stringify({'filingId': newFilingId}), { headers: {'Content-Type': 'application/json' }, withCredentials: true})
      .then(function (response) {
        setF8939Required(response['data']['f8939Required']);
        setForeignAccounts(response['data']['accounts']);
      })
      .catch(function (error) {console.log(error)});
  };

  const deleteForeignAccount = (userFormId) => {
    var jsonData = JSON.stringify({'userFormId': userFormId, 'filingId': filingId});
    axiosInstance
      .post('deleteUserForm', jsonData, { headers: {'Content-Type': 'application/json' }, withCredentials: true})
      .then(function (response) {pullForeignAccounts(filingId);})
      .catch(function (error) {console.log(error.message);})
  };

  const continuePressed = () => {
    navigateFiling(navigation, 'incomeSources', { filingId: filingId });
  };
  const backPressed = () => {
    navigateFiling(navigation, 'incomeSources', { filingId: filingId });
  };
  return (
    <ContentContainer nextPress={continuePressed} nextDisabled={false} backPress={backPressed}>
      <StyledText style={globalStyle.screenHeaderText}>Your foreign accounts</StyledText>
      {f8939Required === true ? 
        <StyledText style={globalStyle.screenSubHeaderText}>Your previous responses indicate that you need to file a Form 8939. Don't worry, we'll get the required information in the next steps.</StyledText>: 
        <StyledText style={globalStyle.screenSubHeaderText}>Enter any foreign accounts</StyledText>}
      
      {// create a row for each dependent in the state
        foreignAccounts.map((form, index) => (
          <View style={globalStyle.fieldRow} key={index}>
            <View>
              <StyledText style={[globalStyle.fieldNameStyle, {fontWeight: 'bold', paddingRight:10}]}>{form.payerName}</StyledText>
            </View>
            
            <TouchableOpacity onPress={() => navigateFiling(navigation, 'addForeignAccount', {'filingId': filingId, formId: form.id}) }>
              <MaterialIcons name="edit" size={24} color="black" />
            </TouchableOpacity>
            <TouchableOpacity onPress={()=>deleteForeignAccount(form.id)}>
              <MaterialIcons name="delete-outline" size={24} color="black" />
            </TouchableOpacity>
          </View>
        ))
      }
      {
        <View style={globalStyle.fieldRow}>
          <StyledButton title='Add a Foreign Account' onPress={()=>navigateFiling(navigation, 'addForeignAccount', {'filingId': filingId, formId: null}) } />
        </View>
      }
    </ContentContainer>
  )
 };