import { StyleSheet, Button, TouchableOpacity, View, TextInput  } from 'react-native';
import { useIsFocused } from '@react-navigation/native'
import React, { useState, useEffect } from 'react';
import axiosInstance from '../../../axiosInstance';
import ContentContainer from '../../../components/ContentContainer';
import ContentHeader from '../../../components/ContentHeader';
import StyledText from '../../../components/StyledText';
import {globalStyle, globalColors} from '../../../style';
import { handleFilingIdParam, navigateFiling } from '../../../utils';
import LottieAnimation from '../../../components/LottieAnimation';
import standardAnimation from '../../../assets/animations/standard_ded.json'
import itemizedAnimation from '../../../assets/animations/itemized_ded.json'
import standardBWAnimation from '../../../assets/animations/standard_bw.json'
import itemizedBWAnimation from '../../../assets/animations/itemized_bw.json'

export default function DeductionIntroScreen({navigation, route}) {
  // declare field state variables
  const [ deduction, setDeductions ] = useState('');
  const [filingId, setFilingId] = useState('');
  const [ miscFormId, setMiscFormId ] = useState('');
  const isFocused = useIsFocused()

  // re-render screen every time its opened
  useEffect(() => {
    if (isFocused) {
      const newFilingId = handleFilingIdParam(navigation, route, setFilingId);
      pullDeductionSelection(newFilingId)
    }
  } , [isFocused]);

  const pullDeductionSelection = (newFilingId) => {
    axiosInstance
      .post('loadForms', JSON.stringify({'filingId': newFilingId, 'formName': 'Miscellaneous', 'formFields':['deduction_type']}), { headers: {'Content-Type': 'application/json' }, withCredentials: true})
      .then(function (response) {
        if (response['data'].length > 0) {
          setMiscFormId(response['data'][0]['id']);
          setDeductions(response['data'][0]['deductionType']);
        }
      })
      .catch(function (error) {console.log(error)});
  };

  const standardClicked = () => {
    if (deduction === 'standard') {setDeductions('');} 
    else {setDeductions('standard');}
  }
  const itemizedClicked = () => {
    if (deduction === 'itemized') {setDeductions('');} 
    else {setDeductions('itemized');}
  }

  const continuePressed = () => {
      let answers = {'deductionType': deduction};
      answers['filingId'] = filingId;
      let apiURL = '/addUserForm';
      if (miscFormId !== '') {
        answers['userFormId'] = miscFormId;
        apiURL ='/editUserForm';
      } else {
        answers['userFormName'] = 'Miscellaneous';
      }
      let jsonData = JSON.stringify(answers);
      axiosInstance.post(apiURL, jsonData, { headers: {'Content-Type': 'application/json'}, withCredentials: true})
      .then((response) => {
        if (deduction === 'standard') {
          navigateFiling(navigation, 'otherSituations', { filingId: filingId });
        }
      })
      .catch(error => {console.log(error);})  
  };
  const backPressed = () => {
    navigateFiling(navigation, 'incomeSources', { filingId: filingId });
  };

  return (
    <ContentContainer nextPress={continuePressed} nextDisabled={false} backPress={backPressed}>
      <ContentHeader icon={"trending-down"} headerText="Let's try reduce your taxable income" subHeaderText="You can either take standard or itemized deductions to your taxable income."/>
      
      <View style={styles.selectionRow}>
        <TouchableOpacity  style={[styles.button, deduction == 'standard' && styles.selectedButton]} onPress={() => standardClicked()}>
          <StyledText style={{...styles.buttonText, ...deduction == 'standard' ? styles.selectedButtonText:null}}>Standard</StyledText>
          <View style={styles.animationContainer}>
            {deduction == 'standard' ? 
              <LottieAnimation key="standard" source={standardAnimation} loop={true} autoplay={true} height={250} /> :
              <LottieAnimation key="standardBW" source={standardBWAnimation} loop={false} autoplay={false} height={250} />
            }
          </View>

        </TouchableOpacity>

        <TouchableOpacity  style={[styles.button, deduction == 'itemized' && styles.selectedButton]}   onPress={() => itemizedClicked()}>
          <StyledText style={{...styles.buttonText, ...deduction == 'itemized' ? styles.selectedButtonText:null}}>Itemized</StyledText>
          <View style={styles.animationContainer}>
            {deduction == 'itemized' ? 
              <LottieAnimation key="itemized" source={itemizedAnimation} loop={true} autoplay={true} height={250} /> :
              <LottieAnimation key="itemizedBW" source={itemizedBWAnimation} loop={false} autoplay={false} height={250} />
            }
          </View>
        </TouchableOpacity>
      </View>
    </ContentContainer>
  );
}

const styles = StyleSheet.create({
  selectionRow: {
    marginVertical: 28,
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    columnGap: 60
  },
  button: {
    width: 300,
    height: 380,
    paddingVertical: 20,
    paddingHorizontal: 10,
    borderRadius: 12,
    alignItems: 'center',
    borderWidth: 2,
    borderColor: globalColors.grey, 
    shadowColor: '#000', // Shadow for 3D effect
    shadowOffset: { width: 0, height: 2 },
    shadowOpacity: 0.25,
    shadowRadius: 3.84,
    elevation: 5,
  },
  selectedButton: {
    // backgroundColor: '#e8f5e9', // Light green background
    borderColor: globalColors.primary, // Darker green for the border
    borderWidth: 2, // Slightly thicker border to make it more noticeable
    shadowColor: '#66bb6a', // Subtle green shadow
  },
  buttonText: {
    fontWeight: 'bold',
    fontSize: 24
  },
  selectedButtonText: {
    color: globalColors.primary,
  },
  animationContainer: {
    paddingTop: 20
  }
});
