import React, { useEffect, useRef, useState } from 'react';
import { Platform } from 'react-native';
import LottieView from 'lottie-react-native';
import ReactLottie from 'react-lottie';

interface LottieAnimationProps {
  source: any;  // Ideally, specify a more precise type for your Lottie animation data
  loop: boolean;
  autoplay: boolean;
  height: number;
  width: number;
  pauseFrame?: number;  // Make pauseFrame optional
}

const LottieAnimation: React.FC<LottieAnimationProps> = ({
  source,
  loop,
  autoplay,
  height,
  width,
  pauseFrame,
}) => {
  const animationRef = useRef<any>(null); // Type the ref accordingly
  const [animationState, setAnimationState] = useState({
    isStopped: !autoplay,
    isPaused: false,
  });

  // Helper function to calculate progress from frame number
  const frameToProgress = (frame, totalFrames) => frame / totalFrames;

  useEffect(() => {
    // Apply pause only if pauseFrame is provided
    if (pauseFrame !== undefined && animationRef.current) {
      if (Platform.OS === 'web') {
        // Access the Lottie player directly (web-specific)
        setTimeout(() => {
          animationRef.current.anim.goToAndStop(pauseFrame, true);
        }, 0);
      } else {
        // Use progress to pause at a specific frame for native
        const progress = frameToProgress(pauseFrame, source.op); // Assuming source.op is total frames
        animationRef.current.play(0, pauseFrame);  // Play up to pauseFrame
        setAnimationState({ ...animationState, isPaused: true });
      }
    }
  }, [pauseFrame]);

  const defaultOptions = {
    loop,
    autoplay,
    animationData: source,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice'
    }
  };

  if (Platform.OS === 'web') {
    return (
      <ReactLottie
        ref={animationRef}
        options={defaultOptions}
        height={height}
        width={width}
        isStopped={animationState.isStopped}
        isPaused={animationState.isPaused}
      />
    );
  } else {
    return (
      <LottieView
        ref={animationRef}
        source={source}
        autoPlay={autoplay}
        loop={loop}
        style={{ width, height }}
        progress={pauseFrame ? frameToProgress(pauseFrame, source.op) : undefined}
      />
    );
  }
};

export default LottieAnimation;
