export const countries = [
    {label:'---', value:''},
    {label:'Afghanistan', value: 'Afghanistan'},
    {label:'Albania', value: 'Albania'},
    {label:'Algeria', value: 'Algeria'},
    {label:'Andorra', value: 'Andorra'},
    {label:'Angola', value: 'Angola'},
    {label:'Antigua and Barbuda', value: 'Antigua and Barbuda'},
    {label:'Argentina', value: 'Argentina'},
    {label:'Armenia', value: 'Armenia'},
    {label:'Australia', value: 'Australia'},
    {label:'Austria', value: 'Austria'},
    {label:'Azerbaijan', value: 'Azerbaijan'},
    {label:'Bahamas', value: 'Bahamas'},
    {label:'Bahrain', value: 'Bahrain'},
    {label:'Bangladesh', value: 'Bangladesh'},
    {label:'Barbados', value: 'Barbados'},
    {label:'Belarus', value: 'Belarus'},
    {label:'Belgium', value: 'Belgium'},
    {label:'Belize', value: 'Belize'},
    {label:'Benin', value: 'Benin'},
    {label:'Bhutan', value: 'Bhutan'},
    {label:'Bolivia', value: 'Bolivia'},
    {label:'Bosnia and Herzegovina', value: 'Bosnia and Herzegovina'},
    {label:'Botswana', value: 'Botswana'},
    {label:'Brazil', value: 'Brazil'},
    {label:'Brunei', value: 'Brunei'},
    {label:'Bulgaria', value: 'Bulgaria'},
    {label:'Burkina Faso', value: 'Burkina Faso'},
    {label:'Burundi', value: 'Burundi'},
    {label:"Côte d'Ivoire", value: "Côte d'Ivoire"},
    {label:'Cabo Verde', value: 'Cabo Verde'},
    {label:'Cambodia', value: 'Cambodia'},
    {label:'Cameroon', value: 'Cameroon'},
    {label:'Canada', value: 'Canada'},
    {label:'Central African Republic', value: 'Central African Republic'},
    {label:'Chad', value: 'Chad'},
    {label:'Chile', value: 'Chile'},
    {label:'China', value: 'China'},
    {label:'Colombia', value: 'Colombia'},
    {label:'Comoros', value: 'Comoros'},
    {label:'Congo (Congo-Brazzaville)', value: 'Congo (Congo-Brazzaville)'},
    {label:'Costa Rica', value: 'Costa Rica'},
    {label:'Croatia', value: 'Croatia'},
    {label:'Cuba', value: 'Cuba'},
    {label:'Cyprus', value: 'Cyprus'},
    {label:'Czechia (Czech Republic)', value: 'Czechia (Czech Republic)'},
    {label:'Democratic Republic of the Congo', value: 'Democratic Republic of the Congo'},
    {label:'Denmark', value: 'Denmark'},
    {label:'Djibouti', value: 'Djibouti'},
    {label:'Dominica', value: 'Dominica'},
    {label:'Dominican Republic', value: 'Dominican Republic'},
    {label:'Ecuador', value: 'Ecuador'},
    {label:'Egypt', value: 'Egypt'},
    {label:'El Salvador', value: 'El Salvador'},
    {label:'Equatorial Guinea', value: 'Equatorial Guinea'},
    {label:'Eritrea', value: 'Eritrea'},
    {label:'Estonia', value: 'Estonia'},
    {label:'Eswatini (fmr. "Swaziland")', value: 'Eswatini (fmr. "Swaziland")'},
    {label:'Ethiopia', value: 'Ethiopia'},
    {label:'Fiji', value: 'Fiji'},
    {label:'Finland', value: 'Finland'},
    {label:'France', value: 'France'},
    {label:'Gabon', value: 'Gabon'},
    {label:'Gambia', value: 'Gambia'},
    {label:'Georgia', value: 'Georgia'},
    {label:'Germany', value: 'Germany'},
    {label:'Ghana', value: 'Ghana'},
    {label:'Greece', value: 'Greece'},
    {label:'Grenada', value: 'Grenada'},
    {label:'Guatemala', value: 'Guatemala'},
    {label:'Guinea', value: 'Guinea'},
    {label:'Guinea-Bissau', value: 'Guinea-Bissau'},
    {label:'Guyana', value: 'Guyana'},
    {label:'Haiti', value: 'Haiti'},
    {label:'Holy See', value: 'Holy See'},
    {label:'Honduras', value: 'Honduras'},
    {label:'Hungary', value: 'Hungary'},
    {label:'Iceland', value: 'Iceland'},
    {label:'India', value: 'India'},
    {label:'Indonesia', value: 'Indonesia'},
    {label:'Iran', value: 'Iran'},
    {label:'Iraq', value: 'Iraq'},
    {label:'Ireland', value: 'Ireland'},
    {label:'Israel', value: 'Israel'},
    {label:'Italy', value: 'Italy'},
    {label:'Jamaica', value: 'Jamaica'},
    {label:'Japan', value: 'Japan'},
    {label:'Jordan', value: 'Jordan'},
    {label:'Kazakhstan', value: 'Kazakhstan'},
    {label:'Kenya', value: 'Kenya'},
    {label:'Kiribati', value: 'Kiribati'},
    {label:'Kuwait', value: 'Kuwait'},
    {label:'Kyrgyzstan', value: 'Kyrgyzstan'},
    {label:'Laos', value: 'Laos'},
    {label:'Latvia', value: 'Latvia'},
    {label:'Lebanon', value: 'Lebanon'},
    {label:'Lesotho', value: 'Lesotho'},
    {label:'Liberia', value: 'Liberia'},
    {label:'Libya', value: 'Libya'},
    {label:'Liechtenstein', value: 'Liechtenstein'},
    {label:'Lithuania', value: 'Lithuania'},
    {label:'Luxembourg', value: 'Luxembourg'},
    {label:'Madagascar', value: 'Madagascar'},
    {label:'Malawi', value: 'Malawi'},
    {label:'Malaysia', value: 'Malaysia'},
    {label:'Maldives', value: 'Maldives'},
    {label:'Mali', value: 'Mali'},
    {label:'Malta', value: 'Malta'},
    {label:'Marshall Islands', value: 'Marshall Islands'},
    {label:'Mauritania', value: 'Mauritania'},
    {label:'Mauritius', value: 'Mauritius'},
    {label:'Mexico', value: 'Mexico'},
    {label:'Micronesia', value: 'Micronesia'},
    {label:'Moldova', value: 'Moldova'},
    {label:'Monaco', value: 'Monaco'},
    {label:'Mongolia', value: 'Mongolia'},
    {label:'Montenegro', value: 'Montenegro'},
    {label:'Morocco', value: 'Morocco'},
    {label:'Mozambique', value: 'Mozambique'},
    {label:'Myanmar (formerly Burma)', value: 'Myanmar (formerly Burma)'},
    {label:'Namibia', value: 'Namibia'},
    {label:'Nauru', value: 'Nauru'},
    {label:'Nepal', value: 'Nepal'},
    {label:'Netherlands', value: 'Netherlands'},
    {label:'New Zealand', value: 'New Zealand'},
    {label:'Nicaragua', value: 'Nicaragua'},
    {label:'Niger', value: 'Niger'},
    {label:'Nigeria', value: 'Nigeria'},
    {label:'North Korea', value: 'North Korea'},
    {label:'North Macedonia', value: 'North Macedonia'},
    {label:'Norway', value: 'Norway'},
    {label:'Oman', value: 'Oman'},
    {label:'Pakistan', value: 'Pakistan'},
    {label:'Palau', value: 'Palau'},
    {label:'Palestine State', value: 'Palestine State'},
    {label:'Panama', value: 'Panama'},
    {label:'Papua New Guinea', value: 'Papua New Guinea'},
    {label:'Paraguay', value: 'Paraguay'},
    {label:'Peru', value: 'Peru'},
    {label:'Philippines', value: 'Philippines'},
    {label:'Poland', value: 'Poland'},
    {label:'Portugal', value: 'Portugal'},
    {label:'Qatar', value: 'Qatar'},
    {label:'Romania', value: 'Romania'},
    {label:'Russia', value: 'Russia'},
    {label:'Rwanda', value: 'Rwanda'},
    {label:'Saint Kitts and Nevis', value: 'Saint Kitts and Nevis'},
    {label:'Saint Lucia', value: 'Saint Lucia'},
    {label:'Saint Vincent and the Grenadines', value: 'Saint Vincent and the Grenadines'},
    {label:'Samoa', value: 'Samoa'},
    {label:'San Marino', value: 'San Marino'},
    {label:'Sao Tome and Principe', value: 'Sao Tome and Principe'},
    {label:'Saudi Arabia', value: 'Saudi Arabia'},
    {label:'Senegal', value: 'Senegal'},
    {label:'Serbia', value: 'Serbia'},
    {label:'Seychelles', value: 'Seychelles'},
    {label:'Sierra Leone', value: 'Sierra Leone'},
    {label:'Singapore', value: 'Singapore'},
    {label:'Slovakia', value: 'Slovakia'},
    {label:'Slovenia', value: 'Slovenia'},
    {label:'Solomon Islands', value: 'Solomon Islands'},
    {label:'Somalia', value: 'Somalia'},
    {label:'South Africa', value: 'South Africa'},
    {label:'South Korea', value: 'South Korea'},
    {label:'South Sudan', value: 'South Sudan'},
    {label:'Spain', value: 'Spain'},
    {label:'Sri Lanka', value: 'Sri Lanka'},
    {label:'Sudan', value: 'Sudan'},
    {label:'Suriname', value: 'Suriname'},
    {label:'Sweden', value: 'Sweden'},
    {label:'Switzerland', value: 'Switzerland'},
    {label:'Syria', value: 'Syria'},
    {label:'Tajikistan', value: 'Tajikistan'},
    {label:'Tanzania', value: 'Tanzania'},
    {label:'Thailand', value: 'Thailand'},
    {label:'Timor-Leste', value: 'Timor-Leste'},
    {label:'Togo', value: 'Togo'},
    {label:'Tonga', value: 'Tonga'},
    {label:'Trinidad and Tobago', value: 'Trinidad and Tobago'},
    {label:'Tunisia', value: 'Tunisia'},
    {label:'Turkey', value: 'Turkey'},
    {label:'Turkmenistan', value: 'Turkmenistan'},
    {label:'Tuvalu', value: 'Tuvalu'},
    {label:'Uganda', value: 'Uganda'},
    {label:'Ukraine', value: 'Ukraine'},
    {label:'United Arab Emirates', value: 'United Arab Emirates'},
    {label:'United Kingdom', value: 'United Kingdom'},
    {label:'United States of America', value: 'United States of America'},
    {label:'Uruguay', value: 'Uruguay'},
    {label:'Uzbekistan', value: 'Uzbekistan'},
    {label:'Vanuatu', value: 'Vanuatu'},
    {label:'Venezuela', value: 'Venezuela'},
    {label:'Vietnam', value: 'Vietnam'},
    {label:'Yemen', value: 'Yemen'},
    {label:'Zambia', value: 'Zambia'},
    {label:'Zimbabwe', value: 'Zimbabwe'}
]

export const residencyStates = [
    {label:'---', value:''},
    {label:'AL - Alabama', value:'AL'},
    {label:'AK - Alaska', value:'AK'},
    {label:'AZ - Arizona', value:'AZ'},
    {label:'AR - Arkansas', value:'AR'},
    {label:'CA - California', value:'CA'},
    {label:'CO - Colorado', value:'CO'},
    {label:'CT - Connecticut', value:'CT'},
    {label:'DE - Delaware', value:'DE'},
    {label:'DC - District of Columbia', value:'DC'},
    {label:'FL - Florida', value:'FL'},
    {label:'GA - Georgia', value:'GA'},
    {label:'HI - Hawaii', value:'HI'},
    {label:'ID - Idaho', value:'ID'},
    {label:'IL - Illinois', value:'IL'},
    {label:'IN - Indiana', value:'IN'},
    {label:'IA - Iowa', value:'IA'},
    {label:'KS - Kansas', value:'KS'},
    {label:'KY - Kentucky', value:'KY'},
    {label:'LA - Louisiana', value:'LA'},
    {label:'ME - Maine', value:'ME'},
    {label:'MD - Maryland', value:'MD'},
    {label:'MA - Massachusetts', value:'MA'},
    {label:'MI - Michigan', value:'MI'},
    {label:'MN - Minnesota', value:'MN'},
    {label:'MS - Mississippi', value:'MS'},
    {label:'MO - Missouri', value:'MO'},
    {label:'MT - Montana', value:'MT'},
    {label:'NE - Nebraska', value:'NE'},
    {label:'NV - Nevada', value:'NV'},
    {label:'NH - New Hampshire', value:'NH'},
    {label:'NJ - New Jersey', value:'NJ'},
    {label:'NM - New Mexico', value:'NM'},
    {label:'NY - New York', value:'NY'},
    {label:'NC - North Carolina', value:'NC'},
    {label:'ND - North Dakota', value:'ND'},
    {label:'OH - Ohio', value:'OH'},
    {label:'OK - Oklahoma', value:'OK'},
    {label:'OR - Oregon', value:'OR'},
    {label:'PA - Pennsylvania', value:'PA'},
    {label:'RI - Rhode Island', value:'RI'},
    {label:'SC - South Carolina', value:'SC'},
    {label:'SD - South Dakota', value:'SD'},
    {label:'TN - Tennessee', value:'TN'},
    {label:'TX - Texas', value:'TX'},
    {label:'UT - Utah', value:'UT'},
    {label:'VT - Vermont', value:'VT'},
    {label:'VA - Virginia', value:'VA'},
    {label:'WA - Washington', value:'WA'},
    {label:'WV - West Virginia', value:'WV'},
    {label:'WI - Wisconsin', value:'WI'},
    {label:'WY - Wyoming', value:'WY'} 
]

export const filingStatuses = [
    {label: 'Choose', value: ''},
    {label: 'Single', value: 'single'},
    {label: 'Married filing jointly', value: 'married_filing_jointly'},
    {label: 'Married filing separately', value: 'married_filing_separately'},
    {label: 'Head of household', value: 'head_of_household'},
    {label: 'Qualified surviving spouse', value: 'qualified_surviving_spouse'}, 
]; 

export const relationshipTypes = [
    {label: 'Choose', value: ''},
    {label: 'Child', value: 'child'},
    {label: 'Sibling', value: 'sibling'},
    {label: 'Parent or in-law', value: 'parent'},
    {label: 'Grandparent', value: 'grandparent'},
    {label: 'Aunt or uncle', value: 'aunt_or_uncle'},
    {label: 'Niece or nephew', value: 'niece_or_nephew'},
    {label: 'Other', value: 'other'}
];

export const monthCount = [
    {label: 'Choose', value: ''},
    {label: '1', value: 1},
    {label: '2', value: 2},
    {label: '3', value: 3},
    {label: '4', value: 4},
    {label: '5', value: 5},
    {label: '6', value: 6},
    {label: '7', value: 7},
    {label: '8', value: 8},
    {label: '9', value: 9},
    {label: '10', value: 10},
    {label: '11', value: 11},
    {label: '12', value: 12}
]

export const w2Box12 = [
    {label: 'Choose', value: ''},
    {label: "A - Uncollected social security or RRTA tax on tips", value: "A"},
    {label: "B - Uncollected Medicare tax on tips", value: "B"},
    {label: "C - Taxable cost of group-term life insurance over $50,000 (included in boxes 1, 3 (up to social security wage base), and 5)", value: "C"},
    {label: "D - Elective deferrals under a section 401(k) cash or deferred arrangement plan (including a SIMPLE 401(k) arrangement)", value: "D"},
    {label: "E - Elective deferrals under a section 403(b) salary reduction agreement", value: "E"},
    {label: "F - Elective deferrals under a section 408(k)(6) salary reduction SEP", value: "F"},
    {label: "G - Elective deferrals and employer contributions (including nonelective deferrals) to a section 457(b) deferred compensation plan", value: "G"},
    {label: "H - Elective deferrals to a section 501(c)(18)(D) tax-exempt organization plan", value: "H"},
    {label: "J - Nontaxable sick pay (information only, not included in box 1, 3, or 5)", value: "J"},
    {label: "K - 20% excise tax on excess golden parachute payments", value: "K"},
    {label: "L - Substantiated employee business expense reimbursements", value: "L"},
    {label: "M - Uncollected social security or RRTA tax on taxable cost of group-term life insurance over $50,000 (former employees only)", value: "M"},
    {label: "N - Uncollected Medicare tax on taxable cost of group-term life insurance over $50,000 (former employees only)", value: "N"},
    {label: "P - Excludable moving expense reimbursements paid directly to members of the U.S. Armed Forces", value: "P"},
    {label: "Q - Nontaxable combat pay", value: "Q"},
    {label: "R - Employer contributions to an Archer MSA", value: "R"},
    {label: "S - Employee salary reduction contributions under a section 408(p) SIMPLE plan", value: "S"},
    {label: "T - Adoption benefits", value: "T"},
    {label: "V - Income from exercise of nonstatutory stock option(s) (included in boxes 1, 3 (up to the social security wage base), and 5).", value: "V"},
    {label: "W - Employer contributions (including amounts the employee elected to contribute using a section 125 cafeteria plan) to your health savings account (HSA)", value: "W"},
    {label: "Y - Deferrals under a section 409A nonqualified deferred compensation plan", value: "Y"},
    {label: "Z - Income under a nonqualified deferred compensation plan that fails to satisfy section 409A", value: "Z"},
    {label: "AA - Designated Roth contributions under a section 401(k) plan", value: "AA"},
    {label: "BB - Designated Roth contributions under a section 403(b) plan", value: "BB"},
    {label: "DD - Cost of employer-sponsored health coverage. The amount reported with code DD is not taxable.", value: "DD"},
    {label: "EE - Designated Roth contributions under a governmental section 457(b) plan.", value: "EE"},
    {label: "FF - Permitted benefits under a qualified small employer health reimbursement arrangement", value: "FF"},
    {label: "GG - Income from qualified equity grants under section 83(i)", value: "GG"},
    {label: "HH - Aggregate deferrals under section 83(i) elections as of the close of the calendar year", value: "HH"}
]


export const capitalGainTypes = [
    {label: 'Choose', value: ''},
    {label: 'Short Term', value: 'short_term'},
    {label: 'Long Term', value: 'long_term'}
];

export const shortTermReportingCategory = [
    {label: 'Choose', value: ''},
    {label: '(A) Received a Form 1099-B showing basis was reported to the IRS', value: 'A'},
    {label: '(B) Received a Form 1099-B showing basis was NOT reported to the IRS', value: 'B'},
    {label: '(C) Did not receive a Form 1099-B', value: 'C'},
]

export const longTermReportingCategory = [
    {label: 'Choose', value: ''},
    {label: '(D) Received a Form 1099-B showing basis was reported to the IRS', value: 'D'},
    {label: '(E) Received a Form 1099-B showing basis was NOT reported to the IRS', value: 'E'},
    {label: '(F) Did not receive a Form 1099-B', value: 'F'},
]

export const largeScreen = 768;
export const drawerWidth = 300;
