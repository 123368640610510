import React, { useState, useEffect } from 'react';
import { View, Platform, StyleSheet, TouchableOpacity } from 'react-native';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { Formik } from 'formik';
import {globalColors, globalStyle} from '../../style';
import StyledButton from '../../components/StyledButton';
import TextFieldFormik from '../../components/TextFieldFormik';
import axiosInstance from '../../axiosInstance';
import StyledText from '../../components/StyledText';
import {loginSchema} from '../../assets/validations';

 export default function LoginScreen({navigation}) {
  const initialValues = { 
    email: '', 
    password: ''}
  const [ formValues, setFormValues ] = useState(initialValues);
  const [ errorMessage, setErrorMessage ] = useState('');

  const continuePressed = (formValues, setSubmitting, setValues, resetForm) => {
    let cleanFormValues = structuredClone(formValues);

    axiosInstance
      // @ts-ignore
      .post('login', JSON.stringify(cleanFormValues), {headers: {'Content-Type': 'application/json' }, withCredentials: true, navigateOnAuthFail: false})
      .then(async function (response) {
        const token = response.data.accessToken;
        await AsyncStorage.setItem('accessToken', token);  // Store the token
        resetForm();
        setSubmitting(false);
        navigation.navigate('landing', {'screen': 'filings'});
      })
      .catch(function (error) {
        setSubmitting(false);
        setErrorMessage('Invalid email or password');
      })
  }

  return (
    <Formik 
      initialValues={formValues} 
      enableReinitialize={true}
      validationSchema={loginSchema}
      onSubmit={(values, { setSubmitting, setValues, resetForm }) => {continuePressed(values, setSubmitting, setValues, resetForm)}}>
     {({ handleChange, handleBlur, handleSubmit, values, setValues, isSubmitting }) => (
      
      <View style={{...globalStyle.landingContainer, backgroundColor: 'white'}}>
        <View style={globalStyle.landingContainerCentral}>
          <StyledText style={{paddingTop: 16, paddingBottom: 16, fontSize: 28, fontWeight: 'bold'}}>Welcome back!</StyledText>
          <View style={globalStyle.fieldRow}>      
            <TextFieldFormik name="email" fieldName="Email" placeholder="Email" fieldStyle={styles.loginField}/>     
          </View>
          <View style={globalStyle.fieldRow}>      
            <TextFieldFormik name="password" fieldName="Password" placeholder="Password" fieldStyle={styles.loginField} secureTextEntry={true} />    
          </View>
          <StyledText>{errorMessage}</StyledText>

          <StyledButton title="Log In" onPress={handleSubmit} disabled={isSubmitting} style={styles.loginButton} textStyle={styles.loginButtonText}/>

          <View style={{flexDirection: 'row', width: '100%', paddingTop: 16}}>
            <TouchableOpacity style={{flex: 1}} onPress={() => navigation.navigate('signup')}>
              <StyledText style={{textAlign: 'left', color: globalColors.accent, fontWeight: 'bold'}}>No account yet?</StyledText>
            </TouchableOpacity>
            <TouchableOpacity style={{flex: 1}} onPress={() => navigation.navigate('signup')}>
              <StyledText style={{textAlign: 'right'}}>Forgot password?</StyledText>
            </TouchableOpacity>
          </View>
        </View>
    </View>
    )}
  </Formik>
  )
 };

 const styles = StyleSheet.create({
  loginField: {
    alignItems: 'flex-start', 
    flex: 1,
    marginRight: 0
  },
  loginButton: {
    backgroundColor: globalColors.primary,
    marginTop: 25,
    width: '100%',
    paddingVertical: 8,
  },
  loginButtonText: {
    color: globalColors.background
  }
});