import React, { useState } from 'react';
import { StyleSheet, View  } from 'react-native';
import { Picker } from '@react-native-picker/picker';
import { useFormikContext, FieldInputProps } from 'formik';
import { useFonts } from 'expo-font';
import StyledText from './StyledText';
import {globalStyle, globalColors} from '../style'


interface DropdownFieldProps {
  name: string;
  fieldName: string;
  items: any;
  placeholder?: string;
  fieldStyle?: object;
  width?: number | string;
}

export default function DropdownField({ name, fieldName, items, placeholder, fieldStyle, width }: DropdownFieldProps) {
  const { handleChange, values, errors, touched } = useFormikContext();
  const [isHovered, setIsHovered] = useState(false);
  const [fontsLoaded] = useFonts({
    'OpenSans-Regular': require('../assets/fonts/OpenSans-Regular.ttf'),
    'OpenSans-Bold': require('../assets/fonts/OpenSans-Bold.ttf'),
  });
  if (!fontsLoaded) {
    return null; // or render a loading indicator
  }
  const inputStyle = isHovered ? styles.hoveredInput : styles.normalInput;

  const pickerItems = items.map((item) =>
    <Picker.Item label={item.label} value={item.value} key={item.label}/>
  );

  return (
    <View style={[styles.dropdownContainer, fieldStyle]}>
      <StyledText style={globalStyle.fieldNameStyle}>
        {fieldName}
      </StyledText>
      <Picker 
        selectedValue={values[name]} 
        onValueChange={handleChange(name)}
        style={[styles.pickerContainerStyle, inputStyle]}
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
        >
        {pickerItems}
      </Picker>
      {touched[name] && errors[name] && <StyledText style={styles.errorText}>{errors[name]}</StyledText>}
    </View>
  );
}

const styles = StyleSheet.create({
  dropdownContainer: {
    alignItems: 'flex-start',
    marginRight: 20
  },
  pickerContainerStyle: {
    fontFamily: 'OpenSans-Regular',
    backgroundColor: "white",
    height: 52,
    borderRadius:5,
    paddingLeft:7,
    width: '100%'
  },
  normalInput: {
    marginHorizontal: 1,
    borderWidth:1, 
    borderColor:globalColors.grey,
  },
  hoveredInput: {
    borderWidth:2, 
    borderColor:globalColors.primary,
  },
  errorText: {
    fontSize: 12,
    color: 'red',
    marginTop: 5,
  },
});
