import React from 'react';
import { View } from 'react-native';
import { globalStyle } from '../style';
import StyledText from './StyledText';
import AnimatedSwingingIcon from './AnimatedSwingingIcon';

const ContentHeader = ({icon='', headerText, subHeaderText=''}) => {

  return (
    <View style={globalStyle.screenHeaderContainer}>
      <AnimatedSwingingIcon icon={icon} />
      <StyledText style={globalStyle.screenHeaderText}>{headerText}</StyledText>
      <StyledText style={globalStyle.screenSubHeaderText}>{subHeaderText}</StyledText>
    </View>
  );
};

export default ContentHeader;