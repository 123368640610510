import axios from 'axios';
import { CommonActions } from '@react-navigation/native';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { REACT_APP_BACKEND_URL, ENV_TYPE } from '@env'; 


const axiosInstance = axios.create({
  baseURL: 'https://' + REACT_APP_BACKEND_URL + '/api/'
});
console.log(`Base URL: ${axiosInstance.defaults.baseURL}`);

// Function to set navigator reference
let navigatorRef;
export const setNavigatorRef = (ref) => {
  navigatorRef = ref;
};

axiosInstance.interceptors.request.use(
  async (config) => {
    try {
      // Retrieve the JWT token from AsyncStorage
      const token = await AsyncStorage.getItem('accessToken');
      if (token) {
        // If the token exists, append it to the header of every request
        config.headers.Authorization = `Bearer ${token}`;
      }
    } catch (error) {
      console.error('Error in request interceptor:', error);
    }
    return config;
  },
  (error) => {
    // Handle request error here
    return Promise.reject(error);
  }
);

axiosInstance.interceptors.response.use(
    response => response,
    error => {
        let navigateOnAuthFail = error.config.navigateOnAuthFail;
        if (navigateOnAuthFail === false) { return Promise.reject(error); }

        if (error.response) {
            switch (error.response.status) {
                case 401: // Unauthorized
                  navigatorRef.dispatch(CommonActions.reset({index: 0, routes: [ { name: 'landing', state: {routes: [{ name: 'login' }] } }], }));
                case 403: //forbidden
                  navigatorRef.dispatch(CommonActions.reset({index: 0, routes: [ { name: 'landing', state: {routes: [{ name: 'login' }] } }], }));
                case 404: // page not found
                  navigatorRef.dispatch(CommonActions.reset({index: 0, routes: [ { name: 'landing', state: {routes: [{ name: 'home' }] } }], }));
                default: // other errors
                  navigatorRef.dispatch(CommonActions.reset({index: 0, routes: [ { name: 'landing', state: {routes: [{ name: 'home' }] } }], }));
            }
        } 
        return Promise.reject(error);
    }
  );

export default axiosInstance;