import React from 'react';
import { View, useWindowDimensions, StyleSheet, ViewStyle } from 'react-native';
import { globalColors } from '../style';
import { largeScreen, drawerWidth } from '../assets/constants';
import StyledButton from './StyledButton';

interface ContentContainerProps {
  children: React.ReactNode;
  nextTitle?: string;
  nextPress: () => void;
  nextDisabled: boolean;
  backTitle?: string;
  backPress: () => void;
  backDisabled?: boolean;
  containerStyle?: ViewStyle;
}
const ContentContainer: React.FC<ContentContainerProps> = ({
  children, nextTitle = "Continue", nextPress, nextDisabled, backTitle = "< Back", backPress, backDisabled = false, containerStyle = {}}) => {
  const { width } = useWindowDimensions();

  let contentWidth;
  if (width > 1300) {
    contentWidth = 900;
  } else if (width > largeScreen) {
    contentWidth = (width - drawerWidth) * 0.7;
  } else {
    contentWidth = width;
  }

  return (
    <View style={styles.screenContainer}>
      <View style={[{ width: contentWidth }, styles.contentColumn, containerStyle]}>
        <View style={styles.contentArea}>
          {children}
        </View>
      </View>
      <View style={{ ...styles.buttonContainer, width: (width - drawerWidth) * 0.7 }}>
        <StyledButton 
          title={backTitle} 
          onPress={backPress} 
          disabled={backDisabled} 
          style={{ backgroundColor: 'transparent' }} 
          textStyle={{ color: globalColors.text }}
        />
        <StyledButton 
          title={nextTitle} 
          onPress={nextPress} 
          disabled={nextDisabled} 
        />
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
    screenContainer: {
        flex: 1,
        backgroundColor: 'white',
        width: '100%',
        overflowY: 'scroll',
      },
      contentColumn: {
        alignSelf: 'center',
        paddingTop: 50,
        paddingHorizontal: 20,
      },
      contentArea: {
        flex: 1,
        paddingBottom: 20,
        minHeight: 500,
      },
      buttonContainer: {
        alignSelf: 'center',
        marginTop: 20,
        paddingVertical: 30,
        flexDirection: 'row',
        justifyContent:'space-between',
        borderTopWidth: 1,
        borderColor: globalColors.grey,
      }
});
  

export default ContentContainer;