import React, {useState} from 'react';
import { TextInput, StyleSheet, View, KeyboardTypeOptions } from 'react-native';
import { useFormikContext, FieldInputProps } from 'formik';
import PropTypes from 'prop-types';
import { useFonts } from 'expo-font';
import StyledText from './StyledText';
import {globalStyle, globalColors} from '../style';
import { prettifyNumber } from '../utils';

interface TextFieldProps {
  name: string;
  fieldName: string;
  placeholder?: string;
  fieldStyle?: object;
  textStyle?: object;
  width?: number;
  secureTextEntry?: boolean;
  decimal?: boolean;
}
const dollarBoxWidth = 20;

export default function DollarField({ name, fieldName, placeholder, fieldStyle, textStyle, width, secureTextEntry=false, decimal=false}: TextFieldProps) {
  const { handleChange, handleBlur, setFieldValue, values, errors, touched } = useFormikContext();
  const [isHovered, setIsHovered] = useState(false);
  const [fontsLoaded] = useFonts({
    'OpenSans-Regular': require('../assets/fonts/OpenSans-Regular.ttf'),
    'OpenSans-Bold': require('../assets/fonts/OpenSans-Bold.ttf'),
  });
  if (!fontsLoaded) {
    return null; // or render a loading indicator
  }

  const handleTextChange = (text) => {
    handleChange(name)(prettifyNumber(text, decimal));
  };
  const inputStyle = isHovered ? styles.hoveredInput : styles.normalInput;
  const boxStyle = isHovered ? styles.hoveredDollarBox : styles.normalDollarBox;
  
  return (
    <View style={[styles.textFormContainer, fieldStyle]}>
      <StyledText style={globalStyle.fieldNameStyle}>
        {fieldName}
      </StyledText>
      <View style={{display: 'flex', width: '100%', flexWrap: 'nowrap', flexDirection: 'row' }}>
        <View style={[styles.dollarBox, boxStyle]}>
          <StyledText style={styles.dollarText}>$</StyledText>
        </View>
        <TextInput
          onChangeText={handleTextChange}
          onBlur={handleBlur(name)}
          value={values[name] ?? ''}
          placeholder={placeholder}
          placeholderTextColor="gray"
          keyboardType="numeric"
          style={[styles.textInputStyle, inputStyle, textStyle]}
          onMouseEnter={() => setIsHovered(true)}
          onMouseLeave={() => setIsHovered(false)}
          secureTextEntry={secureTextEntry}
        />
      </View>
      <View style={styles.errorContainer}>
        {touched[name] && errors[name] && <StyledText style={styles.errorText}>{errors[name]}</StyledText>}
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  textFormContainer: {
    alignItems: 'flex-start',
    marginRight: 20
  },
  dollarBox: {
    width: dollarBoxWidth,
    flexShrink: 0,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: globalColors.background,
    borderTopLeftRadius: 5,
    borderBottomLeftRadius: 5,
    boxSizing: 'border-box',
  },
  dollarText: {
    color: globalColors.text
  },
  normalDollarBox: {
    marginVertical: 1,
    marginLeft: 1,
    borderTopWidth:1,
    borderBottomWidth:1,
    borderLeftWidth:1,
    borderColor:globalColors.grey
  },
  hoveredDollarBox: {
    borderTopWidth:2,
    borderBottomWidth:2,
    borderLeftWidth:2,
    borderColor: globalColors.primary,
  },
  textInputStyle: {
    flexGrow: 1,
    fontFamily: 'OpenSans-Regular',
    backgroundColor: "white",
    height: 52,
    borderWidth:1, 
    borderColor:"gray",
    borderTopRightRadius: 5,
    borderBottomRightRadius: 5,
    paddingLeft:7,
    boxSizing: 'border-box',
  },
  normalInput: {
    marginVertical: 1,
    marginRight: 1,
    borderTopWidth:1, 
    borderBottomWidth:1, 
    borderRightWidth:1, 
    borderColor:globalColors.grey,
  },
  hoveredInput: {
    borderTopWidth:2, 
    borderBottomWidth:2, 
    borderRightWidth:2, 
    borderColor: globalColors.primary,
  },
  errorContainer: {
    height: 20,
  },
  errorText: {
    fontSize: 12,
    color: 'red',
    marginTop: 5,
    height: 15,
  },
});
