import React from 'react';
import { StyleSheet, TouchableOpacity, Image, View } from 'react-native';
import { useFormikContext } from 'formik';
import StyledText from './StyledText';
import {globalStyle, globalColors} from '../style'

interface BooleanBoxProps {
  name: string;
  fieldName: string;
  trueValue?: string;
  trueText?: any;
  trueImage?: string;
  falseValue?: string;
  falseText?: any;
  falseImage?: string;
}

export default function BooleanBoxField({name, fieldName, trueValue='Yes', falseValue='No', trueText, falseText, trueImage, falseImage}: BooleanBoxProps) {
  const { handleChange, setFieldValue, values, errors, touched } = useFormikContext();

  const handlePress = (fieldName:string, newValue:any) => {

    if (values[fieldName] !== newValue) {
      setFieldValue(fieldName, newValue);
    } else {
      setFieldValue(fieldName, '');
    }
  };

  const booleanBox = (id: string, fieldName: string, value:any, text:any, image:string) => {
    if (values[fieldName] === value) {
      return (
        <TouchableOpacity key={id} style={[styles.booleanBox, styles.booleanBoxSelected]} onPress={() => handlePress(fieldName, value)}>
          <View style={{ alignItems: 'center', paddingVertical: 12, marginBottom: 20}}>
            <Image source={require('../assets/icons/' + image + '_green.png')} style={{ width: 88, height: 88 }} />
          </View>

          {text}
          <Image source={require('../assets/icons/checkmark.png')} style={styles.checkmark} />
        </TouchableOpacity>
      )
    }
    else {
      return (
        <TouchableOpacity key={id} style={styles.booleanBox} onPress={() => handlePress(fieldName, value)}>
          <View style={{ alignItems: 'center', paddingVertical: 12, marginBottom: 20}}>
            <Image source={require('../assets/icons/' + image + '_grey.png')} style={{ width: 88, height: 88 }} />
          </View>

          {text}
        </TouchableOpacity>
      )
    }
  };

  return (
    <View style={styles.booleanBoxContainer}>
      {booleanBox('1', fieldName, trueValue, trueText, trueImage)}
      {booleanBox('2', fieldName, falseValue, falseText, falseImage)}
    </View>
  )
}

const styles = StyleSheet.create({
  booleanBoxContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  booleanBox: {
    marginHorizontal: 20,
    width: 300,
    height: 260,
    padding: 16,
    borderWidth: 1,
    borderColor: globalColors.grey,
    borderRadius: 10,
    shadowColor: '#000', // Shadow for 3D effect
    shadowOffset: { width: 0, height: 2 },
    shadowOpacity: 0.25,
    shadowRadius: 3.84,
    elevation: 5,
  },
  booleanBoxSelected: {
    borderColor: globalColors.primary, 
    borderWidth: 3
  },
  checkmark: {
    position: 'absolute',  // This makes it overlay the box
    top: 8,                // Adjust to your desired distance from the top
    right: 8,              // Adjust to your desired distance from the right
    width: 28,             // Set the width of the checkmark
    height: 28,            // Set the height of the checkmark
  },

});
