import React from 'react';
import { TouchableOpacity, Text, StyleSheet } from 'react-native';
import { globalColors } from '../style';
import StyledText from './StyledText';

const StyledButton = ({ title = '', onPress, style = {}, textStyle = {}, ...props }) => {
  return (
    <TouchableOpacity
      style={[styles.button, style, props.disabled && styles.disabled]}
      onPress={props.disabled ? null : onPress}
      {...props}
    >
      <StyledText style={[styles.text, textStyle, props.disabled && styles.disabledText]}>{title}</StyledText>
    </TouchableOpacity>
  );
};

const styles = StyleSheet.create({
  button: {
    backgroundColor: globalColors.primary,
    paddingVertical: 8,
    paddingHorizontal: 24,
    borderRadius: 6,
    alignItems: 'center',
    justifyContent: 'center',
    minWidth: 100,
  },
  text: {
    color: '#fff',
    fontSize: 16,
    fontWeight: 'bold',
  },
  disabled: {
    backgroundColor: '#eee',
  },
  disabledText: {
    color: globalColors.text,
  },
});

export default StyledButton;
