import {  View  } from 'react-native';
import React, { useState, useEffect } from 'react';
import { useIsFocused } from '@react-navigation/native'
import { Formik } from 'formik';
import ContentContainer from '../../../components/ContentContainer';
import ContentHeader from '../../../components/ContentHeader';
import DollarFieldFormik from '../../../components/DollarFieldFormik';
import {globalStyle} from '../../../style';
import { handleFilingIdParam, handleFormIdParam, editFormValues, navigateFiling, pullFormValues, prettifyNumber } from '../../../utils';

export default function ExtensionPaymentScreen({route, navigation}) {
  const initialValues = {
    'amountPaid': '',
  }
  const [formValues, setFormValues] = useState(initialValues);
  const [filingId, setFilingId] = useState('');
  const [formId, setFormId] = useState('');
  const isFocused = useIsFocused();
  const numericFields = ['amountPaid']

  useEffect(() => {
    const newFilingId = handleFilingIdParam(navigation, route, setFilingId);
    const cleanFormId = handleFormIdParam(route, setFormValues, setFormId, initialValues, newFilingId, isFocused, numericFields, 'Form 4868'); 
  }, [isFocused]);

 
  const continuePressed = (formValues, setSubmitting, setValues) => {
    editFormValues(setSubmitting, setValues, filingId, formId, 'Form 4868', formValues, initialValues, navigation, 'otherSituations', numericFields)
  };

  const backPressed = (setValues) => {
    navigateFiling(navigation, 'otherSituations', { filingId: filingId });
  };

  return (
    <Formik 
    initialValues={formValues} 
    enableReinitialize={true}
    onSubmit={(values, { setSubmitting, setValues }) => {continuePressed(values, setSubmitting, setValues)}}>
    {({ handleChange, handleBlur, handleSubmit, values, setValues, isSubmitting }) => (
      <ContentContainer nextPress={handleSubmit} nextDisabled={isSubmitting} backPress={backPressed} backDisabled={false}>
        <ContentHeader icon={"medical-services"} headerText="How much have you already paid?" subHeaderText="If you requested an extension by filing Form 4868 or by making a payment, we'll need those details to make sure you don't overpay."/>
 
        <View style={globalStyle.fieldRow}>
          <DollarFieldFormik name="amountPaid" fieldName="How much have you already paid?" placeholder="" textStyle={{'maxWidth': 200}}  /> 
        </View>

      </ContentContainer>
    )}
    </Formik>
  );
}