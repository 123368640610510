import {  View  } from 'react-native';
import React, { useState, useEffect } from 'react';
import { useIsFocused } from '@react-navigation/native';
import { Formik } from 'formik';
import ContentContainer from '../../components/ContentContainer';
import DropdownField from '../../components/DropdownFieldFormik';
import {globalStyle, globalColors} from '../../style';
import { counties, schoolDistricts } from '../../assets/constantsNyCounties';
import {  navigateFiling } from '../../utils/index'; 
import { handleFilingIdParam } from '../../utils';
import axiosInstance from '../../axiosInstance';
import { replaceNullWithEmpty, replaceEmptyWithNull, editFormValues } from '../../utils/index'; 
import ContentHeader from '../../components/ContentHeader';

export default function NYIntroScreen({navigation, route}) {
  const initialValues = {
    county: '',
    schoolDistrict: ''
  }
  const [formValues, setFormValues] = useState(initialValues);
  const [ filingId, setFilingId ] = useState('');
  const [ formId, setFormId ] = useState('');
  const isFocused = useIsFocused();
  const [errorMessage, setErrorMessage] = useState('');

  useEffect(() => {
    const newFilingId = handleFilingIdParam(navigation, route, setFilingId);
    if (isFocused) {
      axiosInstance
        .post('loadNYIntroScreen', JSON.stringify({'filingId': newFilingId}), { headers: {'Content-Type': 'application/json'}, withCredentials: true})
        .then(function (response) {
          if (response['data'].length > 0) {
            if (response.data[0]['schoolDistrictName'] && response.data[0]['schoolDistrictCode']) {
              response.data[0]['schoolDistrict'] = response.data[0]['schoolDistrictName'] + ' (' + response.data[0]['schoolDistrictCode'] + ')';
            }
            setFormId(response['data'][0]['id']);
            setFormValues(replaceNullWithEmpty(response['data'][0]));
          }
        })
        .catch(function (error) {});
    }
  }, [isFocused]);

  const continuePressed = (formValues, setSubmitting, setValues)  => {
    let answers = replaceEmptyWithNull(structuredClone(formValues));
    // extract out the school district code and name from the raw string
    const rawSchoolDistrict = answers['schoolDistrict'];
    const codeMatch = rawSchoolDistrict.match(/\(([^)]+)\)/);

    answers['schoolDistrictName'] = rawSchoolDistrict.replace(/\s*\([^)]+\)/, '').trim();
    answers['schoolDistrictCode'] = codeMatch ? codeMatch[1] : '';

    editFormValues(setSubmitting, setValues, filingId, formId, 'NY Input', answers, initialValues, navigation, 'bankAccount')
  }

  const backPressed = () => {
    navigateFiling(navigation, 'otherSituations', { filingId: filingId });
  };

  return (
    <Formik 
      initialValues={formValues} 
      enableReinitialize={true}
      onSubmit={(values, { setSubmitting, setValues }) => {continuePressed(values, setSubmitting, setValues)}}>
     {({ handleChange, handleBlur, handleSubmit, values, setValues, isSubmitting }) => (

      <ContentContainer nextPress={handleSubmit} nextDisabled={isSubmitting} backPress={backPressed} backDisabled={false}>
        <ContentHeader icon={"home"} headerText={"Tell us your New York Country"}  subHeaderText='You should use where you were living on December 31st, 2023' />

        <View style={globalStyle.fieldRowCentered}>
          <DropdownField name="county" fieldName="Select your NY county" placeholder="Choose" items={counties} fieldStyle={{width: 300, alignItems: 'center', alignSelf: 'center'}} />
        </View>

        {
        values['county'] !== '' ? 
          <View style={globalStyle.fieldRowCentered}>
            <DropdownField name="schoolDistrict" fieldName="Select your NY school district" placeholder="Choose" items={schoolDistricts[values['county']]} fieldStyle={{width: 300, alignItems: 'center', alignSelf: 'center'}} />
          </View> : 
          <View></View>
        }

      </ContentContainer>
     )}
     </Formik>
   )
  };
 
  