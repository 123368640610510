import {  View  } from 'react-native';
import React, { useState, useEffect } from 'react';
import { useIsFocused } from '@react-navigation/native'
import { Formik } from 'formik';
import ContentContainer from '../../../components/ContentContainer';
import ContentHeader from '../../../components/ContentHeader';
import StyledText from '../../../components/StyledText';
import BooleanField from '../../../components/BooleanFieldFormik';
import {globalStyle} from '../../../style';
import { handleFilingIdParam, handleFormIdParam, editFormValues, navigateFiling } from '../../../utils';

export default function HSAAccountScreen({route, navigation}) {
  const initialValues = {
    'haveHsa': '',
    'haveHdhp': '',
    'hdhpSelfOnly': ''
  }

  const [formValues, setFormValues] = useState(initialValues);
  const [filingId, setFilingId] = useState('');
  const [formId, setFormId] = useState('');
  const isFocused = useIsFocused();

  useEffect(() => {
    const newFilingId = handleFilingIdParam(navigation, route, setFilingId);
    const cleanFormId = handleFormIdParam(route, setFormValues, setFormId, initialValues, newFilingId, isFocused, [], 'Medical Input'); 
  }, [isFocused]);

 
  const continuePressed = (formValues, setSubmitting, setValues) => {
    editFormValues(setSubmitting, setValues, filingId, formId, 'Medical Input', formValues, initialValues, navigation, 'hsaContributions')
  };

  const backPressed = (setValues) => {
    navigateFiling(navigation, 'otherSituations', { filingId: filingId });
  };

  return (
    <Formik 
    initialValues={formValues} 
    enableReinitialize={true}
    onSubmit={(values, { setSubmitting, setValues }) => {continuePressed(values, setSubmitting, setValues)}}>
    {({ handleChange, handleBlur, handleSubmit, values, setValues, isSubmitting }) => (
      <ContentContainer nextPress={handleSubmit} nextDisabled={isSubmitting} backPress={backPressed} backDisabled={false}>
        <ContentHeader icon={"medical-services"} headerText="Tell us about your HSA Account"/>
 
        <View style={globalStyle.fieldRow}>
          <BooleanField name="haveHsa" fieldName="Did you have an HSA Account in 2023?"/>
        </View>

        {values['haveHsa'] === true ?
          <View style={globalStyle.fieldRow}>
            <BooleanField name="haveHdhp" fieldName="Did you have a High Deductible Health Plan (HDHP) in 2023?" />
          </View> : null}

        {(values['haveHsa'] === true && values['haveHdhp'] === true) ?
          <View style={globalStyle.fieldRow}>
            <BooleanField name="hdhpSelfOnly" fieldName="What type of HDHP did you have?" trueLabel='Self-only' falseLabel='Family' />
          </View> : null}

      </ContentContainer>
    )}
    </Formik>
  );
}