import { StyleSheet, Button, TouchableOpacity, Text, View  } from 'react-native';
import React, { useState, useEffect } from 'react';
import axiosInstance from '../../axiosInstance';
import ContentContainer from '../../components/ContentContainer';
import ContentHeader from '../../components/ContentHeader';
import StyledText from '../../components/StyledText';
import StyledButton from '../../components/StyledButton';
import {globalStyle} from '../../style';
import { handleFilingIdParam, navigateFiling } from '../../utils';

export default function GenerateFilingScreen({navigation, route}) {
  // declare field state variables
  const [ filingId, setFilingId ] = useState('');

  useEffect(() => {
    const newFilingId = handleFilingIdParam(navigation, route, setFilingId);
  }, [])

  const generateFilingPressed = () => {
    axiosInstance
    .post('generateFiling', JSON.stringify({'filingId': route.params.filingId}), { headers: {'Content-Type': 'application/json' }, withCredentials: true})
    .then(function (response) {
      // create file link in browser's memory
      const href = URL.createObjectURL(response.data);

      // create "a" HTLM element with href to file & click
      const link = document.createElement('a');
      link.href = href;
      link.setAttribute('download', 'file.pdf'); //or any other extension
      document.body.appendChild(link);
      //link.click();

      // clean up "a" element & remove ObjectURL
      document.body.removeChild(link);
      URL.revokeObjectURL(href);
    })
    .catch(function (error) {console.log(error)});
  };
  const continuePressed = () => {
  };
  const backPressed = () => {
    navigateFiling(navigation, 'bankAccount', { filingId: filingId });
  };
  return (
    <ContentContainer nextPress={continuePressed} nextDisabled={false} backPress={backPressed}>
      <ContentHeader icon={"print"} headerText="Generate your Tax Filing PDFs"/>

      <View style={{width: '100%', alignItems: 'center'}}>
        <StyledButton title="Generate Filing" onPress={generateFilingPressed}/>
      </View>
    </ContentContainer>
  );
}
