import {  View  } from 'react-native';
import React, { useState, useEffect } from 'react';
import { useIsFocused } from '@react-navigation/native'
import { Formik } from 'formik';
import ContentContainer from '../../components/ContentContainer';
import ContentHeader from '../../components/ContentHeader';
import DropdownField from '../../components/DropdownFieldFormik';
import TextFieldFormik from '../../components/TextFieldFormik';
import {globalStyle} from '../../style';
import { handleFilingIdParam, handleFormIdParam, editFormValues, navigateFiling } from '../../utils';

export default function BankAccountScreen({route, navigation}) {
  const initialValues = {
    'accountType': '',
    'routingNumber': '',
    'accountNumber': ''
  }

  const [formValues, setFormValues] = useState(initialValues);
  const [filingId, setFilingId] = useState('');
  const [formId, setFormId] = useState('');
  const isFocused = useIsFocused();

  useEffect(() => {
    const newFilingId = handleFilingIdParam(navigation, route, setFilingId);
    const cleanFormId = handleFormIdParam(route, setFormValues, setFormId, initialValues, newFilingId, isFocused, [], 'Bank Input'); 
  }, [isFocused]);

 
  const continuePressed = (formValues, setSubmitting, setValues) => {
    editFormValues(setSubmitting, setValues, filingId, formId, 'Bank Input', formValues, initialValues, navigation, 'generateFiling')
  };

  const backPressed = (setValues) => {
    navigateFiling(navigation, 'nyIntro', { filingId: filingId });
  };

  const accountTypes = [
    {label: 'Choose', value: ''},
    {label: 'Personal Checking', value: 'personal_checking'},
    {label: 'Personal Savings', value: 'personal_savings'},
]

  return (
    <Formik 
    initialValues={formValues} 
    enableReinitialize={true}
    onSubmit={(values, { setSubmitting, setValues }) => {continuePressed(values, setSubmitting, setValues)}}>
    {({ handleChange, handleBlur, handleSubmit, values, setValues, isSubmitting }) => (
      <ContentContainer nextPress={handleSubmit} nextDisabled={isSubmitting} backPress={backPressed} backDisabled={false}>
        <ContentHeader icon={"medical-services"} headerText="Tell us about your bank account"/>
 
        <View style={globalStyle.fieldRow}>
          <DropdownField name="accountType" fieldName="What type of account is this?" items={accountTypes}  fieldStyle={{'flex': 2, minWidth: 250}}/>
          <View style={{flex: 2}}></View>
        </View>

        <View style={globalStyle.fieldRow}>
          <TextFieldFormik name="routingNumber" fieldName="Routing Number" placeholder="" fieldStyle={{'flex': 2, minWidth: 250}} /> 
          <TextFieldFormik name="accountNumber" fieldName="Account Number" placeholder="" fieldStyle={{'flex': 2, minWidth: 250}} /> 
        </View>

      </ContentContainer>
    )}
    </Formik>
  );
}