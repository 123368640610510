import React, { useState, useEffect } from 'react';
import { Button, TouchableOpacity, View, Text } from 'react-native';
import { useIsFocused } from '@react-navigation/native';
import { Formik } from 'formik';
import {globalStyle} from '../../../style';
import ContentContainer from '../../../components/ContentContainer';
import StyledText from '../../../components/StyledText';
import BooleanField from '../../../components/BooleanFieldFormik';
import { replaceEmptyWithNull, replaceNullWithEmpty } from '../../../utils/index'; 
import { handleFilingIdParam, navigateFiling } from '../../../utils';
import axiosInstance from '../../../axiosInstance';

 export default function ForeignAccountScreen({navigation, route}) {
  const initialValues = {
    foreignAccountBool: '', 
    fbarBool: '', 
    minForeignValueBool: '',
    foreignTrustBool: '',}

  const isFocused = useIsFocused();
  const [ filingId, setFilingId ] = useState('');
  const [ f8939Threshold, setF8939Threshold ] = useState([50000, 75000]);
  const [ filingYear, setFilingYear ] = useState('');
  const [ miscFormId, setMiscFormId ] = useState('');
  const [ formValues, setFormValues ] = useState(initialValues);
  const [ errorMessage, setErrorMessage ] = useState('');

  useEffect(() => {
    if (isFocused) {
      const newFilingId = handleFilingIdParam(navigation, route, setFilingId);
      axiosInstance
      .post('loadForeignAccounts', JSON.stringify({'filingId': newFilingId}), { headers: {'Content-Type': 'application/json' }, withCredentials: true})
      .then(function (response) {
        if (Object.keys(response['data']).length > 0) {
          setMiscFormId(response['data']['currentResponses'][0]['id']);
          setFilingYear(response['data']['filingYear']);
          setF8939Threshold(response['data']['filingThreshold']);
          setFormValues(prevValues => ({
            ...prevValues,
            ...Object.fromEntries(Object.entries(replaceNullWithEmpty(response['data']['currentResponses'][0])).filter(([key]) => key in prevValues))
          }));
        }
      })
      .catch(function (error) {});
    }
  }, [isFocused]);

  const continuePressed = (formValues, setSubmitting, setValues, resetForm) => {
    let answers = replaceEmptyWithNull(structuredClone(formValues));
    answers['filingId'] = filingId;
    let apiURL = '/addUserForm';

    if (answers['foreignAccountBool'] !== true) {answers['fbarBool'] = null;}

    if (miscFormId !== '') {
      answers['userFormId'] = miscFormId;
      apiURL ='/editUserForm';
    } else {
      answers['userFormName'] = 'Miscellaneous';
    }
    let jsonData = JSON.stringify(answers);
    axiosInstance
    .post(apiURL, jsonData, { headers: {'Content-Type': 'application/json'}, withCredentials: true})
    .then((response) => {
      setValues(initialValues);
      resetForm();
      if (formValues['foreignAccountBool'] === true){ 
        navigateFiling(navigation, 'foreignAccountSummary', { filingId: filingId });
      }
      else {
        navigateFiling(navigation, 'otherSituations', { filingId: filingId });
      }
    })
    .catch(error => {console.log(error);})  
  }
  const backPressed = () => {
    navigateFiling(navigation, 'otherSituations', { filingId: filingId });
  };
  

  const f8939_text= `Is the total value of foreign financial assets greater than $${f8939Threshold[0]/1000}k, or greater than $${f8939Threshold[1]/1000}k at any time in ${filingYear}?`;
  const account_test = `At any time during ${filingYear}, did you have a financial interest in or signature authority over a financial account (such as a bank account, securities account, or brokerage account) located in a foreign country?`;
  return (
    <Formik 
      initialValues={formValues} 
      enableReinitialize={true}
      onSubmit={(values, { setSubmitting, setValues, resetForm }) => {continuePressed(values, setSubmitting, setValues, resetForm)}}>
     {({ handleChange, handleBlur, handleSubmit, values, setValues, isSubmitting }) => (
      
      <ContentContainer nextPress={handleSubmit} nextDisabled={isSubmitting} backPress={backPressed} backDisabled={false}>
        <StyledText style={globalStyle.screenHeaderText}>You might need to report some information on Foreign ownership</StyledText>

        <View style={globalStyle.fieldRow}>
          <BooleanField name="foreignAccountBool" fieldName={account_test} trueLabel={'Yes'} falseLabel={'No'} />
        </View>

        {values['foreignAccountBool'] ?
          <View>
            <View style={globalStyle.fieldRow}>
              <BooleanField name="fbarBool" fieldName="Are you required to file FinCEN Form 114, Report of Foreign Bank and Financial Accounts (FBAR), to report that financial interest or signature authority?" trueLabel={'Yes'} falseLabel={'No'} />
            </View>
            <View style={globalStyle.fieldRow}>
              <BooleanField name="minForeignValueBool" fieldName={f8939_text} trueLabel={'Yes'} falseLabel={'No'} />
            </View>
          </View>
          :
          <View></View>}

        <View style={globalStyle.fieldRow}>
          <BooleanField name="foreignTrustBool" fieldName="Did you receive a distribution from, or were you the grantor of, or transferor to, a foreign trust?" trueLabel={'Yes'} falseLabel={'No'} />
        </View>
        
        <View style={globalStyle.fieldRow}>
          <StyledText style={{}}>{errorMessage}</StyledText>
        </View>
      </ContentContainer>
     )}
    </Formik>
  )
 };