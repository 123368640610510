import {  View  } from 'react-native';
import React, { useState, useEffect } from 'react';
import { useIsFocused } from '@react-navigation/native'
import { Formik } from 'formik';
import ContentContainer from '../../../components/ContentContainer';
import ContentHeader from '../../../components/ContentHeader';
import DollarFieldFormik from '../../../components/DollarFieldFormik';
import StyledText from '../../../components/StyledText';
import BooleanField from '../../../components/BooleanFieldFormik';
import {globalStyle} from '../../../style';
import { handleFilingIdParam, handleFormIdParam, editFormValues, navigateFiling } from '../../../utils';

export default function HSADistributionsScreen({route, navigation}) {
  const initialValues = {
    'distributionsBool': '',
    'totalDistribution': '',
    'allMedicalBool': '',
    'medicalDistribution': '',
    'rolloverBool': '',
    'rolloverDistribution': ''

  }

  const [formValues, setFormValues] = useState(initialValues);
  const [filingId, setFilingId] = useState('');
  const [formId, setFormId] = useState('');
  const isFocused = useIsFocused();
  const numericFields = ['distributionAmount']

  useEffect(() => {
    const newFilingId = handleFilingIdParam(navigation, route, setFilingId);
    const cleanFormId = handleFormIdParam(route, setFormValues, setFormId, initialValues, newFilingId, isFocused, numericFields); 
  }, [isFocused]);

 
  const continuePressed = (formValues, setSubmitting, setValues) => {
    editFormValues(setSubmitting, setValues, filingId, formId, 'Medical Input', formValues, initialValues, navigation, 'otherSituations', numericFields)
  };

  const backPressed = (setValues) => {
    navigateFiling(navigation, 'hsaContributions', { filingId: filingId });
  };

  return (
    <Formik 
    initialValues={formValues} 
    enableReinitialize={true}
    onSubmit={(values, { setSubmitting, setValues }) => {continuePressed(values, setSubmitting, setValues)}}>
    {({ handleChange, handleBlur, handleSubmit, values, setValues, isSubmitting }) => (
      <ContentContainer nextPress={handleSubmit} nextDisabled={isSubmitting} backPress={backPressed} backDisabled={false}>
        <ContentHeader icon={"medical-services"} headerText="Tell us about how much you spent using your HSA" subHeaderText="You should have received a Form 1099-SA if you took any distributions."/>
 

        <View style={globalStyle.fieldRow}>
          <BooleanField name="distributionsBool" fieldName="Did you take any distributions in 2023?" />
        </View>

        {values['distributionsBool'] === true ?
        <View>

          <View style={globalStyle.fieldRow}>
            <DollarFieldFormik name="totalDistribution" fieldName="Total HSA distributions (Form 1099-SA, Box 1):" placeholder="" textStyle={{'max-width': 200}} /> 
          </View> 

          <View style={globalStyle.dividerLine} />
          <StyledText style={globalStyle.sectionHeaderText}>Distribution details</StyledText>
          
          <View style={globalStyle.fieldRow}>
            <BooleanField name="allMedicalBool" fieldName="Was all of the distribution used for medical purposes?" />
          </View>

          {values['allMedicalBool'] === false ?
          <View style={globalStyle.fieldRow}>
            <DollarFieldFormik name="medicalDistribution" fieldName="How much of the distrubtion was medical expenses?" placeholder="" textStyle={{'max-width': 200}} /> 
          </View> : null}

          {(values['distributionsBool'] === true && values['allMedicalBool'] === false) ?
          <View>
            <View style={globalStyle.dividerLine} />
            <StyledText style={globalStyle.sectionHeaderText}>HSA Rollover</StyledText>

            <View style={globalStyle.fieldRow}>
              <BooleanField name="rolloverBool" fieldName="Did you rollover any of your distributions to another HSA or widthraw excess contributions and earnings?" />
            </View>
            {(values['distributionsBool'] === true && values['allMedicalBool'] === false && values['rolloverBool'] === true) ?
            <View style={globalStyle.fieldRow}>
              <DollarFieldFormik name="rolloverDistribution" fieldName="Distribution rollover amount (Form 5498-SA, Box 4):" placeholder="" textStyle={{'max-width': 200}} /> 
            </View> : null}

          </View> : null}

        </View> : null}

      </ContentContainer>
    )}
    </Formik>
  );
}