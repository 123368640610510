import React, { useState, useEffect } from 'react';
import { Button, TouchableOpacity, View, StyleSheet } from 'react-native';
import { useIsFocused } from '@react-navigation/native';
import StyledText from '../../../components/StyledText';
import StyledButton from '../../../components/StyledButton';
import {globalStyle} from '../../../style';
import ContentContainer from '../../../components/ContentContainer';
import ContentHeader from '../../../components/ContentHeader';
import { replaceEmptyWithNull, replaceNullWithEmpty  } from '../../../utils/index'; 
import axiosInstance from '../../../axiosInstance';
import {personalInfoSchema} from '../../../assets/validations';
import { handleFilingIdParam, navigateFiling } from '../../../utils';

 export default function OtherSituationsScreen({navigation, route}) {
  const initialValues = {
    presidentialCampaign: false, 
    filingPin: false, 
    foreignAccount: false,
    cryptocurrency: false,
    extensionPayment: false,}

  const isFocused = useIsFocused();
  const [filingId, setFilingId] = useState('');
  const [ miscFormId, setMiscFormId ] = useState('');
  const [ formValues, setFormValues ] = useState(initialValues);

  useEffect(() => {
    if (isFocused) {
      const newFilingId = handleFilingIdParam(navigation, route, setFilingId);
      axiosInstance
      .post('loadOtherSituations', JSON.stringify({'filingId': newFilingId}), { headers: {'Content-Type': 'application/json' }, withCredentials: true})
      .then(function (response) {
        if (response['data'].length > 0) {
          setMiscFormId(response['data'][0]['id']);
          setFormValues(prevValues => ({
            ...prevValues,
            ...Object.fromEntries(Object.entries(replaceNullWithEmpty(response['data'][0])).filter(([key]) => key in prevValues))
          }));
        }
      })
      .catch(function (error) {});
    }
  }, [isFocused]);

  const continuePressed = () => {
    navigateFiling(navigation, 'nyIntro', { filingId: filingId });
  }
  const backPressed = () => {
    navigateFiling(navigation, 'deductionsIntro', { filingId: filingId });
  };
  
  const Situation = ({ label, started, navigateTo }) => {
    return (
      <View style={styles.situationRow}>
        <StyledText style={styles.situationLabel}>{label}</StyledText>
        <StyledButton title={started ? "Edit" : "Start"} onPress={() => {navigateFiling(navigation, navigateTo, { filingId: filingId }); }} />
      </View>
    );
  };


  return (
    <ContentContainer nextPress={continuePressed} nextDisabled={false} backPress={backPressed}>
      <ContentHeader icon={"list-alt"} headerText="Other tax situations" subHeaderText="Start any form that applies to you"/>

      <View style={globalStyle.fieldRow}><StyledText style={globalStyle.sectionHeaderText}>Personal</StyledText></View>
        <Situation label="I want to donate to the presidential election campaign fund" started={formValues['presidentialCampaign']} navigateTo='otherSituations'/>
        <Situation label="I have a PIN?" started={formValues['filingPin']} navigateTo='otherSituations'/>
      

      <View style={globalStyle.fieldRow}><StyledText style={globalStyle.sectionHeaderText}>Income</StyledText></View>
        <Situation label="I own foreign accounts/assets" started={formValues['foreignAccount']} navigateTo='foreignAccount'/>
        <Situation label="I sold cryptocurrency" started={formValues['cryptocurrency']} navigateTo='otherSituations'/>

      <View style={globalStyle.fieldRow}><StyledText style={globalStyle.sectionHeaderText}>Credits & Deductions</StyledText></View>
        <Situation label="I had a HSA account" started={formValues['hsaAccount']} navigateTo='hsaAccount'/>

      <View style={globalStyle.fieldRow}><StyledText style={globalStyle.sectionHeaderText}>Payments</StyledText></View>
        <Situation label="I filed an extension (Form 4868) / already made a payment" started={formValues['extensionPayment']} navigateTo='extensionPayment'/>

    </ContentContainer>
  )
};

 const styles = StyleSheet.create({
  situationRow: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingVertical: 10,
    paddingHorizontal: 15,
    borderBottomWidth: 1,
    borderBottomColor: '#E0E0E0',
  },
  situationLabel: {
    marginRight: 10, // Adjust as necessary
  },
  situationButton: {
    alignSelf: 'flex-start',
  },

});