import React, {useState} from 'react';
import { TextInput, StyleSheet, View, KeyboardTypeOptions } from 'react-native';
import { useFormikContext, FieldInputProps } from 'formik';
import { useFonts } from 'expo-font';
import StyledText from './StyledText';
import {globalColors, globalStyle} from '../style';

interface TextFieldProps {
  name: string;
  fieldName: string;
  placeholder?: string;
  fieldStyle?: object;
  textStyle?: object;
  width?: number | string;
  secureTextEntry?: boolean;
  keyboardType?: string;
  onChangeText?: (text: string, setFieldValue:any) => void; // Optional onChangeText prop
}

export default function TextField({ name, fieldName, placeholder, fieldStyle, textStyle, width, secureTextEntry=false, keyboardType='default', onChangeText }: TextFieldProps) {
  const { handleChange, handleBlur, setFieldValue, values, errors, touched } = useFormikContext();
  const [fontsLoaded] = useFonts({
    'OpenSans-Regular': require('../assets/fonts/OpenSans-Regular.ttf'),
    'OpenSans-Bold': require('../assets/fonts/OpenSans-Bold.ttf'),
  });
  const [isHovered, setIsHovered] = useState(false);
  const inputStyle = isHovered ? styles.hoveredInput : styles.normalInput;

  if (!fontsLoaded) {
    return null; // or render a loading indicator
  }
  // Wrapper function to conditionally handle text changes
  const handleTextChange = (text) => {
    if (onChangeText) {
      // Use custom onChangeText if provided, and pass Formik's setFieldValue to allow custom logic
      onChangeText(text, setFieldValue);
    } else {
      // Fallback to Formik's handleChange if no custom onChangeText is provided
      handleChange(name)(text);
    }
  };
  
  return (
    <View style={[styles.textFormContainer, fieldStyle]}>
      <StyledText style={globalStyle.fieldNameStyle}>
        {fieldName}
      </StyledText>
      <TextInput
        onChangeText={handleTextChange}
        onBlur={handleBlur(name)}
        value={values[name] ?? ''}
        placeholder={placeholder}
        placeholderTextColor="gray"
        keyboardType={keyboardType as KeyboardTypeOptions}
        style={[styles.textInputStyle, inputStyle, textStyle]}
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
        secureTextEntry={secureTextEntry}
      />
      <View style={styles.errorContainer}>
        {touched[name] && errors[name] && <StyledText style={styles.errorText}>{errors[name]}</StyledText>}
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  textFormContainer: {
    alignItems: 'flex-start',
    marginRight: 20
  },
  textInputStyle: {
    fontFamily: 'OpenSans-Regular',
    backgroundColor: "white",
    height: 52,
    paddingLeft:12,
    width: '100%',
    borderRadius:5,
  },
  normalInput: {
    marginHorizontal: 1,
    borderWidth:1, 
    borderColor:globalColors.grey,
  },
  hoveredInput: {
    borderWidth:2, 
    borderColor: globalColors.primary,
  },
  errorContainer: {
    height: 10,
  },
  errorText: {
    fontSize: 12,
    color: 'red',
    marginTop: 5,
    height: 15,
  },
});
