import {  View } from 'react-native';
import React, { useState, useEffect } from 'react';
import { useFocusEffect, useIsFocused } from '@react-navigation/native'
import { Formik } from 'formik';
import ContentContainer from '../../../components/ContentContainer';
import ContentHeader from '../../../components/ContentHeader';
import TextFieldFormik from '../../../components/TextFieldFormik';
import DateFieldFormik from '../../../components/DateFieldFormik';
import BooleanField from '../../../components/BooleanFieldFormik';
import DollarFieldFormik from '../../../components/DollarFieldFormik';
import DropdownField from '../../../components/DropdownFieldFormik';
import { capitalGainTypes, shortTermReportingCategory, longTermReportingCategory } from '../../../assets/constants';
import {globalStyle} from '../../../style';
import { handleFilingIdParam, handleFormIdParam, editFormValues, navigateFiling } from '../../../utils';

export default function AddF1099BItemScreen({route, navigation}) {
  const initialValues = {
    'bulkBool': '',
    'payerName': '', 
    'description': '',
    'dateAcquired': '', 
    'dateSold': '', 
    'grossProceeds': '',
    'costOrOtherBasis': '',
    'gainLoss': '',
    'washSaleLossDisallowed': '',
    'federalIncomeTaxWithheld': '',
    'gainDuration': '',
    'noncoveredSecurities': '',
    'reportingCategory': '',
  }

  const [formValues, setFormValues] = useState(initialValues);
  const [filingId, setFilingId] = useState('');
  const [formId, setFormId] = useState('');
  const isFocused = useIsFocused();
  const numericFields = ['grossProceeds', 'costOrOtherBasis', 'gainLoss', 'washSaleDisallowed', 'federalIncomeTax']

  // if we are editing an existing dependent then we would have received the dependent if as a parameter 
  // we use this to load the existing dependent details upon render and focus
  useEffect(() => {
    const newFilingId = handleFilingIdParam(navigation, route, setFilingId);
    const cleanFormId = handleFormIdParam(route, setFormValues, setFormId, initialValues, newFilingId, isFocused, numericFields); 
  }, [isFocused]);

 
  const continuePressed = (formValues, setSubmitting, setValues) => {
    editFormValues(setSubmitting, setValues, filingId, formId, 'Form 1099-B', formValues, initialValues, navigation, 'f1099B', numericFields)
  };

  const backPressed = () => {
    navigateFiling(navigation, 'addF1099B', { filingId: filingId });
  };

  return (
    <Formik 
      initialValues={formValues} 
      enableReinitialize={true}
      onSubmit={(values, { setSubmitting, setValues }) => {continuePressed(values, setSubmitting, setValues)}}
    >
      {({ handleChange, handleBlur, handleSubmit, values, setValues, isSubmitting }) => (
        <ContentContainer nextPress={handleSubmit} nextDisabled={isSubmitting} backPress={backPressed} backDisabled={false}>
          <ContentHeader icon={"attach-money"} headerText={"Enter details from your F1099-B"} />
            <View>
              <View style={globalStyle.fieldRow}>
                <TextFieldFormik name="description" fieldName="Investment Description" placeholder="" fieldStyle={{'flex': 2, minWidth: 250}}  /> 
                <View style={{'flex': 2}}></View>
              </View>
              <View style={globalStyle.fieldRow}>
                <DateFieldFormik name="dateAcquired" fieldName="Date Acquired" placeholder="" fieldStyle={{'flex': 2, minWidth: 250}}  /> 
                <DateFieldFormik name="dateSold" fieldName="Date Sold" placeholder="" fieldStyle={{'flex': 2, minWidth: 250}}  /> 
              </View>
              <View style={globalStyle.fieldRow}>
                <DollarFieldFormik name="grossProceeds" fieldName="Proceeds (Sale Price)" placeholder="" fieldStyle={{'flex': 2, minWidth: 250}}  /> 
                <DollarFieldFormik name="costOrOtherBasis" fieldName="Cost Basis" placeholder="" fieldStyle={{'flex': 2, minWidth: 250}}  /> 
              </View>
              <View style={globalStyle.fieldRow}>
                <DollarFieldFormik name="gainLoss" fieldName="Gain / Loss" placeholder="" fieldStyle={{'flex': 2, minWidth: 250}}  /> 
                <DollarFieldFormik name="washSaleDisallowed" fieldName="Wash Sale Adjustment" placeholder="" fieldStyle={{'flex': 2, minWidth: 250}}  /> 
              </View>
              <View style={globalStyle.fieldRow}>
                <DollarFieldFormik name="federalIncomeTax" fieldName="Federal Income tax" placeholder="" fieldStyle={{'flex': 2, minWidth: 250}}  /> 
                <DropdownField name="gainDuration" fieldName="Short or Long term" placeholder="Choose" items={capitalGainTypes} fieldStyle={{'flex': 2, minWidth: 250}} />
              </View>
              <View style={globalStyle.fieldRow}>
                <BooleanField name="noncoveredSecurities" fieldName="Noncovered Securities" trueLabel={'Yes'} falseLabel={'No'}/>
              </View>
              {values['noncoveredSecurities'] === true ? <View></View> :
                values['gainDuration'] === 'short_term' ? 
                  <View style={globalStyle.fieldRow}>
                    <DropdownField name="reportingCategory" fieldName="IRS Reporting Category" placeholder="Choose" items={shortTermReportingCategory} fieldStyle={{'flex': 2, minWidth: 250}} />
                    <View style={{'flex': 2}}></View>
                  </View> :
                  <View style={globalStyle.fieldRow}>
                    <DropdownField name="reportingCategory" fieldName="IRS Reporting Category" placeholder="Choose" items={longTermReportingCategory} fieldStyle={{'flex': 2, minWidth: 250}} />
                    <View style={{'flex': 2}}></View>
                  </View>
              }
          </View>
        </ContentContainer>
      )}
    </Formik>
  );
}