import { View } from 'react-native';
import React, { useState, useEffect } from 'react';
import { useIsFocused } from '@react-navigation/native'
import { Formik } from 'formik';
import ContentContainer from '../../components/ContentContainer';
import TextFieldFormik from '../../components/TextFieldFormik';
import DateFieldFormik from '../../components/DateFieldFormik';
import ContentHeader from '../../components/ContentHeader';
import DropdownField from '../../components/DropdownFieldFormik';
import axiosInstance from '../../axiosInstance';
import { globalStyle } from '../../style';
import { replaceEmptyWithNull, editFormValues, navigateFiling, handleFilingIdParam, handleFormIdParam } from '../../utils/index';
import { relationshipTypes } from '../../assets/constants';

export default function AddDependentScreen({route, navigation}) {
  const initialValues = {
    'firstName': '', 
    'middleInitial': '', 
    'lastName': '', 
    'dob': '',
    'ssn': '',
    'type': '',
  }
  const [ formValues, setFormValues ] = useState(initialValues);
  const [filingId, setFilingId] = useState('');
  const [formId, setFormId] = useState('');
  const isFocused = useIsFocused();

  // if we are editing an existing dependent then we would have received the dependent if as a parameter 
  // we use this to load the existing dependent details upon render and focus
  useEffect(() => {
    const newFilingId = handleFilingIdParam(navigation, route, setFilingId);
    const cleanFormId = handleFormIdParam(route, setFormValues, setFormId, initialValues, newFilingId, isFocused, []); 
  }, [isFocused]);

  const continuePressed = (formValues, setSubmitting, setValues) => {
    // edit form values
    let cleanFormValues = replaceEmptyWithNull(structuredClone(formValues), ['dob']);
    cleanFormValues['filingId'] = filingId;
    let apiURL = '/addUserForm';

    // if we are editing an existing form, then the query needs to change
    if (formId !== null && formId !== '' && formId !== undefined) {
        apiURL = '/editUserForm';
        cleanFormValues['userFormId'] = formId;
    }
    else {
      cleanFormValues['userFormName'] = 'Dependent';
    }
    let jsonData = JSON.stringify(cleanFormValues);
    axiosInstance
        .post(apiURL, jsonData, { headers: { 'Content-Type': 'application/json' }, withCredentials: true })
        .then((response) => {
            setValues(initialValues);
            setSubmitting(false);
            let newFormId = response.data['userFormId'];
            // now check whether the dependent could be qualified as this will determine the next screen
            axiosInstance
              .post('couldDependentBeQualified', JSON.stringify({'filingId': filingId, 'type': cleanFormValues['type'], 'dob': cleanFormValues['dob']}), { headers: {'Content-Type': 'application/json' }, withCredentials: true})
              .then(function (response) {
                setSubmitting(false);
                if (response['data']['next_screen'] === 'qualifiedDependentResult') {
                  navigateFiling(navigation, 'qualifiedDependentResult', 
                    {'firstName': formValues['firstName'], 'qualifiedBool': false, 'formId': newFormId, 'filingId': filingId, 'relationship': cleanFormValues['type']});
                } else {
                  navigateFiling(navigation, response.data.next_screen, {'formId': newFormId, 'filingId': filingId});
                }
              })
              .catch(function (error) {console.log(error.message);
            })
        })
        .catch(error => { console.log(error); });

  };
  const backPressed = (setValues) => {
    navigateFiling(navigation, 'dependents', { filingId: filingId });
  };

  return (
    <Formik 
      initialValues={formValues} 
      enableReinitialize={true}
      onSubmit={(values, { setSubmitting, setValues }) => {continuePressed(values, setSubmitting, setValues)}}>
     {({ handleChange, handleBlur, handleSubmit, values, setValues, isSubmitting }) => (
    <ContentContainer nextPress={handleSubmit} nextDisabled={isSubmitting} backPress={backPressed}>
      <ContentHeader icon={"family-restroom"} headerText="Share details on your dependent"/>
  
      <View style={globalStyle.fieldRow}>
        <TextFieldFormik name="firstName" fieldName="First Name" placeholder="First name" fieldStyle={{'flex': 5, minWidth: 200}}  />  
        <TextFieldFormik name="middleInitial" fieldName="Middle Initial" placeholder="M.I." fieldStyle={{'flex': 2, minWidth: 100}}/> 
        <TextFieldFormik name="lastName" fieldName="Last Name" placeholder="Last name" fieldStyle={{'flex': 5, minWidth: 200}}/> 
      </View>
      <View style={globalStyle.fieldRow}>
        <DateFieldFormik name="dob" fieldName="Date Of Birth" placeholder="First name" fieldStyle={{'flex': 2, minWidth: 200}} /> 
        <TextFieldFormik name="ssn" fieldName="Social Security Number" placeholder="###-###-####" fieldStyle={{'flex': 2, minWidth: 200}}  />   
      </View>
      <View style={globalStyle.fieldRow}>
        <DropdownField name="type" fieldName="Relationship" placeholder="Choose" items={relationshipTypes} width={200}/>
      </View>

    </ContentContainer>
     )}
     </Formik>
  );
}