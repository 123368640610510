import { StyleSheet, Button, TouchableOpacity, Text, View, ActivityIndicator  } from 'react-native';
import React, { useState, useEffect } from 'react';
import { useFocusEffect, useIsFocused } from '@react-navigation/native'
import { Formik } from 'formik';
import ContentContainer from '../../../components/ContentContainer';
import ContentHeader from '../../../components/ContentHeader';
import TextFieldFormik from '../../../components/TextFieldFormik';
import DollarFieldFormik from '../../../components/DollarFieldFormik';
import {globalStyle} from '../../../style';
import { handleFilingIdParam, handleFormIdParam, editFormValues, navigateFiling } from '../../../utils';

export default function AddF1099DivScreen({route, navigation}) {
  const initialValues = {
    'payerName': '',
    'ordinaryDividends': '', 
    'qualifiedDividends': '', 
    'capGainDistributions': '',
    'unrecapSection1250Gain': '',
    'section1202Gain': '',
    'collectibles28Gain': '',
    'section897Div': '',
    'section897Gain': '',
    'nonDividendDistributions': '',
    'federalIncomeTax': '',
    'section199aDiv': '',
    'investmentExpenses': '',
    'foreignTaxPaid': '',
    'cashLiquidation': '',
    'nonCashLiquidation': '',
    'facta': '',
    'exemptInterestDiv': '',
    'specifiedPrivateDiv': '',
    'stateTaxWithheld': '',
    'inStateExempt': '',
    'outOfStateExempt': '',
    'usGovernmentBond': ''
  }
  const [formValues, setFormValues] = useState(initialValues);
  const [filingId, setFilingId] = useState('');
  const [formId, setFormId] = useState('');
  const isFocused = useIsFocused();
  const numericFields = ['ordinaryDividends', 'qualifiedDividends', 'capGainDistributions', 'unrecapSection1250Gain', 
  'section1202Gain', 'collectibles28Gain', 'section897Div', 'section897Gain', 'nonDividendDistributions', 'federalIncomeTax', 
  'section199aDiv', 'investmentExpenses', 'foreignTaxPaid', 'cashLiquidation', 'nonCashLiquidation', 'facta', 'exemptInterestDiv', 
  'specifiedPrivateDiv', 'stateTaxWithheld', 'foreignOrdinaryDividends', 'inStateExempt', 'outOfStateExempt', 'usGovernmentBond'];

  // if we are editing an existing dependent then we would have received the dependent if as a parameter 
  // we use this to load the existing dependent details upon render and focus
  useEffect(() => {
    const newFilingId = handleFilingIdParam(navigation, route, setFilingId);
    const cleanFormId = handleFormIdParam(route, setFormValues, setFormId, initialValues, newFilingId, isFocused, numericFields); 
  }, [isFocused]);

  const continuePressed = (formValues, setSubmitting, setValues) => {
    editFormValues(setSubmitting, setValues, filingId, formId, 'Form 1099-DIV', formValues, initialValues, navigation, 'f1099Div', numericFields);
  };

  const backPressed = (setValues) => {
    navigateFiling(navigation, 'f1099Div', { filingId: filingId });
  };

  return (
    <Formik 
    initialValues={formValues} 
    enableReinitialize={true}
    onSubmit={(values, { setSubmitting, setValues }) => {continuePressed(values, setSubmitting, setValues)}}>
    {({ handleChange, handleBlur, handleSubmit, values, setValues, isSubmitting }) => (
      <ContentContainer nextPress={handleSubmit} nextDisabled={isSubmitting} backPress={backPressed} backDisabled={false}>
        <ContentHeader icon={"attach-money"} headerText={"Enter details from your F1099-DIV"} />
 
        <View>
          <View style={globalStyle.fieldRow}>
            <TextFieldFormik name="payerName" fieldName="Payer Name" placeholder="" fieldStyle={{'flex': 2, minWidth: 250}} /> 
            <View style={{'flex': 2}}></View>
          </View>
          <View style={globalStyle.fieldRow}>
            <DollarFieldFormik name="ordinaryDividends" fieldName="1a. Total ordinary dividends" fieldStyle={{'flex': 2, minWidth: 250}}/> 
            <DollarFieldFormik name="qualifiedDividends" fieldName="1b. Qualified dividends" fieldStyle={{'flex': 2, minWidth: 250}}/> 
          </View>
          <View style={globalStyle.fieldRow}>
            <DollarFieldFormik name="capGainDistributions" fieldName="2a. Total capital gain distributions" fieldStyle={{'flex': 2, minWidth: 250}}/> 
            <DollarFieldFormik name="unrecapSection1250Gain" fieldName="2b. Unrecaptured Securities 1250 gain" fieldStyle={{'flex': 2, minWidth: 250}}/> 
          </View>
          <View style={globalStyle.fieldRow}>
            <DollarFieldFormik name="section1202Gain" fieldName="2c. Section 1202 gain" placeholder="" fieldStyle={{'flex': 2, minWidth: 250}} /> 
            <DollarFieldFormik name="collectibles28Gain" fieldName="2d. Collectibles (28%) gain" placeholder="" fieldStyle={{'flex': 2, minWidth: 250}}/> 
          </View>
          <View style={globalStyle.fieldRow}>
            <DollarFieldFormik name="section897Div" fieldName="2e. Section 897 ordinary dividends" placeholder=""  fieldStyle={{'flex': 2, minWidth: 250}}/> 
            <DollarFieldFormik name="section897Gain" fieldName="2f. Section 897 capital gains" placeholder="" fieldStyle={{'flex': 2, minWidth: 250}}/> 
          </View>
          <View style={globalStyle.fieldRow}>
            <DollarFieldFormik name="nonDividendDistributions" fieldName="3. Nondividend distributions" placeholder="" fieldStyle={{'flex': 2, minWidth: 250}}/> 
            <DollarFieldFormik name="federalIncomeTax" fieldName="4. Federal Income Tax withheld" placeholder="" fieldStyle={{'flex': 2, minWidth: 250}} /> 
          </View>
          <View style={globalStyle.fieldRow}>
            <DollarFieldFormik name="section199aDiv" fieldName="5. Section 199A dividends" placeholder="" fieldStyle={{'flex': 2, minWidth: 250}}/> 
            <DollarFieldFormik name="investmentExpenses" fieldName="6. Investment Expenses" placeholder="" fieldStyle={{'flex': 2, minWidth: 250}} /> 
          </View>
          <View style={globalStyle.fieldRow}>
            <DollarFieldFormik name="foreignTaxPaid" fieldName="7. Foreign Tax Paid" placeholder="" fieldStyle={{'flex': 2, minWidth: 250}}/> 
            <DollarFieldFormik name="cashLiquidation" fieldName="9. Cash liquidation distributions" placeholder="" fieldStyle={{'flex': 2, minWidth: 250}} /> 
          </View>
          <View style={globalStyle.fieldRow}>
            <DollarFieldFormik name="nonCashLiquidation" fieldName="10. Non-cash liquidation distributions" placeholder="" fieldStyle={{'flex': 2, minWidth: 250}}/> 
            <DollarFieldFormik name="exemptInterestDiv" fieldName="12. Exempt-interest dividends" placeholder="" fieldStyle={{'flex': 2, minWidth: 250}} /> 
          </View>
          <View style={globalStyle.fieldRow}>
            <DollarFieldFormik name="specifiedPrivateDiv" fieldName="13. Specified private bond interest" placeholder="" fieldStyle={{'flex': 2, minWidth: 250}}/> 
            <DollarFieldFormik name="stateTaxWithheld" fieldName="16. State tax withheld" placeholder="" fieldStyle={{'flex': 2, minWidth: 250}} /> 
          </View>
          <View style={globalStyle.fieldRow}>
            <DollarFieldFormik name="inStateExempt" fieldName="How much of box 12 was in-state munis" placeholder="" fieldStyle={{'flex': 2, minWidth: 250}}/> 
            <DollarFieldFormik name="outOfStateExempt" fieldName="How much of box 12 was out-of-state munis" placeholder="" fieldStyle={{'flex': 2, minWidth: 250}} /> 
          </View>
          <View style={globalStyle.fieldRow}>
            <DollarFieldFormik name="usGovernmentBond" fieldName="US Government Bond interest" placeholder="" fieldStyle={{'flex': 2, minWidth: 250}}/> 
            <View style={{'flex': 2}}></View>
          </View>
        </View>

      </ContentContainer>
    )}
    </Formik>
  );
}