import {  View, StyleSheet, ScrollView, TouchableOpacity } from 'react-native';
import React, { useState, useEffect } from 'react';
import { useFocusEffect, useIsFocused } from '@react-navigation/native'
import { Formik, useFormikContext } from 'formik';
import { TableWrapper, Table, Row, Rows, Cell } from 'react-native-table-component';
import ContentContainer from '../../../components/ContentContainer';
import ContentHeader from '../../../components/ContentHeader';
import StyledText from '../../../components/StyledText';
import StyledButton from '../../../components/StyledButton';
import UploadFileWeb from '../../../components/UploadFileWeb';
import TextFieldFormik from '../../../components/TextFieldFormik';
import {globalStyle, globalColors} from '../../../style';
import { handleFilingIdParam, handleFormIdParam, editFormValues, navigateFiling, pullFormValues } from '../../../utils';
import axiosInstance from '../../../axiosInstance';
import { MaterialIcons } from '@expo/vector-icons';

export default function AddF1099BScreen({route, navigation}) {
  const initialValues = {
    'payerName': '',
  }
  const [formValues, setFormValues] = useState(initialValues);
  const [filingId, setFilingId] = useState('');
  const [formId, setFormId] = useState('');
  const [tableData, setTableData] = useState([['','','','','','','','','','','']]);
  const [tableHead, setTableHead] = useState(["Description","Date Acquired","Date Sold","Gross Proceeds", "Cost or Other Basis",
    "Gain Loss","Wash Sale Disallowed","Federal Income Tax Widthheld","Gain Duration","Non-Covered Securities","Reporting Category"]);
  const [fileToUpload, setFileToUpload] = useState(undefined);
  const [uploadDisplayState, setUploadDisplayState] = useState('');
  const isFocused = useIsFocused();
  const numericFields = ['grossProceeds', 'costOrOtherBasis', 'gainLoss', 'washSaleDisallowed', 'federalIncomeTax']

  // if we are editing an existing dependent then we would have received the dependent if as a parameter 
  // we use this to load the existing dependent details upon render and focus
  useEffect(() => {
    const newFilingId = handleFilingIdParam(navigation, route, setFilingId);
    const routePayerName = route.params?.payerName;
    if (routePayerName !== null && routePayerName !== undefined) {
      // user has specified a payer name in the route, so we should pre-populate any previously provided data
      axiosInstance.post('loadF1099BCollection', JSON.stringify({'filingId': newFilingId, 'payerName': routePayerName}), {headers: { 'Content-Type': 'application/json' }, withCredentials: true})
        .then((response) => {
          // pre-populate the payer name
          console.log(response['data']);
          setFormValues({'payerName': routePayerName});
          setTableData(response.data.table_data);
          setTableHead(response.data.table_headers);
        })
        .catch(error => {
          console.log(error);
        });
      
      //const cleanFormId = handleFormIdParam(route, setFormValues, setFormId, initialValues, newFilingId, isFocused, numericFields); 
    }
  }, [isFocused]);

 
  const parseUpload = (values) => {
    if (fileToUpload !== undefined) {
      let formData = new FormData();
      formData.append('filingId', filingId);
      formData.append('payerName', values.payerName ?? '');
      formData.append('files[]', fileToUpload[0]);
      // setDisplayState('parsingUpload');
      axiosInstance.post('upload1099B', formData, {headers: { 'Content-Type': 'multipart/form-data' }, withCredentials: true})
        .then((response) => {
          console.log(response);
          setTableData(response.data.table_data);
          setTableHead(response.data.table_headers);
        })
        .catch(error => {
          console.log(error);
        });
      }
  }

  const editItemScreen = (formId) => {
    setUploadDisplayState('');
    navigateFiling(navigation, 'addF1099BItem', { filingId: filingId, formId: formId });
  }

  const continuePressed = (formValues, setSubmitting, setValues) => {
    editFormValues(setSubmitting, setValues, filingId, formId, 'Form 1099-B', formValues, initialValues, navigation, 'f1099B', numericFields)
  };

  const backPressed = () => {
    navigateFiling(navigation, 'f1099B', { filingId: filingId });
  };

  var widthArr = [];
  for (let i = 0; i < tableHead.length; i++) {
    widthArr.push(tableHead[i].length * 10);
  }

  return (
    <Formik 
      initialValues={formValues} 
      enableReinitialize={true}
      onSubmit={(values, { setSubmitting, setValues }) => {continuePressed(values, setSubmitting, setValues)}}
    >
      {({ handleChange, handleBlur, handleSubmit, values, setValues, isSubmitting }) => (
    <ContentContainer nextPress={null} nextDisabled={false} backPress={backPressed} backDisabled={false}>
      <ContentHeader icon={"attach-money"} headerText={"Enter details from your F1099-B"} />

      <View>
        <View style={globalStyle.fieldRow}>
          <TextFieldFormik name="payerName" fieldName="Payer Name" placeholder="" fieldStyle={{'flex': 2, minWidth: 250}} /> 
          <View style={{'flex': 2}}></View>
        </View>

        <View style={{flexDirection: 'row'}}>
          <TouchableOpacity onPress={() => setUploadDisplayState('upload')} >
            <MaterialIcons name={'file-upload'} size={50} color={globalColors.accent} />
            <StyledText>Bulk upload</StyledText>
          </TouchableOpacity>

          <TouchableOpacity onPress={() => editItemScreen(null)} >
          <MaterialIcons name={'edit'} size={50} color={globalColors.accent} />
            <StyledText>Enter manual</StyledText>
          </TouchableOpacity>
        </View>
        
        {uploadDisplayState === 'upload' ?
            <View style={globalStyle.fieldRow}>
              <UploadFileWeb fileToUpload={fileToUpload} setFileToUpload={setFileToUpload} parseUpload={() => parseUpload(values)}/>
            </View>
        : <View></View>}

        <View style={styles.container}>
          <ScrollView horizontal={true} >
            <View>
              <Table borderStyle={{ borderWidth: 1 }}>
                <Row data={tableHead} widthArr={widthArr} style={styles.head}  textStyle={styles.text}  />
              </Table>

              <View style={{height: 500}}>
                <ScrollView vertical={true}>
                  <Table borderStyle={{ borderWidth: 1}}>
                    {tableData.map((rowData, index) => (
                      <Row key={index} data={rowData} widthArr={widthArr} style={styles.rowSection} textStyle={styles.text} />
                    ))}
                  </Table>
                </ScrollView>
              </View>
            </View>
          </ScrollView>
        </View>
      </View>
    </ContentContainer>
  )}
  </Formik>
  )
}
const styles = StyleSheet.create({
  container: { flex: 1, padding: 16, paddingTop: 30, backgroundColor: '#fff' },
  header: { height: 50, backgroundColor: '#537791' },
  dataWrapper: { marginTop: -1 },
  text: { textAlign: 'center' },
  row: { height: 40, backgroundColor: '#E7E6E1' }
});