import * as yup from 'yup';

export const loginSchema = yup.object().shape({
  email: yup
    .string()
    .email('Invalid email')
    .required('Email is a required field'),
  password: yup
    .string()
    .required('Password is a required field')
    .min(8, 'Password must be at least 8 characters long'),
});

export const personalInfoSchema = yup.object().shape({
  firstName: yup
    .string()
    .required('First name is a required field')
    .min(3, 'First name must be at least 3 character long'),
  lastName: yup
    .string()
    .required('Last name is a required field')
    .min(3, 'Last name must be at least 3 character long'),
  ssn: yup
    .string()
    .required('SSN is required')
    .matches(/^\d{3}-\d{2}-\d{4}$/, 'Invalid SSN format'),
  filingStatus: yup
    .string(),
});

export const addW2Schema = yup.object().shape({
  employer_ein: yup
    .string()
    .required(('employer_ein: Required field'))
    .typeError( 'employer_ein: Must be a number'),
  wages: yup
    .number()
    .required('wages: Required field')
    .typeError( 'wages: Must be a number'),
  federal_income_tax: yup
    .number()
    .required('federal_income_tax: Required field')
    .typeError( 'federal_income_tax: Must be a number'),
  social_security_wages: yup
    .number()
    .required('social_security_wages: Required field')
    .typeError( 'social_security_wages: Must be a number'),
  social_security_tax: yup
    .number()
    .required('social_security_tax: Required field')
    .typeError( 'social_security_tax: Must be a number'),
  medicare_wages: yup
    .number()
    .required('medicare_wages: Required field')
    .typeError( 'medicare_wages: Must be a number'),
  medicare_tax: yup
    .number()
    .required('medicare_tax: Required field')
    .typeError( 'medicare_tax: Must be a number'),
  state_wages: yup
    .number()
    .required('state_wages: Required field')
  .typeError( 'state_wages: Must be a number'),
  state_income_tax: yup
    .number()
    .required('state_income_tax: Required field')
    .typeError( 'state_income_tax: Must be a number')

});